<template lang="html">
<div class="person-watch">
    <div>Статус мониторинга</div>
    <div v-if="isOnWatch === false" class="watch-off">персона не установлена на мониторинг</div>
    <div v-if="isOnWatch === true" class="watch-on">персона установлена на мониторинг</div>
    <div v-if="isOnWatch === true && !isInChange" v-on:click="disable()" class="link">снять с мониторинга</div>
    <div v-if="isOnWatch === false && !isInChange" v-on:click="enable()" class="link">установить на мониторинг</div>
    <div v-if="isInChange" class="link">пожалуйста, подождите...</div>
</div>
</template>

<script lang="ts">
import {core} from '../../root';
import {RecordPerson, PersonContext} from '../../core';

export default {
    props: {
        person: {
            type: RecordPerson,
            required: true
        },
        context: {
            type: PersonContext,
            required: true
        }
    },
    data() {
        return {isInChange: false}
    },
    computed: {
        isOnWatch() {
            let person = this.person as RecordPerson;
            let context = this.context as PersonContext;
            let index = context.watchedPersons.indexOf(person.personId);
            return index !== -1;
        },
        async disable() {
            if (this.isInChange) {
                return;
            }
            this.isInChange = true;
            let person = this.person as RecordPerson;
            let context = this.context as PersonContext;
            let index = context.watchedPersons.indexOf(person.personId);
            if (index === -1) {
                return;
            }
            try {
                await core.api.disablePersonWatch(person.personId, context.searchId);
                context.watchedPersons.splice(index, 1);
            } finally {
                this.isInChange = false;
            }
        },
        async enable() {
            if (this.isInChange) {
                return;
            }
            this.isInChange = true;
            let person = this.person as RecordPerson;
            let context = this.context as PersonContext;
            let index = context.watchedPersons.indexOf(person.personId);
            if (index !== -1) {
                return;
            }
            try {
                await core.api.enablePersonWatch(person.personId, context.searchId);
                context.watchedPersons.push(person.personId);
            } finally {
                this.isInChange = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.person-watch {
    display: flex;
    flex-direction: row;
    > div:first-child {
        margin-right: 10px;
        &::after {
            content: ':';
        }
    }
    > div:nth-child(2) {
        margin-right: 20px;
    }
}
.watch-on {
    color: #26a326;
    font-weight: bold;
}
</style>