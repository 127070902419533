<template lang="html">
    <div class="root">
        <div class="fields">
            <div>
                <div class="field-block">
                    <div>
                        <div class="field-block-content">
                            <div class="field">
                                <div>Фамилия</div>
                                <div>
                                    <input
                                        v-model="context.person.fields.lastname"
                                        v-bind:disabled="canEdit !== true ? true : null"
                                        type="text"
                                        placeholder="Фамилия"
                                    />
                                </div>
                            </div>
                            <div class="field">
                                <div>Имя</div>
                                <div>
                                    <input
                                        v-model="context.person.fields.firstname"
                                        v-bind:disabled="canEdit !== true ? true : null"
                                        type="text"
                                        placeholder="Имя"
                                    />
                                </div>
                            </div>
                            <div class="field">
                                <div>Отчество</div>
                                <div>
                                    <input
                                        v-model="context.person.fields.patronymic"
                                        v-bind:disabled="canEdit !== true ? true : null"
                                        type="text"
                                        placeholder="Отчество"
                                    />
                                </div>
                            </div>
                            <div class="field">
                                <div>Дата рождения</div>
                                <div>
                                    <DatePicker
                                        v-model="context.person.fields.birthday"
                                        :locale="'ru-RU'"
                                        :masks="masks"
                                        :popover="PopoverOptions"
                                    >
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <input
                                                :value="inputValue"
                                                v-on="inputEvents"
                                                placeholder="дд.мм.гггг"
                                                :visibility="'focus'"
                                                v-bind:disabled="canEdit !== true ? true : null"
                                                @focus="selectAllValue"
                                            />
                                        </template>
                                    </DatePicker>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="field-block">
                    <div>
                        <div class="field-block-content">
                            <div class="field">
                                <div>Серия паспорта</div>
                                <div>
                                    <input
                                        v-model="context.person.fields.passportSerial"
                                        v-bind:disabled="canEdit !== true ? true : null"
                                        type="text"
                                        placeholder="Серия паспорта"
                                    />
                                </div>
                            </div>
                            <div class="field">
                                <div>Номер паспорта</div>
                                <div>
                                    <input
                                        v-model="context.person.fields.passportNumber"
                                        v-bind:disabled="canEdit !== true ? true : null"
                                        type="text"
                                        placeholder="Номер паспорта"
                                    />
                                </div>
                            </div>
                            <div class="field">
                                <div>Паспорт выдан</div>
                                <div>
                                    <DatePicker
                                        :transition="'none'"
                                        v-model="context.person.fields.passportIssue"
                                        :locale="'ru-RU'"
                                        :masks="masks"
                                        :popover="PopoverOptions"
                                    >
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <input
                                                :value="inputValue"
                                                v-on="inputEvents"
                                                placeholder="дд.мм.гггг"
                                                :visibility="'focus'"
                                                v-bind:disabled="canEdit !== true ? true : null"
                                                @focus="selectAllValue"
                                            />
                                        </template>
                                    </DatePicker>
                                </div>
                            </div>
                            <div class="field">
                                <div>ИНН</div>
                                <div>
                                    <input
                                        v-model="context.person.fields.inn"
                                        v-bind:disabled="canEdit !== true ? true : null"
                                        type="text"
                                        placeholder="Номер ИНН"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { core } from "../../root";
import { RecordPersonContext } from "../../core";
import { DatePicker, Calendar } from "v-calendar";

export default {
    props: {
        context: {
            type: RecordPersonContext,
            required: true
        }
    },
    computed: {
        canEdit() {
            let context = this.context as RecordPersonContext;
            return core.getCanEditPerson(context.person);
        }
    },
    components: {
        Calendar,
        DatePicker
    },
    data: function() {
        return {
            masks: {
                input: "DD.MM.YYYY"
            },
            PopoverOptions: {
                visibility: "focus",
                placement: "right"
            }
        };
    },
    methods: {
        selectAllValue(event) {
            event.target.select();
        }
    }
};
</script>

<style lang="scss" scoped>
.root {
    display: flex;
    flex-direction: column;
}
.fields {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: #ffffff;
    > div:nth-child(2n + 1) {
        .field-block {
            padding-right: 5px;
        }
    }
    > div:nth-child(2n) {
        .field-block {
            padding-left: 5px;
        }
    }
    > div {
        width: 50%;
    }
    .field {
        display: flex;
        flex-direction: row;
        width: 100%;
        > div {
            padding: 10px 10px;
        }
        input {
            border: none;
            border-bottom: 1px solid #cccccc;
            width: 100%;
            padding: 5px;
            &:focus {
                outline: none;
            }
        }
        > *:nth-child(1) {
            width: 35%;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        > *:nth-child(2) {
            width: 65%;
        }
    }
}
</style>
