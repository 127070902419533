<template lang="html">
<div class="attachments">
    <div v-file-paste="change" v-file-drop.multiple="change" v-file-choose.multiple="change" class="dropbox">
        <span>Перетащите сюда файл или кликните для открытия диалога</span>
    </div>
    <div v-if="aliveFileCount > 0" class="files">
        <div>
            <div>Тип</div>
            <div>Имя файла</div>
            <div>Действия</div>
        </div>
        <div v-show="file.isDeleted !== true" v-for="file in context.files">
            <div>Файл</div>
            <div>{{file.name}}</div>
            <div>
                <a v-on:click="removeFile($event, file)" href="#">Удалить</a>
            </div>
        </div>
    </div>
    <div v-if="alivePhotoCount" class="block">
        <div v-for="photo in context.photos">
            <div v-if="photo.isDeleted !== true">
                <spinner v-if="photo.content === null" ></spinner>
                <div v-if="photo.content !== null" class="photo">
                    <div v-on:click="removePhoto(photo)" class="control">
                        <div class="far fa-trash-alt"></div>
                    </div>
                    <photo v-bind:on-click="() => openPhoto(photo)" v-bind:content="photo.content" v-bind:side-size="140"></photo>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import {core, modal} from '../../root';
import {Util} from '../../util';
import {RequestContext, RecordPhotoContext, LoadRecordPhotoRequest} from '../../core'

import FileChooseDirective from '../../vueDirectives/FileChoose';
import FilePasteDirective from '../../vueDirectives/FilePaste';
import FileDropDirective from '../../vueDirectives/FileDrop';
import FullPhotoComponent from './FullPhoto.vue';
import SpinnerComponent from '../Spinner.vue';
import ImageComponent from '../Image.vue';

export default {
    props: {
        context: {
            type: RequestContext,
            required: true
        }
    },
    data: function () {
        return {
            photos: null
        };
    },
    computed: {
        aliveFileCount() {
            let result = 0;
            let context = this.context as RequestContext;
            for (let i = 0; i < context.files.length; i++) {
                let file = context.files[i];
                if (file.isDeleted) {
                    continue;
                }
                result = result + 1;
            }
            return result;
        },
        alivePhotoCount() {
            let result = 0;
            let context = this.context as RequestContext;
            for (let i = 0; i < context.photos.length; i++) {
                let photo = context.photos[i];
                if (photo.isDeleted) {
                    continue;
                }
                result = result + 1;
            }
            return result;
        }
    },
    methods: {
        async openPhoto(photo: RecordPhotoContext) {
            let resolvedRecordId = this.context.record.recordId || '__UNDEFINED__';
            let resolvedPhotoId = photo.id || '__UNDEFINED__';
            let request = new LoadRecordPhotoRequest(resolvedRecordId, resolvedPhotoId);
            if (photo.content) {
                request.base64 = await Util.imageToBase64(photo.content);
            }
            modal.open(FullPhotoComponent, {
                data: {
                    requests: [request],
                    index: 0,
                    width: 500
                },
                transparent: true,
                noMargin:true
            })
        },
        async change (files:Array<File>) {
            for (var i = 0; i < files.length; i++) {
                let photo = new RecordPhotoContext();
                let file = files[i];
                photo.name = file.name;
                photo.content = file;
                let header = file.slice(0, 128);
                let fileType = await core.api.detectFile(header);
                if (fileType.type !== 'unknown') {
                    this.context.photos.push(photo)
                } else {
                    this.context.files.push(photo)
                }
            }
        },
        removePhoto(photo: RecordPhotoContext) {
            if (typeof photo.id === 'string') {
                photo.isDeleted = true;
            } else {
                const index = this.context.photos.indexOf(photo);
                this.context.photos.splice(index, 1);
            }
        },
        removeFile($event:MouseEvent, file:RecordPhotoContext) {
            $event.preventDefault();
            if (typeof file.id === 'string') {
                file.isDeleted = true;
            } else {
                const index = this.context.files.indexOf(file);
                this.context.files.splice(index, 1);
            }
        }
    },
    directives: {
        fileChoose: FileChooseDirective,
        filePaste: FilePasteDirective,
        fileDrop: FileDropDirective
    },
    components: {
        spinner: SpinnerComponent,
        photo: ImageComponent
    }
}
</script>

<style lang="scss" scoped>
.attachments {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    > *:not(:last-child) {
        margin-bottom: 10px;
    }
    .dropbox {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        border: 1px dashed #cccccc;
        padding: 30px;
        align-items: center;
        justify-content: center;
        color: #666;
        text-transform: uppercase;
        background: #ffffff;
    }
}
.block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > div {
        display: flex;
        flex-direction: row;
        $_border-color: #cccccc;
        width: 200px;
        &:first-child {
            font-weight: bold;
        }
        > * {
            padding: 20px;
        }
        > *:not(:last-child) {
            border-right: 1px solid $_border-color;
        }
    
    }
}
.files {
    $_border-color: #ededed;
    display: flex;
    flex-direction: column;
    > div {
        display: flex;
        flex-direction: row;
        &:first-child {
            font-weight: bold;
        }
        &:not(:first-child) {
            > div:nth-child(3) {
                justify-content: center;
            }
        }
        > div {
            display: flex;
            flex-direction: row;
            padding: 10px 10px;
            &:first-child {
                width: 15%;
            }
            &:nth-child(2) {
                width: 70%;
            }
            &:nth-child(3) {
                width: 15%;
            }
            &:not(:last-child) {
                border-right: 1px solid $_border-color;
            }
        }
        &:not(:last-child) {
            border-bottom: 1px solid $_border-color;
        }
    }
}
.photo {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #ededed;
    border: 1px solid #cccccc;
    width: 150px;
    height: 150px;
    border-radius: 3px;
    cursor: pointer;
    img {
        border-radius: 3px;
        z-index: 1;
    }
    &[exclude] {
        img {
            opacity: 0.25;
        }
    }
    .control {
        position: absolute;
        top: 0;
        right: 0;
        background: #ededed;
        border-left: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;
        border-bottom-left-radius: 3px;
        padding: 5px;
        z-index: 2;
        .fa-check-circle {
            color: green;
        }
        .fa-circle {
            color: red;
        }
        .fa-trash-alt {
            color: red;
        }
    }
}
</style>