<template lang="html">
<a v-bind:href="'#/search/text?queryType=phone&queryString=' + value" v-bind:title="'Выполнить поиск по номеру телефона ' + value" target="_blank">{{phone}}</a>
</template>

<script lang="ts">
export default {
    props: {
        value: {
            type: [String, Number],
            required: true
        }
    },
    computed: {
        phone() {
            let phone:number;
            if (typeof this.value === 'number') {
                phone = this.value;
            } else if (typeof this.value === 'string') {
                phone = parseInt(this.value);
            }
            if (!phone) {
                return null;
            }
            return '+' + phone.toString();
        }
    }
}
</script>

<style lang="scss" scoped>
a {
    color: #000000;
}
</style>