<template lang="html">
<div class="root">
    <hint v-bind:text="'Сравнение фото. Позволяет оценить степень схожести лиц на двух и более фотографиях'"></hint>
    <div v-file-paste="change" v-file-drop.multiple="change" v-file-choose.multiple="change" class="dropbox">
        <span>Перетащите сюда файл или кликните для открытия диалога</span>
    </div>
    <div v-bind:empty="(faces.length === 0 && uploadCount === 0) ? true : null" class="photos">
        <div v-if="faces.length > 0" class="control-left">
            <div>
                <div v-on:click="choose(true)" class="link">Выбрать все</div>
            </div>
            <div class="separator"></div>
            <div>
                <div v-on:click="choose(false)" class="link">Сбросить</div>
            </div>
            <div class="separator"></div>
            <div>
                <div v-on:click="clear" class="link">Очистить</div>
            </div>
        </div>
        <div class="control">
            <div v-if="selectedCount === 0 && faces.length > 0">Выберите лица для сравнения</div>
            <div v-if="selectedCount > 0 && faces.length > 0">Выбрано лиц: {{selectedCount}}</div>
        </div>
        <div v-if="faces.length === 0 && uploadCount === 0" class="empty">
            <div>Здесь будут отображены найденные лица</div>
        </div>
        <div v-on:click="face.include = !face.include" v-for="face, index in faces" class="photo">
            <div>
                <div class="photo-control">
                    <div v-on:click="remove(index)" class="far fa-trash-alt"></div>
                </div>
                <div class="photo-select">
                    <div v-if="face.include === true" class="fas fa-check-circle"></div>
                    <div v-if="face.include === false" class="far fa-circle"></div>
                </div>
                <photo v-bind:content="face.base64" v-bind:side-size="140"></photo>
            </div>
        </div>
        <div v-if="uploadCount > 0" class="photo">
            <spinner></spinner>
        </div>
    </div>
    <div class="footer">
        <btn v-bind:disabled="(isInProcess || selectedCount < 2) ? true : null" v-on:clicked="compare" v-bind:text="'Сравнить'"></btn>
    </div>
    <spinner v-if="isInProcess === true"></spinner>
    <div v-if="result !== null && isInProcess === false" class="result">
        <div v-if="result.isTheSame === true" class="match match-true">
            <div class="fas fa-check-circle"></div>
            <div>На всех фотографиях лица одинаковые</div>
        </div>
        <div v-if="result.isTheSame === false" class="match match-false">
            <div class="far fa-times-circle"></div>
            <div>На фотографиях представлены различные лица</div>
        </div>
    </div>
    <div v-if="result !== null && isInProcess === false" class="match-table">
        <div class="match-table-row">
            <div class="match-table-cell"></div>
            <div v-for="face in source" class="match-table-cell match-table-photo match-table-item">
                <photo v-bind:content="face.base64" v-bind:side-size="80"></photo>
            </div>
        </div>
        <div v-for="(row, i) in result.similarity" class="match-table-row">
            <div class="match-table-cell match-table-photo match-table-item">
                <photo v-bind:content="source[i].base64" v-bind:side-size="80"></photo>
            </div>
            <div v-for="(cell, k) in row" class="match-table-cell match-table-item">
                <div v-bind:high="cell >= result.strictLevel ? true : null" v-bind:low="cell < result.strictLevel ? true : null" v-if="i !== k">
                    <div v-if="cell > faceModerate">Высокий</div>
                    <div v-if="cell <= faceModerate && cell > faceLow">Средний</div>
                    <div v-if="cell <= faceLow">Низкий</div>
                    <div>{{Math.floor(cell.toFixed(2) * 100)}}%</div>
                </div>
                <div v-if="i === k">&mdash;</div>
            </div>
        </div>
    </div>
    <div v-if="isInProcess === false && isInFailure === true" class="failure">
        <div v-if="isGeneralError === true" class="fas fa-exclamation-triangle"></div>
        <div v-if="isGeneralError === true" class="text">Произошла ошибка</div>
        <div v-if="isBillingError === true" class="fas fa-ruble-sign"></div>
        <div v-if="isBillingError === true" class="text">Недостаточно средств на счёте</div>
        <div v-if="isRateLimitError === true" class="far fa-hand-paper"></div>
        <div v-if="isRateLimitError === true" class="text">Превышение условий тарифного плана</div>
        <div v-if="errorReason" class="reason">
            <error-reason v-bind:value="errorReason"></error-reason>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import {FaceCompareRequest} from '../../core';
import {core} from '../../root';

import FileChooseDirective from '../../vueDirectives/FileChoose';
import FilePasteDirective from '../../vueDirectives/FilePaste';
import FileDropDirective from '../../vueDirectives/FileDrop';
import ErrorReasonComponent from '../ErrorReason.vue';
import SpinnerComponent from './../Spinner.vue';
import ButtonComponent from './../Button.vue';
import ImageComponent from '../Image.vue';
import HintComponent from '../Hint.vue';

export default {
    data: function () {
        return {
            faces: [],
            isInFailure: false,
            isInProcess: false,
            isBillingError: false,
            isGeneralError: false,
            isRateLimitError: false,
            errorReason: null,
            result: null,
            source: null,
            uploadCount: 0,
            faceLow: core.faceMatchLevelInfo.low,
            faceModerate: core.faceMatchLevelInfo.moderate
        }
    },
    computed: {
        selectedCount: function () {
            var count = 0;
            for (var i = 0; i < this.faces.length; i++) {
                const face = this.faces[i];
                if (face.include === true) {
                    count = count + 1;
                }
            }
            return count;
        }
    },
    methods: {
        choose(status:boolean) {
            this.faces.forEach(_ => _.include = status);
        },
        clear() {
            this.uploadCount = 0;
            this.result = null;
            this.source = null;
            this.faces = [];
        },
        async change(files) {
            const add = async file => {
                const fileId = await core.api.uploadFile(file);
                const bounds = await core.api.detectFaces(fileId);
                for (var i = 0; i < bounds.length; i++) {
                    const bound = bounds[i];
                    const preview = await core.api.cropFile(fileId, bound);
                    const face = {
                        fileId: fileId,
                        base64: preview,
                        bound: bound,
                        include: false
                    };
                    if (this.faces.length < 2) {
                        face.include = true;
                    }
                    this.faces.push(face);
                }
            }
            this.uploadCount = this.uploadCount + 1;
            try {
                const promises = [];
                for (var i = 0; i < files.length; i++) {
                    const promise = add(files[i]);
                    promises.push(promise);
                }
                await Promise.all(promises)
            } catch (error) {
                if (error.status === 500 || error.status == 400) {
                    if (!!error.responseJSON.result && !!error.responseJSON.result.reason) {
                        this.errorReason = error.responseJSON.result.reason;
                    }
                    this.isGeneralError = true;
                } else if (error.status === 402) {
                    this.isBillingError = true;
                } else if (error.status === 429) {
                    this.isRateLimitError = true;
                } else {
                    this.isGeneralError = true;
                }
                this.isInFailure = true;
            } finally {
                this.uploadCount = this.uploadCount - 1;
            }
        },
        resetError() {
            this.isInFailure = false;
            this.isBillingError = false;
            this.isGeneralError = false;
            this.isRateLimitError = false;
            this.errorReason = null;
        },
        resetData() {
            this.result = null;
            this.source = null;
        },
        remove(index) {
            this.faces.splice(index, 1);
            this.result = null;
            this.source = null;
        },
        async compare() {
            if (this.isInProcess === true) {
                return;
            }
            this.resetError();
            this.resetData();
            this.isInProcess = true;
            try {
                const request = new FaceCompareRequest();
                this.source = [];
                for (var i = 0; i < this.faces.length; i++) {
                    const face = this.faces[i];
                    if (face.include !== true) {
                        continue;
                    }
                    request.add(face.bound.faceId);
                    this.source.push(face);
                }
                this.result = await core.api.compareFaces(request);
            } catch (error) {
                if (error.status === 500 || error.status == 400) {
                    if (!!error.responseJSON.result && !!error.responseJSON.result.reason) {
                        this.errorReason = error.responseJSON.result.reason;
                    }
                    this.isGeneralError = true;
                } else if (error.status === 402) {
                    this.isBillingError = true;
                } else if (error.status === 429) {
                    this.isRateLimitError = true;
                } else {
                    this.isGeneralError = true;
                }
                this.isInFailure = true;
            } finally {
                this.isInProcess = false;
            }
        }
    },
    directives: {
        fileChoose: FileChooseDirective,
        fileDrop: FileDropDirective,
        filePaste: FilePasteDirective
    },
    components: {
        errorReason: ErrorReasonComponent,
        spinner: SpinnerComponent,
        photo: ImageComponent,
        hint: HintComponent,
        btn: ButtonComponent
    }
}
</script>

<style lang="scss" scoped>
.root {
    display: flex;
    flex-direction: column;
    > * {
        margin-bottom: 20px;
    }
    .dropbox {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        border: 1px dashed #cccccc;
        padding: 30px;
        align-items: center;
        justify-content: center;
        color: #666;
        text-transform: uppercase;
        background: #ffffff;
        &.drag-hover {
            background: #ededed;
            border: 1px solid #cccccc;
        }
    }
}
.result {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .match {
        display: flex;
        flex-direction: row;
        padding: 10px;
        > *:not(:last-child) {
            margin-right: 10px;
        }
        text-transform: uppercase;
        font-size: 14px;
        * {
            line-height: 30px;
        }
    }
    .match-true {
        color: green;
    }
    .match-false {
        color: red;
    }
}
.link {
    color: #0070e0;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
        text-decoration: none;
        color: #3eb8ff
    }
}
.failure {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;;
    padding: 50px 0;
    > *:not(:last-child) {
        margin-bottom: 50px;
    }
    .fas, .far {
        font-size: 50px;
        &.fa-exclamation-triangle {
            color: #f96565;
        }
        &.fa-ruble-sign {
            color: #cecdcd;
        }
        &.fa-hand-paper {
            color: #cecdcd;
        }
    }
    .text {
        font-size: 20px;
    }
    .reason {
        font-size: 15px;
    }
}
.match-table {
    display: flex;
    flex-direction: column;
    .match-table-row {
        display: flex;
        flex-direction: row;
    }
    .match-table-item {
        &:not(:first-child) {
            margin-left: 2px;
        }
        &:not(:last-child) {
            margin-right: 2px;
        }
    }
    .match-table-cell {
        $_size: 90px;
        display: flex;
        flex-direction: row;
        height: $_size;
        width: $_size;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        div[high] {
            color: #3b8e3b;
            font-weight: bold;
        }
        div[low] {
            color: red;
            font-weight: bold;
        }
    }
    .match-table-photo {
        flex-shrink: 0;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #ededed;
        border: 1px solid #cccccc;
        border-radius: 3px;
        box-sizing: border-box;
        margin-top: 2px;
        margin-bottom: 2px;
    }
}
.photos {
    padding: 20px;
    display: flex;
    flex-direction: row;
    position: relative;
    &:not([empty]) {
        flex-wrap: wrap;
        padding-top: 40px;
    }
    &[empty] {
        justify-content: center;
        background: #ffffff;
    }
    border: 1px solid #ededed;
    .empty {
        color: #666666;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .control {
        display: flex;
        flex-direction: row;
        position: absolute;
        background: #ffffff;
        top: 0;
        right: 0;
        border-left: 1px solid #ededed;
        border-bottom: 1px solid #ededed;
        border-bottom-left-radius: 3px;
        padding: 5px 10px;
        > * {
            display: flex;
            flex-direction: row;
            &:not(:last-child) {
                margin-right: 10px;
            }
            &.separator {
                border-left: 1px solid #ededed;
            }
        }
    }
    .control-left {
        display: flex;
        flex-direction: row;
        position: absolute;
        background: #ffffff;
        top: 0;
        left: 0;
        border-right: 1px solid #ededed;
        border-bottom: 1px solid #ededed;
        border-bottom-right-radius: 3px;
        padding: 5px 10px;
        > * {
            display: flex;
            flex-direction: row;
            &:not(:last-child) {
                margin-right: 10px;
            }
            &.separator {
                border-left: 1px solid #ededed;
            }
        }
    }
}
.photo {
    width: 150px;
    height: 150px;
    > div {
        position: relative;
        border: 1px solid #cccccc;
        border-radius: 3px;
        width: 140px;
        height: 140px;
        margin: 5px;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #ededed;
        .photo-control {
            position: absolute;
            right: 0;
            top: 0;
            background: #ededed;
            border-bottom-left-radius: 3px;
            border-left: 1px solid #cccccc;
            border-bottom: 1px solid #cccccc;
            > div {
                padding: 5px;
                cursor: pointer;
                &:hover {
                    color:red;
                }
            }
        }
        .photo-select {
            position: absolute;
            top: 0;
            left: 0;
            background: #ededed;
            border-right: 1px solid #cccccc;
            border-bottom: 1px solid #cccccc;
            border-bottom-right-radius: 3px;
            padding: 5px;
            z-index: 2;
            .fa-check-circle {
                color: green;
            }
            .fa-circle {
                color: red;
            }
            .fa-trash-alt {
                color: red;
            }
        }
    }
    img {
        cursor: pointer;
    }
}
</style>