<template lang="html">
<a v-bind:href="'#/search/text?queryType=email&queryString=' + value" v-bind:title="'Выполнить поиск по электронной почте ' + value" target="_blank">{{value}}</a>
</template>

<script lang="ts">
export default {
    props: {
        value: {
            type: [String, Number],
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
a {
    color: #000000;
}
</style>