<template lang="html">
<div class="root">
    <div class="contact-body">
        <div class="contact-block">
            <div>
                <div class="contact-block-content">
                    <div v-for="(phone, index) in context.person.contacts.phones" class="contact">
                        <div>Телефон № {{index + 1}}</div>
                        <div> 
                            <input v-model="context.person.contacts.phones[index]" v-bind:disabled="canEdit !== true ? true : null" type="text" placeholder="Номер телефона" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contact-block">
            <div>
                <div class="contact-block-content">
                    <div v-for="(email, index) in context.person.contacts.emails" class="contact">
                        <div>Email № {{index + 1}}</div>
                        <div>
                            <input v-model="context.person.contacts.emails[index]" v-bind:disabled="canEdit !== true ? true : null" type="text" placeholder="Адрес электронной почты" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="contact-footer">
        <div v-on:click="addFields()" class="link">Добавить ещё одно поле (телефон и email)</div>
    </div>
</div>
</template>

<script lang="ts">
import {core} from '../../root';
import {RecordPersonContext} from '../../core';

function prepareContext(context:RecordPersonContext, addOneMore:boolean) {
    function fill(array:Array<string>, size:number) {
        if (addOneMore === true) {
            size++;
        }
        for (let i = array.length; i < size; i++) {
            array.push(null);
        }
        return array;
    }
    const defaultSize = 1;
    let phones = context.person.contacts.phones;
    let emails = context.person.contacts.emails;
    let maxArrayLength = Math.max(phones.length, emails.length, defaultSize);
    fill(phones, maxArrayLength);
    fill(emails, maxArrayLength);
}
export default {
    props: {
        context: {
            type: RecordPersonContext,
            required: true
        }
    },
    created() {
        prepareContext(this.context, false);
    },
    watch: {
        context: function (value) {
            prepareContext(value, false);
        }
    },
    computed: {
        canEdit() {
            let context = this.context as RecordPersonContext;
            return core.getCanEditPerson(context.person);
        }
    },
    methods: {
        addFields() {
            prepareContext(this.context, true);
        }
    }
}
</script>

<style lang="scss" scoped>
.contact-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
}
.contact-body {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    > div {
        width: 50%;
    }
    > div:nth-child(2n + 1) {
        padding-right: 5px;
    }
    > div:nth-child(2n) {
        padding-left: 5px;
    }
}
.contact-block {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.contact {
    display: flex;
    flex-direction: row;
    width: 100%;
    > div {
        display: flex;
        flex-direction: row;
        padding: 10px 10px;
    }
    input {
        border: none;
        border-bottom: 1px solid #cccccc;
        width: 100%;
        padding: 5px;
        &:focus {
            outline: none;
        }
    }
    > *:nth-child(1) {
        width: 35%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    > *:nth-child(2) {
        width: 65%;
    }
    .fa-check-circle {
        &.fas {
            color: green;
        }
        &.far {
            color: #666;
        }
    }
}
</style>