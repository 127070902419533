<template lang="html">
<div class="resolve-or-create">
    <div class="resolve">
        <div>Найти существующую персону по идентификатору</div>
        <div class="resolve-input">
            <input v-bind:disabled="loading ? true : null" v-model="humanId" v-on:keyup.enter="resolve" placeholder="Введите ID персоны, например: P0000" />
            <btn v-on:clicked="resolve" v-bind:disabled="loading ? true : null" v-bind:text="'Найти'"></btn>
        </div>
    </div>
    <div class="separator">
        <div></div>
        <div>Или</div>
        <div></div>
    </div>
    <div class="create-button">
        <btn v-on:clicked="create" v-bind:disabled="loading ? true : null" v-bind:text="'Создать новую персону'"></btn>
    </div>
</div>
</template>


<script lang="ts">
import {core} from '../../root';
import ButtonComponent from '../Button.vue';

export default {
    props: {
        recordId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            humanId: null
        };
    },
    methods: {
        async create($event) {
            this.loading = true;
            try {
                let personId = await core.api.createPerson(this.recordId);
                this.$emit('resolved', personId);
            } finally {
                this.loading = false;
            }
        },
        async resolve() {
            let humanId;
            try {
                humanId = this.humanId.replaceAll(/[^\d]/ig, '')
                humanId = parseInt(humanId);
                let isNan = isNaN(humanId);
                if (isNan) {
                    throw new Error();
                }
            } catch {
                this.humanId = null;
                return;
            }
            this.loading = true;
            try {
                let personId = await core.api.resolvePersonHumanId(humanId);
                this.$emit('resolved', personId);
            } catch (error) {
                if (error.status !== 404) {
                    throw error;
                }
                this.humanId = null;
            } finally {
                this.loading = false;
            }
        }
    },
    components: {
        btn: ButtonComponent
    }
}
</script>

<style lang="scss" scoped>
.resolve-or-create {
    display: flex;
    flex-direction: column;
}
.separator {
    $_height: 40px;
    display: flex;
    flex-direction: row;
    line-height: $_height;
    height: $_height;
    font-size: 14px;
    margin: 10px 0;
    text-transform: uppercase;
    > div {
        display: flex;
        flex-direction: row;
        flex-shrink: 0;
    }
    > div:nth-child(2) {
        padding: 0 20px;
        align-self: center;
        height: $_height;
    }
    > div:nth-child(1), > div:nth-child(3) {
        border-bottom: 1px solid #cccccc;
        flex-grow: 1;
        height: calc($_height / 2);
    }
}
.resolve {
    display: flex;
    flex-direction: column;
    > *:not(:last-child) {
        margin-bottom: 10px;
    }
    .resolve-input {
        display: flex;
        flex-direction: row;
        > * {
            flex-shrink: none;
            &:not(:last-child) {
                margin-right: 20px;
            }
        }
        input {
            flex-grow: 1;
            &:focus {
                outline: none;
            }
        }
    }
}
.create-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
}
</style>