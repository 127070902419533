<template lang="html">
<div class="root">
    <hint v-bind:text="'Поиск по системе распознавания лиц. Позволяет найти записи в общей базе данных, у которых лица в медиа-контенте имеют высокий процент схожести с загруженной фотографией'"></hint>
    <div v-file-paste="change" class="upload-dialog">
        <div v-if="file" class="source-photo">
            <photo v-bind:content="file" v-bind:side-size="110"></photo>
        </div>
        <div v-bind:disabled="isInProcess ? true : null" class="dropbox">
            <div v-file-drop="change" v-file-choose="change" class="dropbox-click-area">
                <span class="message">Перетащите сюда файл или кликните для открытия диалога</span>
            </div>
            <div class="mode">
                <span>Режим поиска</span>
                <span>
                    <span v-on:click="changeMultiFacesearchMode(false)" v-bind:active="allowMultiFaceSearch === false ? true : null">Лучшее лицо</span>
                    <span v-on:click="changeMultiFacesearchMode(true)" v-bind:active="allowMultiFaceSearch === true ? true : null">Все лица</span>
                </span>
            </div>
        </div>
    </div>
    <div v-if="result !== null && result.length > 0 && isInProcess === false" class="header">
        <div>
            <div>Найдено: записей &mdash; <b>{{result ? result.length : 0}}</b>, связанных инцидентов &mdash; <b>{{incidentCount}}</b></div>
        </div>
        <div class="sort-options">
            <select v-model="isDesc">
                <option v-bind:value="true">По убыванию</option>
                <option v-bind:value="false">По возрастанию</option>
            </select>
            <select v-model="sort">
                <option v-bind:value="k" v-for="v, k in sortMethods">{{v}}</option>
            </select>
        </div>
    </div>
    <div v-if="isInProcess === false && isInFailure === true" class="failure">
        <div v-if="isGeneralError === true" class="fas fa-exclamation-triangle"></div>
        <div v-if="isGeneralError === true" class="text">При поиске произошла ошибка</div>
        <div v-if="isBillingError === true" class="fas fa-ruble-sign"></div>
        <div v-if="isBillingError === true" class="text">Недостаточно средств на счёте</div>
        <div v-if="isRateLimitError === true" class="far fa-hand-paper"></div>
        <div v-if="isRateLimitError === true" class="text">Превышение условий тарифного плана</div>
        <div v-if="isFileSizeError === true" class="fas fa-exclamation-triangle"></div>
        <div v-if="isFileSizeError === true" class="text">Слишком большой размер файла</div>
        <div v-if="errorReason" class="reason">
            <error-reason v-bind:value="errorReason"></error-reason>
        </div>
    </div>
    <div v-if="isInProcess === false && result !== null && result.length === 0" class="empty">
        <div>Ничего не найдено</div>
    </div>
    <div v-if="result !== null && result.length > 0 && isInProcess === false && isInFailure === false" class="result">
        <div class="result-flag-groups">
            <div v-on:click="changeTypeFilter(typeGroup.type)" v-bind:active="typeFilter === typeGroup.type ? true : null" v-show="typeGroup.count > 0" v-for="typeGroup in typeGroups" class="flag-group">
                <div>{{typeGroup.name}}</div>
                <div>{{typeGroup.count}}</div>
            </div>
        </div>
        <div class="result-flag-groups">
            <div v-on:click="changeFagFilter(flagGroup.code)" v-bind:active="flagFilter === flagGroup.code ? true : null" v-show="(typeFilter === 'all' ? flagGroup.count : (typeFilter === 'person' ? flagGroup.personCount : flagGroup.recordCount)) > 0" v-for="flagGroup in flagGroups" class="flag-group">
                <div>{{flagGroup.name}}</div>
                <div>{{typeFilter === 'all' ? flagGroup.count : (typeFilter === 'person' ? flagGroup.personCount : flagGroup.recordCount)}}</div>
            </div>
        </div>
        <div class="result-entries">
            <feed-item v-bind:item="record" v-for="record in filtered"></feed-item>
        </div>
    </div>
    <spinner v-if="isInProcess === true"></spinner>
</div>
</template>

<script lang="ts">
import {core} from '../root';

import FileChooseDirective from '../vueDirectives/FileChoose';
import FilePasteDirective from '../vueDirectives/FilePaste';
import FileDropDirective from '../vueDirectives/FileDrop';
import ErrorReasonComponent from './ErrorReason.vue';
import SpinnerComponent from './Spinner.vue';
import ImageComponent from './Image.vue';
import HintComponent from './Hint.vue';
import FeedItemComponent from './request/FeedItem.vue';
import { FeedRecord, PersonFeedRecord, RecordFlags, RequestFeedRecord } from '../core';

function resolveFlags(feedItem:FeedRecord) : RecordFlags {
    let flags:RecordFlags = null
    if (feedItem.type === "person") {
        let personItem = feedItem as PersonFeedRecord;
        let person = feedItem.personContext.persons.get(personItem.personId);
        if (person) {
            flags = person.flags;
        }
    } else if (feedItem.type === 'record') {
        let recordItem = feedItem as RequestFeedRecord;
        let record = feedItem.personContext.records.get(recordItem.recordId);
        if (record) {
            flags = record.flags;
        }
    }
    return flags;
}

export default {
    data: function () {
        return {
            typeFilter: 'all',
            flagFilter: 'all',
            typeGroups: null,
            flagGroups: null,
            isInProcess: false,
            isInFailure: false,
            isBillingError: false,
            isRateLimitError: false,
            isGeneralError: false,
            isFileSizeError: false,
            allowMultiFaceSearch: false,
            incidentCount: null,
            errorReason: null,
            searchId: null,
            result: null,
            source: 'cobalt',
            isDesc: true,
            sort: 'score',
            file: null
        }
    },
    computed: {
        sortMethods() {
            return {
                score: 'Релевантность',
                recordDate: 'Дата внесения',
                eventDate: 'Дата анкеты'
            }
        },
        filtered() {
            let records = this.result as Array<FeedRecord>;
            let result = new Array<FeedRecord>();
            records.forEach(_ => {
                if (this.typeFilter !== 'all' && this.typeFilter !== _.type) {
                    return;
                }
                if (this.flagFilter !== 'all') {
                    let flags = resolveFlags(_);
                    if (!flags) {
                        return;
                    }
                    if (flags[this.flagFilter] !== true) {
                        return;
                    }
                }
                result.push(_);
            });
            return result;
        }
    },
    watch: {
        sort: function () {
            this.replay();
        },
        isDesc: function () {
            this.replay();
        }
    },
    methods: {
        changeMultiFacesearchMode(mode: boolean) : void {
            if (this.allowMultiFaceSearch === mode) {
                return;
            }
            this.allowMultiFaceSearch = mode;
            if (!!this.file && this.isInProcess !== true) {
                this.search();
            }
        },
        changeFagFilter(code) {
            this.flagFilter = code;
        },
        changeTypeFilter(type) {
            this.typeFilter = type;
        },
        resetError() {
            this.isInFailure = false;
            this.isBillingError = false;
            this.isRateLimitError = false;
            this.isGeneralError = false;
            this.isFileSizeError = false;
            this.errorReason = null;
        },
        resetResult() {
            this.result = null;
        },
        async executeFaceSearch(fileId:string) {
            let result = await core.api.searchByFace(fileId, this.allowMultiFaceSearch);
            let flagGroups = {};
            let typeGroups = {};
            result.records.forEach(_ => {
                let flags = resolveFlags(_);
                if (_.type in typeGroups) {
                    typeGroups[_.type] = typeGroups[_.type] + 1;
                } else {
                    typeGroups[_.type] = 1;
                }
                if (!flags) {
                    return;
                }
                Object.keys(flags).forEach(f => {
                    if (flags[f] !== true) {
                        return;
                    }
                    if (f in flagGroups === false) {
                        flagGroups[f] = 1;
                    } else {
                        flagGroups[f] = flagGroups[f] + 1;
                    }
                    let z = f + '$' + _.type;
                    if (z in flagGroups === false) {
                        flagGroups[z] = 1;
                    } else {
                        flagGroups[z] = flagGroups[z] + 1;
                    }
                });
            });
            this.flagFilter = 'all';
            this.typeFilter = 'all';
            this.typeGroups = [
                {type: 'all', name: 'Все', count: result.records.length},
                {type: 'person', name: 'Персоны', count: typeGroups['person'] || 0},
                {type: 'record', name: 'Инциденты', count: typeGroups['record'] || 0},
            ];
            this.flagGroups = [
                {
                    code: 'all',
                    name: 'Все',
                    count: result.records.length,
                    personCount: result.records.length,
                    recordCount: result.records.length
                },
                {
                    code: 'isFraud',
                    name: 'Мошенник',
                    count: flagGroups['isFraud'] || 0,
                    personCount: flagGroups['isFraud$person'] || 0,
                    recordCount: flagGroups['isFraud$record'] || 0
                },
                {
                    code: 'isBankFraud',
                    name: 'Банковский мошенник',
                    count: flagGroups['isBankFraud'] || 0,
                    personCount: flagGroups['isBankFraud$person'] || 0,
                    recordCount: flagGroups['isBankFraud$record'] || 0
                },
                {
                    code: 'isMicrofinanceFraud',
                    name: 'Мошенник МФО',
                    count: flagGroups['isMicrofinanceFraud'] || 0,
                    personCount: flagGroups['isMicrofinanceFraud$person'] || 0,
                    recordCount: flagGroups['isMicrofinanceFraud$record'] || 0
                },
                {
                    code: 'isInsuranceFraud',
                    name: 'Страховое мошенничество',
                    count: flagGroups['isInsuranceFraud'] || 0,
                    personCount: flagGroups['isInsuranceFraud$person'] || 0,
                    recordCount: flagGroups['isInsuranceFraud$record'] || 0
                },
                {
                    code: 'isMoneyLaundering',
                    name: 'Обналичивание',
                    count: flagGroups['isMoneyLaundering'] || 0,
                    personCount: flagGroups['isMoneyLaundering$person'] || 0,
                    recordCount: flagGroups['isMoneyLaundering$record'] || 0
                },
                {
                    code: 'isCargoThief',
                    name: 'Кража грузов',
                    count: flagGroups['isCargoThief'] || 0,
                    personCount: flagGroups['isCargoThief$person'] || 0,
                    recordCount: flagGroups['isCargoThief$record'] || 0
                },
                {
                    code: 'isShopThief',
                    name: 'Магазинный вор',
                    count: flagGroups['isShopThief'] || 0,
                    personCount: flagGroups['isShopThief$person'] || 0,
                    recordCount: flagGroups['isShopThief$record'] || 0
                },
                {
                    code: 'isEmployRisk',
                    name: 'Риск при найме',
                    count: flagGroups['isEmployRisk'] || 0,
                    personCount: flagGroups['isEmployRisk$person'] || 0,
                    recordCount: flagGroups['isEmployRisk$record'] || 0
                }
            ];
            this.result = result.records;
            this.searchId = result.searchId;
            this.incidentCount = result.metaData.incidentCount || null;
        },
        async search() {
            this.resetError();
            this.resetResult();
            this.isInProcess = true;
            let fileId:string;
            try {
                fileId = await core.api.uploadFile(this.file);
            } catch (error) {
                if (error.status === 413) {
                    this.isFileSizeError = true;
                } else {
                    this.isGeneralError = true;
                }
                this.isInFailure = true;
                return;
            }
            try {
                await this.executeFaceSearch(fileId);
            } catch (error) {
                if (error.status === 500 || error.status == 400) {
                    if (!!error.responseJSON.result && !!error.responseJSON.result.reason) {
                        this.errorReason = error.responseJSON.result.reason;
                    }
                    this.isGeneralError = true;
                } else if (error.status === 402) {
                    this.isBillingError = true;
                } else if (error.status === 429) {
                    this.isRateLimitError = true;
                } else {
                    this.isGeneralError = true;
                }
                this.isInFailure = true;
            } finally {
                this.isInProcess = false;
            }
        },
        async change (file) {
            if (file instanceof Array) {
                if (file.length === 0) {
                    return;
                }
                file = file[0]
            }
            this.file = file;
            await this.search();
        },
        async replay() {
            this.isInProcess = true;
            try {
                const result = await core.api.replaySearch(this.searchId, this.sort, this.isDesc);
                this.result = result.records;
            } catch {
                this.isInFailure = true;
            } finally {
                this.isInProcess = false;
            }
        }
    },
    directives: {
        fileChoose: FileChooseDirective,
        filePaste: FilePasteDirective,
        fileDrop: FileDropDirective
    },
    components: {
        spinner: SpinnerComponent,
        errorReason: ErrorReasonComponent,
        feedItem: FeedItemComponent,
        photo: ImageComponent,
        hint: HintComponent
    }
}
</script>

<style lang="scss" scoped>
.root {
    display: flex;
    flex-direction: column;
    > * {
        margin-bottom: 20px;
    }
    .dropbox {
        &[disabled] {
            opacity: 0.5;
            pointer-events: none;
        }
        .dropbox-click-area {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: absolute;
            color: #666;
            background: #ffffff;
            height: 100%;
            width: 100%;
            .message {
                text-transform: uppercase;
            }
            &.drag-hover {
                background: #ededed;
                border: 1px solid #cccccc;
            }
        }
        position: relative;
        border: 1px dashed #cccccc;
        .mode {
            display: flex;
            flex-direction: row;
            position: absolute;
            bottom: 5px;
            right: 5px;
            > span {
                display: flex;
                flex-direction: row;
            }
            > span:nth-child(1) {
                align-items: center;
                padding-right: 10px;
                &::after {
                    content: ':';
                }
            }
            > span:nth-child(2) {
                border-radius: 3px;
                box-shadow: 0 4px 8px rgb(0 0 0 / 4%), 0 0 4px rgb(0 0 0 / 6%);
                $_color: #43a0ed;
                overflow: hidden;
                border: 1px solid $_color;
                > span {
                    cursor: pointer;
                    padding: 2px 10px;
                    &[active], &:hover {
                        background: $_color;
                        color: #ffffff;
                    }
                    &:not([active]):not(:hover) {
                        color: #444;
                        background: #ffffff;
                    }
                    &:not(:last-child) {
                        border-right: 1px solid $_color;
                    }
                }
            }
        }
    }
}
[bold] {
    font-weight: bold;
}
.link {
    color: #0070e0;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
        text-decoration: none;
        color: #3eb8ff
    }
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    b {
        font-weight: bold;
    }
    > div:nth-child(1) {
        display: flex;
        flex-direction: column;
    }
    > div.sort-options {
        display: flex;
        flex-direction: row;
        > *:not(:last-child) {
            margin-right: 10px;
        }
    }
}
.result {
    display: flex;
    flex-direction: column;
    .result-entries {
        > * {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }
    > *:not(:last-child) {
        margin-bottom: 20px;
    }
}
.empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;;
    padding: 50px 0;
    font-size: 20px;
}
.result-flag-groups {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .flag-group {
        display: flex;
        flex-direction: row;
        flex-shrink: 0;
        padding: 5px 10px;
        border-radius: 2px;
        margin-bottom: 5px;
        line-height: 13px;
        &:not(:last-child) {
            margin-right: 5px;
        }
        > *:not(:last-child) {
            margin-right: 10px;
        }
        > *:last-child {
            padding-left: 10px;
        }
        &:not([active]) {
            &:not(:hover) {
                border: 1px solid #ededed;
            }
            &:hover {
                border: 1px solid #2288dd;
            }
            color: #666;
            cursor: pointer;
            > *:last-child {
                border-left: 1px solid #ededed;
            }
        }
        &[active] {
            background: #2288dd;
            color: #ffffff;
            border: 1px solid #2288dd;
            > *:last-child {
                border-left: 1px solid #ffffff;
            }
        }
    }
}
.failure {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;;
    padding: 50px 0;
    > *:not(:last-child) {
        margin-bottom: 50px;
    }
    .fas, .far {
        font-size: 50px;
        &.fa-exclamation-triangle {
            color: #f96565;
        }
        &.fa-ruble-sign {
            color: #cecdcd;
        }
        &.fa-hand-paper {
            color: #cecdcd;
        }
    }
    .text {
        font-size: 20px;
    }
}
.upload-dialog {
    display: flex;
    flex-direction: row;
    > * {
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
    .source-photo {
        width: 120px;
        flex-shrink: 0;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #ededed;
        border: 1px solid #cccccc;
        width: 120px;
        height: 120px;
        border-radius: 3px;
    }
    .dropbox {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        height: 120px;
    }
}
</style>