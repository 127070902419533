<template>
    <div class="tooltip-container" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
        <div class="tooltip" :class="{'show': isHovered, 'hide': !isHovered}">{{ text }}</div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isHovered: false
        };
    },
    methods: {
        handleMouseEnter() {
            this.isHovered = true;
        },
        handleMouseLeave() {
            this.isHovered = false;
        }
    }
};
</script>

<style scoped>
.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip {
    margin-top: 4px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #43a0ed;
    color: #fff;
    padding: 4px 8px;
    border-radius: 6px;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.tooltip::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 4px;
    border-style: solid;
    border-color: transparent transparent #43a0ed transparent;
}

.tooltip.show {
    opacity: 1;
}

.tooltip.hide {
    opacity: 0;
}
</style>
