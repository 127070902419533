<template lang="html">
<div class="feed-notification">
    <div class="feed-notification-header">
        <div class="feed-notification-type">
            <div v-if="record.objectType === 'news'">Сообщение от администрации ресурса</div>
            <div v-if="record.objectType === 'watch_hit'">Совпадение по установленной на мониторинг персоне</div>
        </div>
        <div class="feed-notification-date">
            <date v-bind:value="record.notification.dateTime"></date>
        </div>
    </div>
    <div class="feed-notification-content">
        <div v-if="record.objectType === 'news'">
            <pre>{{record.personContext.news.get(record.objectId).message}}</pre>
        </div>
        <div v-if="record.objectType === 'watch_hit'">
            <person-watch-hit-feed-record v-bind:hit-id="record.objectId" v-bind:context="context"></person-watch-hit-feed-record>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import {FeedNotification, PersonContext} from '../../core'
import PersonWatchHitFeedRecordComponent from './PersonWatchHitFeedRecord.vue';
import DateComponent from '../Date.vue';

export default {
    props: {
        record: {
            type: FeedNotification,
            required: true
        },
        context: {
            type: PersonContext,
            required: true
        }
    },
    components: {
        date: DateComponent,
        personWatchHitFeedRecord: PersonWatchHitFeedRecordComponent
    }
}
</script>

<style lang="scss" scoped>
.feed-notification {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    background: #ffffff;
    color: #27343b;
    border: 1px solid #eee;
    border-radius: 2px;
    padding: 10px;
    > *:not(:last-child) {
        margin-bottom: 10px;
    }
}
.feed-notification-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
    text-transform: uppercase;
    height: 20px;
    .feed-notification-date {
        display: flex;
        flex-direction: row;
        color: #27343b;
        font-weight: bold;
        align-items: center;
        justify-content: center;
    }
}
pre {
    white-space: pre-wrap;
    word-break: break-word;
    margin: 0;
}
</style>