<template lang="html">
<div class="company-root">
    <div v-if="context !== null && resolvedCompany !== null" class="company">
        <company-hit v-bind:company="resolvedCompany" v-bind:context="context"></company-hit>
        <div v-if="resolvedAttributes" class="company-attributes">
            <div v-for="value, key in resolvedAttributes" class="company-attribute">
                <span>{{key}}</span><span>{{value}}</span>
            </div>
        </div>
    </div>
    <spinner v-if="loading"></spinner>
</div>
</template>

<script lang="ts">
import {core} from '../../root';
import {PersonContext} from '../../core';
import SpinnerComponeent from '../Spinner.vue';
import CompanyHitComponent from './CompanyHit.vue';

export default {
    data: function () {
        return {
            loading: false,
            context: null
        }
    },
    props: {
        id: {
            type: String,
            required: true
        },
        searchId: {
            type: String,
            required: false
        }
    },
    created: function () {
        return this.load(this.id);
    },
    watch: {
        'id': function () {
            this.load();
        }
    },
    computed: {
        resolvedCompany() {
            let context = this.context as PersonContext;
            if (!context) {
                return null;
            }
            return context.companies.get(this.id);
        },
        resolvedAttributes() {
            let context = this.context as PersonContext;
            if (!context) {
                return null;
            }
            let has = context.companyAttributes.has(this.id);
            if (!has) {
                return null;
            }
            return context.companyAttributes.get(this.id);
        }
    },
    methods: {
        async load() {
            this.loading = true;
            try {
                this.context = await core.createPersonContextFromCompanyId(this.id, this.searchId);
            } finally {
                this.loading = false;
            }
        }
    },
    components: {
        companyHit: CompanyHitComponent,
        spinner: SpinnerComponeent
    }
}
</script>

<style lang="scss" scoped>
.company-root {
    display: felx;
    flex-direction: column;
    > *:not(:last-child) {
        margin-bottom: 10px;
    }
    .company {
        display: flex;
        flex-direction: column;
        border: 1px solid #dddddd;
        border-radius: 5px;
        padding: 10px 10px;
        > *:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}
.company-attributes {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: #f7f7f7;
    border: 1px solid #ccc;
    border-radius: 5px;
    .company-attribute {
        display: inline-block;
        border-radius: 5px;
        padding-right: 5px;
        padding-left: 5px;
        &:first-child {
            padding-bottom: 2px;
        }
        &:last-child {
            padding-top: 2px;
        }
        &:not(:last-child):not(:first-child) {
            padding-top: 2px;
            padding-bottom: 2px;
        }
        span:first-child {
            font-weight: bold;
            margin-right: 10px;
            &:after {
                content: ':';
            }
        }
    }
}
</style>