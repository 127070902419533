import $ from "jquery";

export default {
    mounted: function (element, binding) {
        const hiddenInput = '<input type="file" style="display: none" />';
        function onChange(event) {
            if(binding.value instanceof Function === false) {
                return;
            }
            if (binding.modifiers.multiple === true) {
                binding.value(event.target.files);
            } else {
                if (event.target.files.length === 0) {
                    binding.value(null);
                } else {
                    binding.value(event.target.files[0]);
                }
            }
        }
        function appendInput() {
            const inputElement = $(hiddenInput).on('change', function (event) {
                onChange(event);
                appendInput();
                $(this).remove();
                return false;
            }).on('click', function (event: Event) {
                event.stopPropagation();
            });
            if (binding.modifiers.multiple) {
                inputElement.attr('multiple', '1');
            }
            $(element).append(inputElement);
        }
        $(element).on('click', () => {
            $(element).find('input:file').trigger('click')
        });
        appendInput();
    },
    unmounted: function (element) {
        $(element).off();
        $(element).find('input:file').remove();
    }
};;