<template lang="html">
<img v-if="ready === true" v-on:click="click" v-bind:src="'data:image/png;base64,' + base64" v-bind:width="width" v-bind:height="height" />
</template>

<script lang="ts">
import {Util} from '../util';

export default {
    props: {
        content: {
            type: [File, String],
            required: true
        },
        sideSize: {
            type: Number,
            required: true
        },
        onClick: {
            type: Function,
            required: false
        }
    },
    data: function () {
        return {
            base64: null,
            height: null,
            width: null,
            ready: null
        };
    },
    watch: {
        async content() {
            await this.update();
        }
    },
    created: async function () {
        await this.update();
    },
    methods: {
        async update() {
            this.base64 = await Util.imageToBase64(this.content);
            const image = new Image();
            image.onload = () => {
                if (image.width > image.height) {
                    image.height = image.height * (this.sideSize / image.width);
                    image.width = this.sideSize;
                } else {
                    image.width = image.width * (this.sideSize / image.height);
                    image.height = this.sideSize;
                }
                this.width = image.width;
                this.height = image.height;
                this.ready = true;
            };
            image.src = 'data:image/png;base64,'+ this.base64;
        },
        click() {
            if (this.onClick instanceof Function) {
                this.onClick()
            }
        }
    }
}
</script>

<style lang="scss" scoped></style>