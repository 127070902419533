<template>
    <record-fields v-bind:context="context"></record-fields>
    <person-form-contacts v-bind:context="context"></person-form-contacts>
    <person-marks v-bind:context="context"></person-marks>
    <person-form-media v-bind:person="context" v-bind:context="context"></person-form-media>
</template>

<script>
import RecordFields from "./RecordFields.vue";
import PersonFormContacts from "./RecordContacts.vue";
import PersonFormMediaComponent from "./PersonFormMedia.vue";
import PersonMarksComponent from "./PersonMarks.vue";
import { RecordPersonContext } from "../../core";

export default {
    components: {
        recordFields: RecordFields,
        personFormContacts: PersonFormContacts,
        personMarks: PersonMarksComponent,
        personFormMedia: PersonFormMediaComponent
    },
    props: {
        context: {
            type: RecordPersonContext,
            required: true
        }
    },
};
</script>
