<template lang="html">
    <div class="feed">
        <div class="feed-sub-header">
            <div v-bind:disabled="loading === true ? true : null" class="feed-sub-header-buttons">
                <div class="feed-flag-filter" important="1">
                    <div v-bind:active="isRecordFeed === true ? true : null" v-on:click="changeFeedType(true)">
                        <div>Инциденты</div>
                    </div>
                    <div v-bind:active="isRecordFeed === false ? true : null" v-on:click="changeFeedType(false)">
                        <div>Персоны</div>
                    </div>
                </div>
                <div class="feed-flag-filter">
                    <div v-bind:active="flagFilter === null ? true : null" v-on:click="changeFlagFilter(null)">
                        <div>Любые</div>
                    </div>
                    <div v-bind:active="flagFilter === 'fraud' ? true : null" v-on:click="changeFlagFilter('fraud')">
                        <div>Мошенники</div>
                    </div>
                    <div
                        v-bind:active="flagFilter === 'shoplifter' ? true : null"
                        v-on:click="changeFlagFilter('shoplifter')"
                    >
                        <div>Шоплифтеры</div>
                    </div>
                    <div v-bind:active="flagFilter === 'cargo' ? true : null" v-on:click="changeFlagFilter('cargo')">
                        <div>Кража грузов</div>
                    </div>
                </div>
            </div>
            <div class="sort">
                <div v-bind:disabled="loading === true ? true : null" class="asc-desc">
                    <i
                        v-if="feedSort.sortDesc === true"
                        v-on:click="changeSortType(false)"
                        class="fa-solid fa-arrow-down-wide-short"
                    ></i>
                    <i
                        v-if="feedSort.sortDesc === false"
                        v-on:click="changeSortType(true)"
                        class="fa-solid fa-arrow-down-short-wide"
                    ></i>
                </div>
                <div
                    v-bind:active="dateFilterType !== null ? true : null"
                    v-bind:disabled="loading === true ? true : null"
                    v-dropdown="dateFilterDropdown"
                    class="date-filter-dropdown"
                >
                    <span v-if="dateFilterType === null">Фильтр по дате</span>
                    <span v-if="dateFilterType === 'timestamp'">
                        <string v-bind:value="'По дате публикации'" v-bind:max-length="13"></string>
                    </span>
                    <span v-if="dateFilterType === 'dateTime'">
                        <string
                            v-bind:value="'По дате ' + (isRecordFeed ? 'инцидента' : 'анкеты')"
                            v-bind:max-length="13"
                        ></string>
                    </span>
                    <span class="fa-solid fa-chevron-down"></span>
                </div>
                <div
                    v-bind:disabled="loading === true ? true : null"
                    v-dropdown="dateSortDropdown"
                    class="date-filter-dropdown"
                >
                    <span v-if="feedSort.sortField === 'timestamp'">
                        <i v-if="feedSort.sortDesc === true" class="fa-solid fa-arrow-down-wide-short"></i>
                        <i v-if="feedSort.sortDesc === false" class="fa-solid fa-arrow-down-short-wide"></i>
                        <string v-bind:value="'Опубликовано'" v-bind:max-length="10"></string>
                    </span>
                    <span v-if="feedSort.sortField === 'dateTime'">
                        <i v-if="feedSort.sortDesc === true" class="fa-solid fa-arrow-down-wide-short"></i>
                        <i v-if="feedSort.sortDesc === false" class="fa-solid fa-arrow-down-short-wide"></i>
                        <string
                            v-bind:value="'Дата ' + (isRecordFeed ? 'инцидента' : 'анкеты')"
                            v-bind:max-length="10"
                        ></string>
                    </span>
                    <span class="fa-solid fa-chevron-down"></span>
                </div>
            </div>
        </div>
        <div v-if="items != null && items.length === 0" class="feed-empty">
            <div>Ни одной записи, соответствующей условию, не найдено</div>
        </div>
        <div v-if="items != null && items.length !== 0" v-infinity-scroll="{ scroll: scroll }" class="feed-main">
            <div v-for="item in items" class="feed-content">
                <feed-item v-bind:item="item"></feed-item>
            </div>
        </div>
        <div v-if="items != null && items.length > 0" class="feed-footer">
            <button v-if="session !== null" v-bind:disabled="loading ? true : null" v-on:click="scroll">
                Загрузить ещё
            </button>
        </div>
        <spinner v-if="loading === true"></spinner>
    </div>
</template>

<script lang="ts">
import { core, modal } from "../../root";
import { FeedBatch, FeedRecord, FeedSettings, FeedType, RequestStatus, FeedDateFilter, FeedSort } from "../../core";
import FeedItemComponent from "./FeedItem.vue";
import FeedDateFilterSettingsComponent from "./FeedDateFilterSettings.vue";
import Spinner from "../Spinner.vue";
import StringComponent from "../String.vue";
import InfinityScrollDirective from "../../vueDirectives/InfinityScroll";
import DropdownDirective from "../../vueDirectives/Dropdown";

const dropdownStaticTop = 90;

function resolveIsRecordFeed(type: string) {
    if (type === "record") {
        return true;
    }
    if (type === "person") {
        return false;
    }
    return true;
}

export default {
    props: {
        onSettingsUpdated: {
            type: Function,
            required: false
        },
        defaultStatus: {
            type: String,
            required: false
        },
        defaultFlags: {
            type: String,
            required: false
        },
        type: {
            type: String,
            required: false
        }
    },
    data() {
        const data = new (class {
            public items: FeedRecord[] = null;
            public session: string = null;
            public loading: boolean = false;
            public statusFilter: RequestStatus = null;
            public isRecordFeed: boolean = null;
            public flagFilter: string = null;
            public dateFilter: FeedDateFilter = new FeedDateFilter();
            public dateFilterType: string = null;
            public feedSort: FeedSort = new FeedSort();
        })();
        data.isRecordFeed = resolveIsRecordFeed(this.type);
        if (typeof this.defaultStatus === "string") {
            data.statusFilter = <any>this.defaultStatus;
        }
        if (typeof this.defaultFlags === "string") {
            data.flagFilter = this.defaultFlags;
        }
        return data;
    },
    created: function() {
        this.load();
    },
    watch: {
        type(value) {
            let isRecordFeed = resolveIsRecordFeed(value);
            this.changeFeedType(isRecordFeed);
        }
    },
    computed: {
        hasDateFilter() {
            let dateFilter = this.dateFilter as FeedDateFilter;
            if (dateFilter.fromDateTime !== null) {
                return true;
            }
            if (dateFilter.tillDateTime !== null) {
                return true;
            }
            if (dateFilter.fromTimestamp !== null) {
                return true;
            }
            if (dateFilter.tillTimestamp !== null) {
                return true;
            }
            return false;
        },
        dateFilterDropdown() {
            return {
                id: "feed-date-filter",
                staticTop: dropdownStaticTop,
                items: [
                    {
                        name: "Без фильтрации",
                        active: this.dateFilterType === null,
                        onClick: () => {
                            this.dateFilterType = null;
                            this.openDateFilterModal();
                        }
                    },
                    {
                        name: "По дате публикации",
                        active: this.dateFilterType === "timestamp",
                        onClick: () => {
                            this.dateFilterType = "timestamp";
                            this.openDateFilterModal();
                        }
                    },
                    {
                        name: "По дате " + (this.isRecordFeed ? "инцидента" : "анкеты"),
                        active: this.dateFilterType === "dateTime",
                        onClick: () => {
                            this.dateFilterType = "dateTime";
                            this.openDateFilterModal();
                        }
                    }
                ],
                styles: {
                    left: true
                }
            };
        },
        dateSortDropdown() {
            return {
                id: "feed-date-sort",
                staticTop: dropdownStaticTop,
                items: [
                    {
                        name: "Сортировка по дате публикации",
                        active: this.feedSort.sortField === "timestamp",
                        onClick: () => {
                            this.feedSort.sortField = "timestamp";
                            this.load();
                        }
                    },
                    {
                        name: "Сортировка по дате " + (this.isRecordFeed ? "инцидента" : "анкеты"),
                        active: this.feedSort.sortField === "dateTime",
                        onClick: () => {
                            this.feedSort.sortField = "dateTime";
                            this.load();
                        }
                    }
                ],
                styles: {
                    left: true
                }
            };
        }
    },
    methods: {
        _buildSettings(): FeedSettings {
            let result = new FeedSettings();
            if (this.isRecordFeed) {
                result.type = FeedType.Record;
            } else {
                result.type = FeedType.Person;
            }
            if (this.statusFilter !== null) {
                result.status = this.statusFilter;
            }
            if (this.flagFilter !== null) {
                result.flags = this.flagFilter;
            }
            return result;
        },
        _onSettingsUpdated() {
            if (this.onSettingsUpdated instanceof Function === false) {
                return;
            }
            let settings = this._buildSettings();
            this.onSettingsUpdated(settings);
        },
        openDateFilterModal() {
            let dateFilter = this.dateFilter as FeedDateFilter;
            if (this.dateFilterType === null) {
                dateFilter.clear();
                this.load();
                return;
            }
            let onClose = () => this.load();
            let handler = modal.open(FeedDateFilterSettingsComponent, {
                data: {
                    filter: this.dateFilter,
                    filterType: this.dateFilterType,
                    onSubmit: () => {
                        onClose();
                        handler();
                    }
                },
                onClose: () => onClose(),
                dialog: true
            });
        },
        async _makeCall(passScroll: boolean): Promise<FeedBatch> {
            let scroll: string | null = passScroll ? this.session : null;
            let promise: Promise<FeedBatch>;
            if (this.isRecordFeed) {
                promise = core.api.getRecordFeed(
                    this.statusFilter,
                    this.flagFilter,
                    this.dateFilter,
                    this.feedSort,
                    scroll
                );
            } else {
                promise = core.api.getPersonFeed(
                    this.statusFilter,
                    this.flagFilter,
                    this.dateFilter,
                    this.feedSort,
                    scroll
                );
            }
            let result = await promise;
            return result;
        },
        async _loadFeed() {
            let batch: FeedBatch = await this._makeCall(false);
            this.items = batch.records;
            this.session = batch.session;
        },
        async changeSortType(sort: boolean) {
            if (this.feedSort.sortDesc === sort) {
                return;
            }
            if (this.loading) {
                return;
            }
            this.feedSort.sortDesc = sort;
            await this.load();
        },
        async changeFeedType(isRecordFeed: boolean) {
            if (this.isRecordFeed === isRecordFeed) {
                return;
            }
            this.isRecordFeed = isRecordFeed;
            this._onSettingsUpdated();
            await this.load();
        },
        async changeFlagFilter(type: string) {
            if (this.flagFilter === type) {
                return;
            }
            this.flagFilter = type;
            this._onSettingsUpdated();
            await this.load();
        },
        async load() {
            this.loading = true;
            this.items = null;
            await this._loadFeed();
            this.loading = false;
        },
        async scroll() {
            if (this.loading || !this.session) {
                return;
            }
            this.loading = true;
            let batch = await this._makeCall(true);
            for (var i = 0; i < batch.records.length; i++) {
                this.items.push(batch.records[i]);
            }
            this.session = batch.session;
            this.loading = false;
        }
    },
    components: {
        feedItem: FeedItemComponent,
        spinner: Spinner,
        string: StringComponent
    },
    directives: {
        infinityScroll: InfinityScrollDirective,
        dropdown: DropdownDirective
    }
};
</script>

<style lang="scss" scoped>
.date-filter-dropdown {
    display: flex;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    height: 24px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    box-sizing: border-box;
    background: #ffffff;
    padding: 0 5px;
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    > span:first-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 10px;
        width: 90px;
        > *:not(:last-child) {
            margin-right: 5px;
        }
    }
    &[active] {
        background: #bef9c5;
    }
    &[disabled] {
        opacity: 0.7;
    }
}
.feed {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    > *:not(:last-child) {
        margin-bottom: 10px;
    }
}
.feed-content {
    &:not(:last-child) {
        margin-bottom: 20px;
    }
}
.asc-desc {
    cursor: pointer;
    color: #005fd5;
    &:hover {
        color: #41a2f5;
    }
    &[disabled] {
        opacity: 0.5;
        pointer-events: none;
    }
}
.feed-links {
    font-weight: bold;
}
.feed-sub-header-buttons {
    display: flex;
    flex-direction: row;
    > *:not(:last-child) {
        margin-right: 10px;
    }
    &[disabled] {
        opacity: 0.5;
        pointer-events: none;
    }
}
.date-filter-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    > * {
        &:not(:last-child) {
            margin-right: 5px;
        }
    }
    .fa-toggle-off {
        color: #666;
    }
    .fa-toggle-on {
        color: #27ad27;
    }
    &[disabled] {
        opacity: 0.5;
        pointer-events: none;
    }
    &:hover {
        color: #666;
    }
}
.feed-sub-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    background: #ffffff;
    z-index: 500;
    top: 60;
    > * {
        flex-shrink: 0;
    }
    .feed-flag-filter {
        display: flex;
        flex-direction: row;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgb(0 0 0 / 4%), 0 0 4px rgb(0 0 0 / 6%);
        overflow: hidden;
        &:not([important]) {
            $_color: #eaeaea;
            border: 1px solid #bbb;
            > div {
                cursor: pointer;
                padding: 2px 10px;
                &[active],
                &:hover {
                    background: $_color;
                }
                &:not([active]):not(:hover) {
                    background: #ffffff;
                }
                &:not(:last-child) {
                    border-right: 1px solid $_color;
                }
            }
        }
        &[important] {
            $_color: #43a0ed;
            border: 1px solid $_color;
            > div {
                cursor: pointer;
                padding: 2px 10px;
                &[active],
                &:hover {
                    background: $_color;
                    color: #ffffff;
                }
                &:not([active]):not(:hover) {
                    color: #444;
                    background: #ffffff;
                }
                &:not(:last-child) {
                    border-right: 1px solid $_color;
                }
            }
        }
    }
    .sort {
        display: flex;
        flex-direction: row;
        align-items: center;
        > *:not(:last-child) {
            margin-right: 10px;
        }
        select {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 12px;
            height: 24px;
            border: 1px solid #cccccc;
            border-radius: 3px;
            &:focus {
                outline: none;
            }
        }
    }
}
.feed-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    button {
        background: #ffffff;
        border-radius: 3px;
        border: 1px solid #8e8e8e;
        cursor: pointer;
        padding: 10px 20px;
        &:focus {
            outline: none;
        }
        &:hover {
            background: #8e8e8e;
            color: #ffffff;
        }
    }
}
.feed-empty {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}
.feed-header {
    display: flex;
    flex-direction: row;
    flex-shrink: 1;
    justify-content: space-between;
    .feed-header-mode {
        display: flex;
        flex-direction: row;
        border: 1px solid #dedede;
        > * {
            display: flex;
            flex-direction: row;
            padding: 5px 10px;
            cursor: pointer;
            &[active] {
                background: #ededed;
            }
            &:not([active]) {
                background: #ffffff;
            }
        }
    }
    .feed-header-filter {
        display: flex;
        flex-direction: row;
        border: 1px solid #dedede;
        .status-line {
            display: flex;
            flex-direction: row;
            cursor: pointer;
            &[active] {
                background: #ededed;
            }
            &:not([active]) {
                background: #ffffff;
            }
            div {
                padding: 5px 10px;
                pointer-events: none;
            }
            .status-name {
                min-width: 80px;
            }
            &:not(:last-child) {
                border-right: 1px solid #ededed;
            }
        }
    }
}
</style>
