<template lang="html">
<div class="record-confirm">
    <hint v-bind:text="'Данные сохранены. Запись требуется <b>опубликовать</b> &mdash; после этого она станет достпуна в поиске'"></hint>
    <feed-item v-if="feedRecord" v-bind:item="feedRecord"></feed-item>
    <div v-if="feedRecord" class="footer">
        <submit-button v-bind:on-click="returnBack" v-bind:context="context" v-bind:message="'Редактировать'"></submit-button>
        <submit-button v-bind:on-click="commit" v-bind:context="context" v-bind:message="'Опубликовать'" v-bind:important="true"></submit-button>
    </div>
    <div v-if="!!context.similar[context.similarFilter]" class="similar">
        <div class="similar-header">
            <div>Похожие персоны</div>
            <div></div>
        </div>
        <div class="similar-navbar">
            <div class="similar-filter">
                <div v-on:click="changeSimilarFilter(status.code)" v-bind:disabled="(status.count === 0 || context.isInSimilarLoading) ? true : null" v-bind:active="(context.similarFilter === status.code) ? true : null" v-for="status in context.similarCounters" class="status-line">
                    <div class="status-name">{{status.name}}</div>
                    <div class="status-count">{{status.count}}</div>
                </div>
            </div>
        </div>
        <div v-if="context.isInSimilarLoading !== true && !!context.similar[context.similarFilter]" class="similar-records-face">
            <div v-for="v, k in context.similar[context.similarFilter].face">
                <feed-item v-bind:item="item" v-for="item in v.records"></feed-item>
            </div>
        </div>
        <div v-if="context.isInSimilarLoading !== true && !!context.similar[context.similarFilter]" class="similar-records-text">
            <div v-if="context.similar[context.similarFilter].text.records.length !== 0" v-infinity-scroll="{scroll: scroll}" class="feed-content">
                <feed-item v-bind:item="item" v-for="item in context.similar[context.similarFilter].text.records"></feed-item>
            </div>
            <div v-if="context.similar[context.similarFilter].text.records != null && context.similar[context.similarFilter].text.records.length > 0" class="feed-footer">
                <button v-if="context.similar[context.similarFilter].text.session !== null" v-bind:disabled="loading ? true : null" v-on:click="scroll">Загрузить ещё</button>
            </div>
        </div>
    </div>
    <spinner v-if="context.isInSimilarLoading || loading"></spinner>
</div>
</template>

<script lang="ts">
import {core} from '../../root'
import {RequestContext, RequestFeedRecord} from '../../core'
import FeedItemComponent from './FeedItem.vue';
import RequestFormButtonComponent from './RequestFormButton.vue';
import SpinnerComponent from '../Spinner.vue';
import HintComponent from '../Hint.vue';
import InfinityScrollDirective from '../../vueDirectives/InfinityScroll';

export default {
    props: {
        context: {
            type: RequestContext,
            required: true
        }
    },
    data() {
        return {
            loading: true,
            feedRecord: null,
            mainContext: null
        };
    },
    async created() {
        const context = this.context;
        const recordId = this.context.record.recordId;
        try {
            let item = new RequestFeedRecord();
            item.recordId = recordId;
            item.personContext = await core.createPersonContextFromRecordId(recordId);
            this.feedRecord = item;
        } finally {
            this.loading = false;
        }
        context.isInSimilarLoading = true;
        context.similarFilter = 'all';
        try {
            const result = await core.api.searchSimilar(recordId);
            context.similar['all'] = result;
            context.similarCounters = [
                {code: 'all', name: 'Все', count: result.counts['unique']},
                {code: 'face', name: 'Лица', count: result.counts['face']},
                {code: 'person', name: 'Ф.И.О. + д/р', count: result.counts['person']},
                {code: 'passport', name: 'Паспорт', count: result.counts['passport']},
                {code: 'phone', name: 'Телефон', count: result.counts['phone']},
                {code: 'email', name: 'E-mail', count: result.counts['email']}
            ];
        } catch (error) {
            console.log(error)
        } finally {
            context.isInSimilarLoading = false;
        }
    },
    components: {
        feedItem: FeedItemComponent,
        submitButton: RequestFormButtonComponent,
        spinner: SpinnerComponent,
        hint: HintComponent
    },
    directives: {
        infinityScroll: InfinityScrollDirective
    },
    methods: {
        returnBack() {
            this.context.moveBack();
        },
        async changeSimilarFilter(filter:string) {
            const context = this.context;
            if (filter in context.similar === false) {
                const recordId = this.context.record.recordId;
                context.isInSimilarLoading = true;
                try {
                    const filterParam = filter === 'all' ? null : filter;
                    context.similar[filter] = await core.api.searchSimilar(recordId, filterParam);
                } catch (error) {
                    console.log(error)
                } finally {
                    context.isInSimilarLoading = false;
                }
            }
            context.similarFilter = filter;
        },
        async commit() {
            this.context.isInProcess = true;
            try {
                await core.api.commitRecord(this.context.record.recordId);
                this.context.isInProcess = false;
                this.context.moveNext();
            } catch {
                this.context.isInFailure = true;
            }
        },
        async scroll() {
            let context = this.context as RequestContext;
            let scope = context.similar[context.similarFilter].text;
            if (this.loading || !scope.session) {
                return;
            }
            this.loading = true;
            let session = scope.session;
            let searchId = scope.searchId;
            const batch = await core.api.searchScroll(searchId, session);
            for (var i = 0; i < batch.records.length; i++) {
                scope.records.push(batch.records[i]);
            }
            scope.session = batch.session;
            this.loading = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.record-confirm {
    display: flex;
    flex-direction: column;
    > *:not(:last-child) {
        margin-bottom: 20px;
    }
}
.similar {
    display: flex;
    flex-direction: column;
    > * > * {
        margin-bottom: 20px;
    }
}
.similar-header {
    $_height: 40px;
    display: flex;
    flex-direction: row;
    line-height: $_height;
    margin-bottom: 20px;
    height: $_height;
    font-size: 14px;
    > div {
        display: flex;
        flex-direction: row;
        flex-shrink: 0;
    }
    > div:nth-child(1) {
        padding-right: 20px;
        height: $_height;
    }
    > div:nth-child(2) {
        border-bottom: 1px solid #cccccc;
        flex-grow: 1;
        height: calc($_height / 2);
    }
}
.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.similar-navbar {
    display: flex;
    flex-direction: row;
}
.similar-records-text {
    display: flex;
    flex-direction: column;
    > .feed-content {
        display: flex;
        flex-direction: column;
        > *:not(:last-child) {
            margin-bottom: 20px;
        }   
    }
}
.similar-records-face {
    display: flex;
    flex-direction: column;
    > div {
        display: flex;
        flex-direction: column;
        > *:not(:last-child) {
            margin-bottom: 20px;
        }
    }
}
.similar-filter {
    display: flex;
    flex-direction: row;
    border: 1px solid #dedede;
    .status-line {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        &[active] {
            background: #ededed;
        }
        &:not([active]) {
            background: #ffffff;
        }
        div {
            padding: 5px 10px;
            pointer-events: none;
        }
        &[disabled] {
            opacity: 0.4;
            pointer-events: none;
        }
        .status-name {
            min-width: 60px;
        }
        &:not(:last-child) {
            border-right: 1px solid #ededed;
        }
    }
}
.feed-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    button {
        background: #ffffff;
        border-radius: 3px;
        border: 1px solid #8e8e8e;
        cursor: pointer;
        padding: 10px 20px;
        &:focus {
            outline: none;
        }
        &:hover {
            background: #8e8e8e;
            color: #ffffff;
        }
    }
}
</style>