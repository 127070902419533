<template lang="html">
    <div class="feed-record">
        <div class="record-header">
            <div class="record-human-id">
                <span>Инцидент</span>
                <span v-if="resolvedRecord.status !== 1">
                    <human-id
                        v-bind:value="resolvedRecord.humanId"
                        v-bind:prefix="'R'"
                        v-bind:href="'#/record/' + resolvedRecord.recordId"
                    ></human-id>
                    <span v-if="resolvedRecord.status === 4"> опубликован</span>
                    <span v-if="resolvedRecord.status === 2"> в обработке</span>
                </span>
                <a
                    v-bind:href="'#/form/' + resolvedRecord.recordId + '/2'"
                    v-if="resolvedRecord.status === 1"
                    class="id-placeholder"
                    >не опубликован</a
                >
                <span class="record-date-separator">от</span>
                <span class="record-date">
                    <a v-bind:href="link" target="_blank"
                        ><date
                            v-bind:value="resolvedRecord.dateTime || resolvedRecord.timestamp"
                            v-bind:no-time="true"
                        ></date
                    ></a>
                    <span class="create-date"
                        >Создан &mdash; <date v-bind:value="resolvedRecord.timestamp" v-bind:no-time="true"></date
                    ></span>
                </span>
            </div>
            <div class="separator"></div>
            <div v-if="canEdit" v-on:click="navigateToEdit" class="button " title="Редактировать">
                <a v-bind:href="editLink" class="link">
                    <i class="fa-solid fa-pencil"></i>
                </a>
            </div>
            <div v-on:click="exportRecord" v-if="canExport" class="button" title="Экспортировать">
                <span class="fa-solid fa-download"></span>
            </div>
            <div v-if="member" class="user" title="Автор инцидента">
                <i class="fa-solid fa-user-tie"></i>
                <humanId v-if="member" v-bind:value="member.humanId" v-bind:prefix="'U'"></humanId>
            </div>
        </div>
        <div v-if="resolvedRecord.importId !== null && isAdmin" class="record-loss">
            <span>Идентификатор импорта</span>
            <span>{{ resolvedRecord.importId }}</span>
        </div>
        <div
            v-if="resolvedRecord.location.lat !== null && resolvedRecord.location.lng !== null"
            class="record-location"
        >
            <span>Место инцидента</span>
            <span>{{ resolvedRecord.location.lat.toFixed(4) }}, {{ resolvedRecord.location.lng.toFixed(4) }}</span>
            <span v-if="resolvedAddress">{{ resolvedAddress }}</span>
        </div>
        <div v-if="resolvedRecord.loss !== null" class="record-loss">
            <span>Сумма ущерба</span>
            <span>
                <number v-bind:value="resolvedRecord.loss"></number>
            </span>
        </div>
        <div class="record-comment">
            <span v-if="resolvedRecord.comment" class="fable">{{ resolvedRecord.comment }}</span>
            <span v-if="!resolvedRecord.comment" class="record-missing-fable">Фабула отсутствует</span>
        </div>
        <div v-if="isEmpty" class="no-persons">
            <span>К записи не прикреплено ни одного участника</span>
            <a v-bind:href="editLink">Редактировать</a>
        </div>
        <div v-if="isEmpty === false" class="record-persons">
            <div v-for="person in persons">
                <incident-person v-if="!!person" v-bind:person="person" v-bind:context="context"></incident-person>
                <div v-if="!person" class="access-denied">
                    <div>У Вас нет необходимых прав доступа для просмотра этого сегмента данных</div>
                </div>
            </div>
            <div v-for="company in companies">
                <incident-company v-bind:company="company" v-bind:context="context"></incident-company>
            </div>
        </div>
        <div v-if="hasMedia" class="media">
            <record-media v-bind:record="resolvedRecord" v-bind:context="context"></record-media>
        </div>
        <div v-if="hasFlags" class="record-footer">
            <span class="record-flags">
                <span v-if="resolvedRecord.flags.isFraud === true">
                    <span class="fa-solid fa-tag"></span>
                    <span>Мошенничество</span>
                </span>
                <span v-if="resolvedRecord.flags.isBankFraud === true">
                    <span class="fa-solid fa-tag"></span>
                    <span>Банковское мошенничество</span>
                </span>
                <span v-if="resolvedRecord.flags.isMicrofinanceFraud === true">
                    <span class="fa-solid fa-tag"></span>
                    <span>Мошенничество МФО</span>
                </span>
                <span v-if="resolvedRecord.flags.isInsuranceFraud === true">
                    <span class="fa-solid fa-tag"></span>
                    <span>Страховое мошенничество</span>
                </span>
                <span v-if="resolvedRecord.flags.isMoneyLaundering === true">
                    <span class="fa-solid fa-tag"></span>
                    <span>Обналичивание</span>
                </span>
                <span v-if="resolvedRecord.flags.isCargoThief === true">
                    <span class="fa-solid fa-tag"></span>
                    <span>Кража грузов</span>
                </span>
                <span v-if="resolvedRecord.flags.isShopThief === true">
                    <span class="fa-solid fa-tag"></span>
                    <span>Магазинная кража</span>
                </span>
                <span v-if="resolvedRecord.flags.isEmployRisk === true">
                    <span class="fa-solid fa-tag"></span>
                    <span>Риск при найме</span>
                </span>

                <span v-if="resolvedCompanyFlags.isPartnershipRisk === true">
                    <span class="fa-solid fa-tag"></span>
                    <span>Риск сотрудничества</span>
                </span>
                <span v-if="resolvedCompanyFlags.isDefaultRisk === true">
                    <span class="fa-solid fa-tag"></span>
                    <span>Риск неисполнения обязательств</span>
                </span>
                <span v-if="resolvedCompanyFlags.isCriminal === true">
                    <span class="fa-solid fa-tag"></span>
                    <span>Налоговые риски</span>
                </span>
                <span v-if="resolvedCompanyFlags.isTaxRisk === true">
                    <span class="fa-solid fa-tag"></span>
                    <span>Криминал</span>
                </span>
                <span v-if="resolvedCompanyFlags.isFraud === true">
                    <span class="fa-solid fa-tag"></span>
                    <span>Мошенничество</span>
                </span>
            </span>
        </div>
    </div>
</template>

<script lang="ts">
import { core } from "../../root";
import { Record, PersonContext, RecordPerson, RequestFeedRecord, Company, CompanyFlags } from "../../core";
import RecordMediaComponent from "./RecordMedia.vue";
import IncidentPersonComponent from "./IncidentPerson.vue";
import IncidentCompany from "./IncidentCompany.vue";
import HumanIdComponent from "../common/HumanId.vue";
import DateComponent from "../Date.vue";
import NumberComponent from "../Number.vue";

export default {
    props: {
        record: {
            type: RequestFeedRecord,
            required: true
        },
        context: {
            type: PersonContext,
            required: true
        }
    },
    computed: {
        isInLegalMode(): boolean {
            return core.isInLegalMode;
        },
        isMineRecord(): boolean {
            let record = this.resolvedRecord as Record;
            return record.authorId === core.auth.user.id;
        },
        resolvedRecord(): Record {
            let context = this.context as PersonContext;
            let record = this.record as RequestFeedRecord;
            return context.records.get(record.recordId);
        },
        resolvedAddress() {
            let record = this.resolvedRecord as Record;
            let geoHash = record.location.geoHash;
            if (!geoHash) {
                return null;
            }
            let context = this.context as PersonContext;
            let has = context.resolvedLocations.has(geoHash);
            if (has !== true) {
                return null;
            }
            let location = context.resolvedLocations.get(geoHash);
            if (!location.address) {
                return null;
            }
            return location.address;
        },
        persons() {
            let persons = new Array<RecordPerson>();
            let context = this.context as PersonContext;
            let hasConnections = context.connections.has(this.record.recordId);
            if (hasConnections !== true) {
                return persons;
            }
            let connections = context.connections.get(this.record.recordId);
            for (let i = 0; i < connections.length; i++) {
                let personId = connections[i];
                let isHidden = context.hiddenPersons.indexOf(personId) !== -1;
                if (isHidden) {
                    persons.push(null);
                } else {
                    let person = context.persons.get(personId);
                    persons.push(person);
                }
            }
            return persons;
        },
        companies() {
            let companies = new Array<Company>();
            let context = this.context as PersonContext;
            let hasConnections = context.recordToCompany.has(this.record.recordId);
            if (hasConnections !== true) {
                return companies;
            }
            let connections = context.recordToCompany.get(this.record.recordId);
            for (let i = 0; i < connections.length; i++) {
                let companyId = connections[i];
                let company = context.companies.get(companyId);
                companies.push(company);
            }
            return companies;
        },
        resolvedCompanyFlags() {
            let companies: Array<Company> = this.companies;
            let flags = new CompanyFlags();
            for (let company of companies) {
                if (company.flags.isFraud === true) {
                    flags.isFraud = true;
                }
                if (company.flags.isCriminal === true) {
                    flags.isCriminal = true;
                }
                if (company.flags.isDefaultRisk === true) {
                    flags.isDefaultRisk = true;
                }
                if (company.flags.isPartnershipRisk === true) {
                    flags.isPartnershipRisk = true;
                }
                if (company.flags.isTaxRisk === true) {
                    flags.isTaxRisk = true;
                }
            }
            return flags;
        },
        link() {
            let result = "#/record/" + this.record.recordId;
            if (this.context.searchId) {
                result = result + "?search=" + this.context.searchId;
            }
            return result;
        },
        isEmpty() {
            if (this.persons.length > 0) {
                return false;
            }
            if (this.companies.length > 0) {
                return false;
            }
            return true;
        },
        isAdmin() {
            let adminIndex = core.auth.user.roles.indexOf("admin");
            if (adminIndex !== -1) {
                return true;
            }
            return false;
        },
        canEdit() {
            let record = this.resolvedRecord;
            if (record.status === 1) {
                return true;
            }
            if (this.isAdmin === true) {
                return true;
            }
            let editorIndex = core.auth.user.roles.indexOf("editor");
            if (editorIndex !== -1) {
                return true;
            }
            let localEditorIndex = core.auth.user.roles.indexOf("editor_local");
            if (localEditorIndex !== -1 && core.auth.user.id === record.authorId) {
                return true;
            }
            return false;
        },
        canExport() {
            if (this.isMineRecord === true) {
                return true;
            }
            if (this.isInLegalMode === true) {
                return false;
            }
            return true;
        },
        member() {
            let context = this.context as PersonContext;
            let record = this.resolvedRecord as Record;
            return context.users.get(record.authorId);
        },
        hasMedia() {
            let record = this.resolvedRecord as Record;
            let context = this.context as PersonContext;
            if (!!record.files && record.files.length > 0) {
                return true;
            }
            if (!!record.photos && record.photos.length > 0) {
                return true;
            }
            let hasConnection = context.connections.has(record.recordId);
            if (hasConnection !== true) {
                return false;
            }
            let connections = context.connections.get(record.recordId);
            for (let personId of connections) {
                let has = context.persons.has(personId);
                if (has !== true) {
                    continue;
                }
                let person = context.persons.get(personId);
                if (person.files.length > 0) {
                    return true;
                }
                if (person.photos.length > 0) {
                    return true;
                }
            }
            return false;
        },
        hasFlags() {
            let record: Record = this.resolvedRecord;
            let keys = Object.keys(record.flags);
            for (let i = 0; i < keys.length; i++) {
                if (record.flags[keys[i]] === true) {
                    return true;
                }
            }
            let resolvedCompanyFlags = this.resolvedCompanyFlags;
            let companyFlagsKeys = Object.keys(resolvedCompanyFlags);
            for (let i = 0; i < companyFlagsKeys.length; i++) {
                if (resolvedCompanyFlags[companyFlagsKeys[i]] === true) {
                    return true;
                }
            }
            return false;
        },
        editLink() {
            if (!this.resolvedRecord) {
                return null;
            }
            return "#/form/" + this.resolvedRecord.recordId + "/1";
        }
    },
    methods: {
        exportRecord() {
            core.api.exportRecord(this.record.recordId, this.context.searchId);
        },
        navigateToEdit() {
            window.location = this.editLink;
        }
    },
    components: {
        date: DateComponent,
        number: NumberComponent,
        recordMedia: RecordMediaComponent,
        incidentPerson: IncidentPersonComponent,
        incidentCompany: IncidentCompany,
        humanId: HumanIdComponent
    }
};
</script>

<style lang="scss" scoped>
.separator {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}
.button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4 5px;
    cursor: pointer;
    color: #2288dd;
    border-radius: 5px;
    border: 1px solid #4499e1;
    &:hover,
    a:hover {
        background: #2288dd;
        color: #fff;
        > a {
            color: #fff;
        }
    }
}
.user {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    font-family: monospace;
    padding: 0 10px;
    > *:not(:last-child) {
        margin-right: 5px;
    }
    i {
        font-size: 10px;
        color: #000;
    }
}
.media {
    padding: 0 20px;
}
.record-human-id {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-weight: bold;
    > *:not(:last-child) {
        margin-right: 5px;
    }
    .id-placeholder {
        font-weight: bold;
        color: #f12b2b;
    }
}
.record-footer {
    padding: 0 20px;
}
.record-header {
    display: flex;
    flex-direction: row;
    padding: 0 20px;
    > * {
        flex-shrink: 0;
        &:not(:last-child):not(.separator) {
            margin-right: 5px;
        }
    }
}
.record-persons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 10px;
    > * {
        width: 50%;
        box-sizing: border-box;
        &:not(:last-child):not(:nth-last-child(-n + 2):not(:nth-child(even))) {
            margin-bottom: 20px;
        }
    }
}
.record-date-separator {
    font-weight: normal;
}
.record-date {
    color: #666;
    > *:not(:last-child) {
        margin-right: 5px;
    }
    .create-date {
        font-weight: normal;
        color: #aaa;
        &::before {
            content: "(";
        }
        &::after {
            content: ")";
        }
    }
}
.record-location {
    > *:nth-child(2) {
        $_color: #aaa;
        color: $_color;
        margin-right: 5px;
        &::before {
            content: "(";
            color: $_color;
        }
        &::after {
            content: ")";
            color: $_color;
        }
    }
}
.record-loss,
.record-location {
    display: inline-block;
    padding: 0 20px;
    > *:first-child {
        font-weight: bold;
        margin-right: 10px;
        &::after {
            content: ":";
        }
    }
}
.record-flags {
    display: inline-block;
    margin-right: 5px;
    > span {
        display: inline-block;
        font-size: 10px;
        text-transform: uppercase;
        color: #cf6565;
        &:not(:last-child) {
            margin-right: 15px;
        }
        > *:not(:last-child) {
            margin-right: 5px;
        }
    }
}
.record-comment {
    display: inline-block;
    padding: 0 20px;
    a,
    .link {
        text-decoration: none;
    }
    .record-missing-fable {
        color: #ccc;
    }
}
.record-author {
    margin-left: 20px;
}
.access-denied {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #ccc;
    text-transform: uppercase;
    text-align: center;
}
.no-persons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    color: #9b9b9b;
    text-transform: uppercase;
    > *:not(:last-child) {
        margin-bottom: 10px;
    }
}
.feed-record {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    background: #ffffff;
    color: #27343b;
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 10px 0;
    > *:not(:last-child) {
        margin-bottom: 10px;
    }
}
.fable {
    white-space: pre-wrap;
    word-break: break-word;
    margin: 0;
}
</style>
