import $ from "jquery";
    
export default {
    mounted: function (element, binding) {
        $(element).bind('dragover', function () {
            $(element).addClass('drag-hover');
            return false;
        }).bind('dragleave', function() {
            $(element).removeClass('drag-hover');
            return false;
        }).bind('drop', function (event) {
            const files = event.originalEvent.dataTransfer.files;
            event.preventDefault();
            $(element).removeClass('drag-hover');
            if(binding.value instanceof Function) {
                if (binding.modifiers.multiple === true) {
                    binding.value(files);
                } else {
                    if (files.length === 0) {
                        binding.value(null);
                    } else {
                        binding.value(files[0]);
                    }
                }
            }
            return false;
        }).addClass('drag-area');
    },
    unmounted: function (element) {
        $(element).removeClass('drag-area').off();
    }
};