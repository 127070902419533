import { createApp } from "vue";
import Main from "./vueComponent/Main.vue";
import "@fortawesome/fontawesome-free/css/all.css";
import "regenerator-runtime/runtime";
import { core, routingScope, modal, modalCompilerVueConfigExtension } from "./root";
import PersonIncidentsComponent from "./vueComponent/request/PersonIncidents.vue";
import CompanyRecordComponent from "./vueComponent/request/CompanyRecord.vue";
import PersonBoundComponent from "./vueComponent/request/PersonBound.vue";
import "v-calendar/dist/style.css";

const globalProperties: any = {
    routingScope: routingScope,
    openIncidentsModal: (personId: string, searchId: string | null) => {
        const data = { id: personId, searchId: searchId };
        modal.open(PersonIncidentsComponent, { data: data, dialog: true });
    },
    openBoundModal: (personId: string, searchId: string | null) => {
        const data = { id: personId, searchId: searchId, noHeadline: true };
        modal.open(PersonBoundComponent, { data: data, dialog: true });
    },
    openCompanyRecordsModal: (companyId: string, searchId: string | null) => {
        const data = { id: companyId, searchId: searchId };
        modal.open(CompanyRecordComponent, { data: data, dialog: true });
    }
};

core.start().then(() => {
    let app = createApp(Main);
    Object.keys(globalProperties).forEach(_ => {
        app.config.globalProperties[_] = globalProperties[_];
        modalCompilerVueConfigExtension[_] = globalProperties[_];
    });
    app.mount("#app");
});
