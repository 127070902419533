<template lang="html">
<div class="alerts">
    <hint v-if="loading !== true" v-bind:text="'Новые уведомления, требующие подтверждения о прочтении'"></hint>
    <div v-if="loading !== true && records != null">
        <div v-for="item in records" class="notification-feed-content">
            <feed-item v-bind:item="item"></feed-item>
        </div>
    </div>
    <div v-if="loading !== true" class="alerts-footer">
        <div>{{records.length === 1 ? 'Это' : 'Эти'}} и другие уведомления доступны в <a href="#/notification">ленте уведомлений</a></div>
        <button v-bind:disabled="!loading ? null : true" v-on:click="close">Закрыть</button>
    </div>
    <spinner v-if="loading === true"></spinner>
</div>
</template>

<script lang="ts">
import {core} from '../root';
import {FeedRecord, FeedNotification} from '../core';
import HintComponent from './Hint.vue';
import FeedItemComponent from './request/FeedItem.vue';
import Spinner from './Spinner.vue';

export default {
    data() {
        return new class {
            public records: FeedRecord[] = null;
            public loading: boolean = false;
        }();
    },
    async created() {
        await this.load();
    },
    methods: {
        async load() {
            if (this.loading === true) {
                return;
            }
            this.loading = true;
            try {
                let feed = await core.api.getAlertFeed();
                this.records = feed.records;
            } finally {
                this.loading = false;
            }
        },
        async close() {
            if (this.loading === true) {
                return;
            }
            this.loading = true;
            try {
                let records = this.records as Array<FeedRecord>;
                for (let record of records) {
                    if (record instanceof FeedNotification === false) {
                        continue;
                    }
                    let feedNotification = record as FeedNotification;
                    await core.api.closeAlert(feedNotification.notification.id);
                }
            } finally {
                this.$emit('close')
            }
        }
    },
    components: {
        feedItem: FeedItemComponent,
        hint: HintComponent,
        spinner: Spinner
    }
}
</script>

<style lang="scss" scoped>
.alerts {
    display: flex;
    flex-direction: column;
    > *:not(:last-child) {
        margin-bottom: 20px;
    }
}
.alerts-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #aaa;
    button {
        background: rgba(0, 0, 0, 0.05);
        border: 0;
        color: #3a3a3a;
        border-radius: 50px;
        text-transform: uppercase;
        border: 1px solid #c1c1c1;
        height: 30px;
        padding: 0 10px;
        cursor: pointer;
        &:hover {
            background: rgba(0, 0, 0, 0.03);
        }
    }
}
.notification-feed-content {
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
}
</style>