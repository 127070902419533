<template lang="html">
    <div v-file-paste="onDocumentChanged" class="root">
        <hint
            v-bind:text="'Поиск по записям. Позволяет найти ранее внесённые записи по указанным установочным данным'"
        ></hint>
        <div class="request">
            <div v-if="!isInLegalMode">
                <div class="field-block-content">
                    <div class="field">
                        <div>Фамилия</div>
                        <div>
                            <input
                                v-on:keyup.enter="search"
                                v-bind:disabled="isFormDisabled ? true : null"
                                v-model="query.lastname"
                                type="text"
                                placeholder="Фамилия персоны"
                            />
                        </div>
                    </div>
                    <div class="field">
                        <div>Имя</div>
                        <div>
                            <input
                                v-on:keyup.enter="search"
                                v-bind:disabled="isFormDisabled ? true : null"
                                v-model="query.firstname"
                                type="text"
                                placeholder="Имя персоны"
                            />
                        </div>
                    </div>
                    <div class="field">
                        <div>Отчество</div>
                        <div>
                            <input
                                v-on:keyup.enter="search"
                                v-bind:disabled="isFormDisabled ? true : null"
                                v-model="query.patronymic"
                                type="text"
                                placeholder="Отчество персоны"
                            />
                        </div>
                    </div>
                    <div class="field">
                        <div>Дата рождения</div>
                        <div>
                            <DatePicker
                                v-model="query.birthday"
                                :locale="'ru-RU'"
                                :masks="masks"
                                :popover="PopoverOptions"
                            >
                                <template v-slot="{ inputValue, inputEvents }">
                                    <input
                                        :value="inputValue"
                                        v-on="inputEvents"
                                        placeholder="дд.мм.гггг"
                                        :visibility="'focus'"
                                        v-bind:disabled="isFormDisabled ? true : null"
                                        @focus="selectAllValue"
                                    />
                                </template>
                            </DatePicker>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!isInLegalMode">
                <div class="field-block-content">
                    <div class="field">
                        <div>Серия</div>
                        <div>
                            <input
                                v-on:keyup.enter="search"
                                v-bind:disabled="isFormDisabled ? true : null"
                                v-model="query.passportSerial"
                                type="text"
                                placeholder="Серия паспорта"
                                @focus="selectAllValue"
                            />
                        </div>
                    </div>
                    <div class="field">
                        <div>Номер</div>
                        <div>
                            <input
                                v-on:keyup.enter="search"
                                v-bind:disabled="isFormDisabled ? true : null"
                                v-model="query.passportNumber"
                                type="text"
                                placeholder="Номер паспорта"
                            />
                        </div>
                    </div>
                    <div class="field">
                        <div>Выдан</div>
                        <div>
                            <DatePicker
                                v-model="query.passportIssue"
                                :locale="'ru-RU'"
                                :masks="masks"
                                :popover="PopoverOptions"
                            >
                                <template v-slot="{ inputValue, inputEvents }">
                                    <input
                                        :value="inputValue"
                                        v-on="inputEvents"
                                        placeholder="дд.мм.гггг"
                                        :visibility="'focus'"
                                        v-bind:disabled="isFormDisabled ? true : null"
                                        @focus="selectAllValue"
                                    />
                                </template>
                            </DatePicker>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="field-block-content">
                    <div class="field">
                        <div>Номер телефона</div>
                        <div>
                            <input
                                v-on:keyup.enter="search"
                                v-bind:disabled="isFormDisabled ? true : null"
                                v-model="query.phone"
                                type="text"
                                placeholder="Номер мобильного телефона"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="field-block-content">
                    <div class="field">
                        <div>E-Mail</div>
                        <div>
                            <input
                                v-on:keyup.enter="search"
                                v-bind:disabled="isFormDisabled ? true : null"
                                v-model="query.email"
                                type="text"
                                placeholder="Адрес электронной почты"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="search-form-footer">
            <div>
                <btn v-bind:disabled="isFormDisabled ? true : null" v-on:clicked="search" v-bind:text="'Поиск'"></btn>
                <div v-if="isFormDisabled === false" v-file-choose="onDocumentChanged" class="link">
                    Распознать с документа
                </div>
                <div v-if="isInDocumentRecognition" class="document-recognition-message">
                    <span class="fas fa-sync-alt fa-spin"></span>
                    <span>Идёт распознавание документа</span>
                </div>
            </div>
            <span v-if="isFormDisabled === false" v-on:click="clear" class="link">Начать новый поиск</span>
        </div>
        <div class="result-flag-groups">
            <div
                v-bind:active="source === segment ? true : null"
                v-on:click="changeSourceFilter(segment)"
                v-for="segment in segments"
                class="flag-group"
            >
                <div>{{ segment.segmentName }}</div>
                <div v-if="segment.isLoading === true">
                    <div class="fas fa-sync-alt fa-spin"></div>
                </div>
                <div v-if="segment.isLoading === false && segment.isFailed !== true">{{ segment.total }}</div>
                <div v-if="segment.isLoading === false && segment.isFailed === true">
                    <i class="fa-solid fa-circle-exclamation"></i>
                </div>
                <div v-if="segment.isLoading === false && segment.hasAlert === true">
                    <i class="fa-solid fa-bolt"></i>
                </div>
            </div>
        </div>
        <div v-if="source !== null" class="segment">
            <div v-if="source.isFailed === true" class="failure">
                <div v-if="source.isGeneralError === true" class="fas fa-exclamation-triangle"></div>
                <div v-if="source.isGeneralError === true" class="text">При поиске произошла ошибка</div>
                <div v-if="source.isBillingError === true" class="fas fa-ruble-sign"></div>
                <div v-if="source.isBillingError === true" class="text">Недостаточно средств на счёте</div>
            </div>
            <div v-if="source.isFailed !== true">
                <div v-if="source.segmentCode === 'cobalt'" class="cobalt-result">
                    <div v-if="source.records !== null" class="header">
                        <div>
                            Совпадений: <b>{{ source.total ? source.total : 0 }}</b>
                        </div>
                        <div class="sort-options">
                            <select v-on:change="replay(source)" v-model="source.isDesc">
                                <option v-bind:value="true">По убыванию</option>
                                <option v-bind:value="false">По возрастанию</option>
                            </select>
                            <select v-on:change="replay(source)" v-model="source.sort">
                                <option v-bind:value="k" v-for="(v, k) in sortMethods">{{ v }}</option>
                            </select>
                        </div>
                    </div>
                    <div
                        v-if="source.isLoading === false && source.isPassportCompromised !== null"
                        class="passport-check-result"
                    >
                        <div v-if="source.isPassportCompromised === false" class="not-compromised">
                            <div class="far fa-check-circle"></div>
                            <div>Паспорт в списках недействительных не значится</div>
                        </div>
                        <div v-if="source.isPassportCompromised === true" class="compromised">
                            <div class="fas fa-exclamation-triangle"></div>
                            <div>Паспорт значится в списках недействительных</div>
                        </div>
                    </div>
                    <div
                        v-if="source.isLoading === false && source.records !== null && source.records.length === 0"
                        class="empty"
                    >
                        <div>Ничего не найдено</div>
                    </div>
                    <div
                        v-if="source.records !== null && source.records.length > 0 && source.isFailed === false"
                        class="result"
                    >
                        <feed-item v-bind:item="record" v-for="record in source.records"></feed-item>
                    </div>
                    <div class="footer">
                        <btn
                            v-if="source.session !== null && source.isLoading !== true"
                            v-on:clicked="scroll(source)"
                            v-bind:text="'Загрузить ещё'"
                        ></btn>
                        <spinner v-if="source.isLoading === true"></spinner>
                    </div>
                </div>
                <div v-if="source.segmentCode === 'wanted'" class="wanted">
                    <div v-if="source.isLoading === false && source.result.length === 0" class="empty">
                        <div>Ничего не найдено</div>
                    </div>
                    <div v-if="source.isLoading === false && source.result.length > 0" class="wanted-list">
                        <div v-for="record in source.result" class="wanted-record">
                            <div>
                                <div>Имя</div>
                                <div>{{ record.name }}</div>
                            </div>
                            <div>
                                <div>Регион</div>
                                <div>{{ record.region }}</div>
                            </div>
                            <div>
                                <div>Дата и время</div>
                                <div>
                                    <date v-bind:value="record.dateTime"></date>
                                </div>
                            </div>
                            <div>
                                <div>Подробности</div>
                                <div>{{ record.message }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="source.segmentCode === 'phone_book'" class="zen">
                    <div v-if="source.isLoading === false && source.result === null" class="empty">
                        <div>Ничего не найдено</div>
                    </div>
                    <div v-if="source.result != null && source.result.length > 0" class="phone-book">
                        <span>Имена в телефонных книгах (GetContact, TrueCaller, прочие)</span>
                        <span v-for="name in source.result">{{ name }}</span>
                    </div>
                </div>
                <div v-if="source.segmentCode === 'zen'" class="zen">
                    <div v-if="source.isLoading === false && source.infoPersonBatch.length === 0" class="empty">
                        <div>Ничего не найдено</div>
                    </div>
                    <div v-if="source.infoPersonBatch.length > 0" class="info-persons">
                        <div v-for="group in source.infoPersonBatch" class="info-person-group">
                            <div class="info-person-group-name">{{ group.name }}</div>
                            <div>
                                <div class="info-person">
                                    <div>Фамилия</div>
                                    <div>Имя</div>
                                    <div>Отчество</div>
                                    <div>Д/Р</div>
                                    <div>Телефон</div>
                                    <div>E-Mail</div>
                                </div>
                                <div v-for="info in group.entries" class="info-person">
                                    <div>{{ info.lastname || "—" }}</div>
                                    <div>{{ info.firstname || "—" }}</div>
                                    <div>{{ info.patronymic || "—" }}</div>
                                    <div>{{ buildBirthday(info) }}</div>
                                    <div>
                                        <span v-for="phone in info.phones">{{ phone }}</span>
                                        <span v-if="!info.phones || info.phones.length === 0">&mdash;</span>
                                    </div>
                                    <div>
                                        <span v-for="email in info.emails">{{ email }}</span>
                                        <span v-if="!info.emails || info.emails.length === 0">&mdash;</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <spinner v-if="source.isLoading === true"></spinner>
                </div>
            </div>
        </div>
    </div>
    <toast-notification ref="toast"></toast-notification>
</template>

<script lang="ts">
import { reactive } from "vue";
import { core } from "../root";
import { FeedRecord, InfoPerson, RecordFields, TextSearchQuery, WantedRecord } from "../core";

import SpinnerComponent from "./Spinner.vue";
import FeedItemComponent from "./request/FeedItem.vue";
import HintComponent from "./Hint.vue";
import ButtonComponent from "./Button.vue";
import DateComponent from "./Date.vue";
import FilePasteDirective from "../vueDirectives/FilePaste";
import FileChooseDirective from "../vueDirectives/FileChoose";
import ToastNotificationComponent from "./ToastNotification.vue";

import { DatePicker, Calendar } from "v-calendar";

async function runPhoneSearchSafely(query: TextSearchQuery) {
    if (!query.phone) {
        return null;
    }
    try {
        return await core.api.searchPersonInfoByPhone(query.phone);
    } catch (e) {
        console.log(e);
    }
    return null;
}
async function runEmailSearchSafely(query: TextSearchQuery) {
    if (!query.email) {
        return null;
    }
    try {
        return await core.api.searchPersonInfoByEmail(query.email);
    } catch (e) {
        console.log(e);
    }
    return null;
}
async function runNameSearchSafely(query: TextSearchQuery) {
    if (!query.firstname || !query.lastname) {
        return null;
    }
    try {
        return await core.api.searchPersonInfoByName(query);
    } catch (e) {
        console.log(e);
    }
    return null;
}
async function runPhoneBookSearchSafely(query: TextSearchQuery) {
    if (!query.phone) {
        return null;
    }
    try {
        return await core.api.searchPhoneBook(query.phone);
    } catch (e) {
        console.log(e);
    }
    return null;
}
abstract class SearchSegment {
    public isFailed: boolean = false;
    public isBillingError: boolean = false;
    public isGeneralError: boolean = false;
    public isLoading: boolean = true;
    public total: Number | null = null;
    public hasAlert: boolean = false;
    public readonly segmentCode: string;
    public readonly segmentName: string;
    constructor(code: string, name: string) {
        this.segmentCode = code;
        this.segmentName = name;
    }
}
class RecordSearchSegment extends SearchSegment {
    public isPassportCompromised: boolean | null = null;
    public searchId: string | null = null;
    public session: string | null = null;
    public records: Array<FeedRecord> | null = null;
    public isDesc: boolean = true;
    public sort: string = "score";
    constructor() {
        super("cobalt", "База Stop-Fraud");
    }
}
class WantedSearchSegment extends SearchSegment {
    public result: Array<WantedRecord> | null = null;
    constructor() {
        super("wanted", "Розыск МВД");
    }
}
class PhoneBookSearchSegment extends SearchSegment {
    public result: Array<string> | null = null;
    constructor() {
        super("phone_book", "Телефонные книги");
    }
}
class ZenSearchSegment extends SearchSegment {
    public personByPhone: any | null = null;
    public personByEmail: any | null = null;
    public personByName: any | null = null;
    public phoneBook: any | null = null;
    public infoPersonBatch: Array<any> = new Array<any>();
    constructor() {
        super("zen", "База персон");
    }
}
export default {
    props: {
        queryString: {
            type: String,
            required: false
        },
        queryType: {
            type: String,
            required: false
        }
    },
    data: function() {
        return {
            documentRecognitionError: null,
            isInDocumentRecognition: false,
            source: null,
            segments: [],
            query: new TextSearchQuery(),
            masks: {
                input: "DD.MM.YYYY"
            },
            PopoverOptions: {
                visibility: "focus",
                placement: "bottom"
            }
        };
    },
    created() {
        let queryString = this.queryString as string;
        if (!queryString) {
            return;
        }
        let queryType = this.queryType as string;
        if (!queryType) {
            return;
        }
        let query = this.query as TextSearchQuery;
        if (queryType === "phone") {
            query.phone = queryString;
        } else if (queryType === "email") {
            query.email = queryString;
        } else {
            return;
        }
        return this.search();
    },
    computed: {
        isFormDisabled() {
            if (this.isInProcess === true) {
                return true;
            }
            if (this.isInDocumentRecognition === true) {
                return true;
            }
            return false;
        },
        sortMethods() {
            return {
                score: "Релевантность",
                recordDate: "Дата внесения",
                eventDate: "Дата анкеты"
            };
        },
        isInLegalMode() {
            return core.isInLegalMode;
        },
        isInProcess() {
            for (let segment of this.segments) {
                if (segment.isLoading === true) {
                    return true;
                }
            }
            return false;
        }
    },
    methods: {
        setBirthday(date) {
            this.query.setBirthday(date);
        },
        setPassportIssue(date) {
            this.query.setPassportIssue(date);
        },
        changeSourceFilter(source: string) {
            this.source = source;
        },
        clearResult() {
            this.source = null;
            this.segments = [];
        },
        buildBirthday(person: InfoPerson): string {
            if (!person.birthdayYear && !person.birthdayMonth && !person.birthdayDay) {
                return "—";
            }
            function getPadded(i: number, c: number): string {
                if (!i) {
                    return "".padStart(c, "?");
                }
                let str = i.toString();
                return str.padStart(c, "0");
            }
            return [
                getPadded(person.birthdayDay, 2),
                getPadded(person.birthdayMonth, 2),
                getPadded(person.birthdayYear, 4)
            ].join(".");
        },
        clear() {
            this.query = new TextSearchQuery();
            this.clearResult();
        },
        async onDocumentChanged(file) {
            if (file instanceof Array) {
                if (file.length === 0) {
                    return;
                }
                file = file[0];
            }
            if (this.isFormDisabled) {
                return;
            }
            if (this.isInDocumentRecognition) {
                return;
            }
            this.isInDocumentRecognition = true;
            try {
                let fileId = await core.api.uploadFile(file);
                let documents = await core.api.recognizeDocument(fileId);
                if (documents.length == 0) {
                    this.documentRecognitionError = "no_documents";
                }
                for (var i = 0; i < documents.length; i++) {
                    let document = documents[i];
                    let query = this.query as TextSearchQuery;
                    if (document.type === "RUSSIAN_PASSPORT") {
                        query.firstname = document.fields["first_name"];
                        query.lastname = document.fields["last_name"];
                        query.patronymic = document.fields["middle_name"];
                        let birthdaySplit = document.fields["birth_date"].split(".");
                        query.birthdayDay = parseInt(birthdaySplit[0]);
                        query.birthdayMonth = parseInt(birthdaySplit[1]);
                        query.birthdayYear = parseInt(birthdaySplit[2]);
                        query.passportSerial = document.fields["serial_number"].substring(0, 4);
                        query.passportNumber = document.fields["serial_number"].substring(4);
                        let issueSplit = document.fields["issue_date"].split(".");
                        query.passportIssueDay = parseInt(issueSplit[0]);
                        query.passportIssueMonth = parseInt(issueSplit[1]);
                        query.passportIssueYear = parseInt(issueSplit[2]);
                    } else if (document.type === "DRIVER_LICENSE_FRONT") {
                        query.lastname = document.fields["last_name"];
                        let nameMatch = document.fields["first_middle_name"].match(/^([^\s]+)(\s+)?(.*)?/i);
                        query.firstname = nameMatch[1];
                        if (nameMatch.length > 2) {
                            query.patronymic = nameMatch[3];
                        }
                        let birthdaySplit = document.fields["birth_date"].split(".");
                        query.birthdayDay = parseInt(birthdaySplit[0]);
                        query.birthdayMonth = parseInt(birthdaySplit[1]);
                        query.birthdayYear = parseInt(birthdaySplit[2]);
                    }
                }
            } catch(error) {
                this.isDocumentRecognitionError = "error";
                this.query = new TextSearchQuery();
                if (error.status = 402) {
                    this.$refs.toast.addToast("Лимит распознаваний документа исчерпан", "error");
                }
            } finally {
                this.isInDocumentRecognition = false;
            }
        },
        _onSearchSegmentSuccessfullyCompleted(segment: SearchSegment): void {
            if (this.source !== null) {
                return;
            }
            if (segment.total !== null && (segment.total > 0 || segment.hasAlert)) {
                this.source = segment;
            }
        },
        async _executeSegment(segment: SearchSegment, func: Function) {
            try {
                await func();
                this._onSearchSegmentSuccessfullyCompleted(segment);
            } catch (error) {
                if (error.status === 402) {
                    segment.isBillingError = true;
                    this.$refs.toast.addToast("Пополните баланс", "error");
                } else {
                    segment.isGeneralError = true;
                }
                segment.isFailed = true;
            } finally {
                segment.isLoading = false;
            }
        },
        async _executePhoneBookSearch(query: TextSearchQuery) {
            if (!query.phone) {
                return;
            }
            let segment = reactive(new PhoneBookSearchSegment());
            this.segments.push(segment);
            await this._executeSegment(segment, async () => {
                let result = await runPhoneBookSearchSafely(query);
                if (result !== null) {
                    segment.result = result;
                    segment.total = result.length;
                }
            });
        },
        async _executeWantedSearch(query: TextSearchQuery) {
            if (!query.HasFirstnameAndLastname || !query.hasBirthday) {
                return;
            }
            let segment = reactive(new WantedSearchSegment());
            this.segments.push(segment);
            await this._executeSegment(segment, async () => {
                let fields = new RecordFields();
                fields.firstname = query.firstname;
                fields.lastname = query.lastname;
                fields.patronymic = query.patronymic;
                fields.birthdayYear = query.birthdayYear;
                fields.birthdayMonth = query.birthdayMonth;
                fields.birthdayDay = query.birthdayDay;
                let result = await core.api.verifyWanted(fields);
                segment.result = result;
                segment.total = result.length;
            });
        },
        async _executeCobaltSearch(query: TextSearchQuery) {
            let segment = reactive(new RecordSearchSegment());
            this.segments.push(segment);
            await this._executeSegment(segment, async () => {
                let result = await core.api.searchByText(query);
                segment.searchId = result.searchId;
                segment.session = result.session;
                segment.records = result.records;
                segment.total = result.total;
                if (query.hasPassport) {
                    segment.isPassportCompromised = await core.api.getIsPassportCompromised(
                        query.passportSerial,
                        query.passportNumber
                    );
                    if (segment.isPassportCompromised === true) {
                        segment.hasAlert = true;
                    }
                }
            });
        },
        async _executePersonInfoSearch(query: TextSearchQuery) {
            let segment = reactive(new ZenSearchSegment());
            this.segments.push(segment);
            await this._executeSegment(segment, async () => {
                let promiseResult = await Promise.all([
                    runPhoneSearchSafely(query),
                    runEmailSearchSafely(query),
                    runNameSearchSafely(query)
                ]);
                let total = 0;
                if (promiseResult[0] !== null && promiseResult[0].length > 0) {
                    segment.infoPersonBatch.push({ name: "Поиск по номеру телефона", entries: promiseResult[0] });
                    total = total + promiseResult[0].length;
                }
                if (promiseResult[1] !== null && promiseResult[1].length > 0) {
                    segment.infoPersonBatch.push({
                        name: "Поиск по адресу электронной почты",
                        entries: promiseResult[1]
                    });
                    total = total + promiseResult[1].length;
                }
                if (promiseResult[2] !== null && promiseResult[2].length > 0) {
                    segment.infoPersonBatch.push({ name: "Поиск по Ф.И.О. + д/р", entries: promiseResult[2] });
                    total = total + promiseResult[2].length;
                }
                segment.total = total;
            });
        },
        async search() {
            this.clearResult();
            let query = this.query as TextSearchQuery;
            await Promise.all([
                this._executeCobaltSearch(query),
                this._executePersonInfoSearch(query),
                this._executeWantedSearch(query),
                this._executePhoneBookSearch(query)
            ]);
        },
        async scroll(segment: RecordSearchSegment) {
            if (segment.isLoading || !segment.session) {
                return;
            }
            segment.isLoading = true;
            try {
                let batch = await core.api.searchScroll(segment.searchId, segment.session);
                for (let i = 0; i < batch.records.length; i++) {
                    segment.records.push(batch.records[i]);
                }
                segment.session = batch.session;
            } catch {
                segment.isFailed = true;
            } finally {
                segment.isLoading = false;
            }
        },
        async replay(segment: RecordSearchSegment) {
            segment.isLoading = true;
            segment.records = null;
            segment.session = null;
            try {
                let result = await core.api.replaySearch(segment.searchId, segment.sort, segment.isDesc);
                segment.session = result.session;
                segment.records = result.records;
                segment.total = result.total;
            } catch {
                segment.isFailed = true;
            } finally {
                segment.isLoading = false;
            }
        },
        selectAllValue(event) {
            event.target.select();
        },
    },
    components: {
        spinner: SpinnerComponent,
        feedItem: FeedItemComponent,
        btn: ButtonComponent,
        date: DateComponent,
        hint: HintComponent,
        toastNotification: ToastNotificationComponent,
        Calendar,
        DatePicker
    },
    directives: {
        filePaste: FilePasteDirective,
        fileChoose: FileChooseDirective
    }
};
</script>

<style lang="scss" scoped>
.root {
    display: flex;
    flex-direction: column;
    > * {
        margin-bottom: 20px;
    }
    .dropbox {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        border: 1px dashed #cccccc;
        padding: 30px;
        align-items: center;
        justify-content: center;
        color: #666;
        text-transform: uppercase;
        background: #ffffff;
    }
}
.document-recognition-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    > *:not(:last-child) {
        margin-right: 10px;
    }
}
.phone-book {
    display: inline-block;
    > *:first-child {
        margin-right: 10px;
        font-weight: bold;
        &::after {
            content: ":";
        }
    }
    > *:not(:first-child):not(:last-child) {
        margin-right: 7px;
        &::after {
            content: ",";
        }
    }
}
.zen {
    display: flex;
    flex-direction: column;
    > *:not(:last-child) {
        margin-bottom: 10px;
    }
}
.link {
    color: #0070e0;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
        text-decoration: none;
        color: #3eb8ff;
    }
}
.cobalt-result {
    display: flex;
    flex-direction: column;
    > *:not(:last-child) {
        margin-bottom: 20px;
    }
}
.info-person-group {
    &:not(:last-child) {
        margin-bottom: 40px;
    }
}
.info-person-group-name {
    padding-bottom: 20px;
    text-transform: uppercase;
}
.info-person {
    display: flex;
    flex-direction: row;
    $_border-color: #ededed;
    > * {
        &:not(:last-child) {
            border-right: 1px solid $_border-color;
        }
        padding: 5px 5px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
    }
    &:first-child {
        font-weight: bold;
        border-bottom-color: #ccc !important;
    }
    &:not(:last-child) {
        border-bottom: 1px solid $_border-color;
    }
    > *:nth-child(1) {
        width: 18%;
    }
    > *:nth-child(2) {
        width: 18%;
    }
    > *:nth-child(3) {
        width: 16%;
    }
    > *:nth-child(4) {
        width: 15%;
    }
    > *:nth-child(5) {
        width: 13%;
    }
    > *:nth-child(6) {
        width: 20%;
    }
}
.request {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: #ffffff;
    padding: 20px 0;
    > div:nth-child(2n + 1) {
        .field-block {
            padding-right: 5px;
        }
    }
    > div:nth-child(2n) {
        .field-block {
            padding-left: 5px;
        }
    }
    > div {
        width: 50%;
    }
    .field {
        display: flex;
        flex-direction: row;
        width: 100%;
        > div {
            padding: 10px 20px;
        }
        input {
            border: none;
            border-bottom: 1px solid #cccccc;
            width: 100%;
            padding: 5px;
            &:focus {
                outline: none;
            }
        }
        > *:nth-child(1) {
            width: 35%;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        > *:nth-child(2) {
            width: 65%;
        }
    }
}
.wanted {
    display: flex;
    flex-direction: column;
    > *:not(:last-child) {
        margin-bottom: 20px;
    }
    .wanted-list {
        display: flex;
        flex-direction: column;
    }
    .wanted-record {
        display: flex;
        flex-direction: column;
        > * {
            display: inline-block;
            > * {
                display: inline;
            }
            > *:first-child {
                font-weight: bold;
                margin-right: 10px;
                &::after {
                    content: ":";
                }
            }
        }
    }
}
.passport-check-result {
    display: flex;
    flex-direction: column;
    .not-compromised {
        color: #00a700;
    }
    .compromised {
        color: #e30e0e;
    }
    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        padding: 20px 0;
        border: 1px solid #ededed;
        > * {
            display: flex;
            flex-direction: row;
            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }
}
.search-form-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > * {
        display: flex;
        flex-direction: row;
        align-items: center;
        > *:not(:last-child) {
            margin-right: 20px;
        }
    }
}
.result {
    display: flex;
    flex-direction: column;
    > *:not(:last-child) {
        margin-bottom: 20px;
    }
}
.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    min-height: 40px;
}
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    b {
        font-weight: bold;
    }
    > div.sort-options {
        display: flex;
        flex-direction: row;
        > *:not(:last-child) {
            margin-right: 10px;
        }
    }
}
.result-flag-groups {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .flag-group {
        display: flex;
        flex-direction: row;
        flex-shrink: 0;
        padding: 5px 10px;
        border-radius: 2px;
        margin-bottom: 5px;
        line-height: 13px;
        &:not(:last-child) {
            margin-right: 5px;
        }
        > *:not(:last-child) {
            margin-right: 10px;
        }
        > *:last-child {
            padding-left: 10px;
            min-width: 20px;
            text-align: center;
        }
        &:not([active]) {
            &:not(:hover) {
                border: 1px solid #ededed;
            }
            &:hover {
                border: 1px solid #2288dd;
            }
            color: #666;
            cursor: pointer;
            > *:last-child {
                border-left: 1px solid #ededed;
            }
        }
        &[active] {
            background: #2288dd;
            color: #ffffff;
            border: 1px solid #2288dd;
            > *:last-child {
                border-left: 1px solid #ffffff;
            }
        }
    }
}
.empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    font-size: 15px;
}
.failure {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    > *:not(:last-child) {
        margin-bottom: 50px;
    }
    .fas {
        font-size: 50px;
        &.fa-exclamation-triangle {
            color: #f96565;
        }
        &.fa-ruble-sign {
            color: #cecdcd;
        }
    }
    .text {
        font-size: 20px;
    }
}
</style>
