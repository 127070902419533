<template lang="html">
<div class="root">
    <spinner v-if="isCompleted === false"></spinner>
    <div v-if="isCompleted === true" class="success">
        <div class="far fa-check-circle"></div>
        <div v-if="status === 2" class="text">Запись поступила в обработку</div>
        <div v-if="status === 4" class="text">Запись успешно обработана</div>
        <div v-if="status === 2" class="help">До завершения обработки запись будет недоступна в поиске</div>
    </div>
</div>
</template>

<script lang="ts">
import {core} from '../../root'
import {RequestContext} from '../../core'
import Spinner from './../Spinner.vue'

export default {
    props: {
        context: {
            type: RequestContext,
            required: true
        }
    },
    data: function () {
        return {
            isCompleted: false,
            status: null
        };
    },
    created: function () {
        const requestId = this.context.record.recordId;
        new Promise((resolve, reeject) => {
            function checkStatus() {
                core.api.getRecordStatus(requestId).then(_ => {
                    if (_ === 4 || _ === 2) {
                        resolve(_);
                    } else {
                        setTimeout(() => checkStatus(), 1000);
                    }
                }, error => {
                    reeject(error);
                });
            }
            checkStatus();
        }).then(status => {
            this.status = status;
            this.isCompleted = true;
        }, error => {
            core.raiseError(error, 'Ошибка проверки статуса');
            this.context.isInFailure = true;
        });
    },
    components: {
        spinner: Spinner
    }
}
</script>

<style lang="scss" scoped>
.success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;;
    padding: 50px 0;
    > *:not(:last-child) {
        margin-bottom: 50px;
    }
    .far {
        font-size: 50px;
        color: green;
    }
    .text {
        font-size: 20px;
    }
    .help {
        font-size: 15px;
    }
}
</style>