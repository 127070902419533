<template lang="html">
<div class="record">
    <div v-if="personFeed !== null">
        <feed-item v-bind:item="item" v-for="item in personFeed.records"></feed-item>
    </div>
    <div v-if="feed !== null" class="separator">
        <div>Инциденты с участием данной персоны ({{feed.records.length}}):</div>
        <div></div>
    </div>
    <div v-if="feed !== null" class="container">
        <feed-item v-bind:item="item" v-for="item in feed.records"></feed-item>
    </div>
    <spinner v-if="loading"></spinner>
</div>
</template>

<script lang="ts">
import {core} from '../../root';

import SpinnerComponeent from '../Spinner.vue';
import FeedItemComponent from './FeedItem.vue';

export default {
    data: function () {
        return {
            feed: null,
            personFeed: null
        }
    },
    props: {
        id: {
            type: String,
            required: true
        },
        searchId: {
            type: String,
            required: false
        }
    },
    created: function () {
        return this.load(this.id);
    },
    watch: {
        'id': function () {
            this.load();
        }
    },
    methods: {
        async load() {
            this.loading = true;
            try {
                this.personFeed = await core.createPersonFeedFromPersonId(this.id, this.searchId);
                this.feed = await core.api.getPersonRecords(this.id, this.searchId);
            } finally {
                this.loading = false;
            }
        }
    },
    components: {
        feedItem: FeedItemComponent,
        spinner: SpinnerComponeent
    }
}
</script>

<style lang="scss" scoped>
.separator {
    $_height: 40px;
    display: flex;
    flex-direction: row;
    line-height: $_height;
    height: $_height;
    font-size: 14px;
    margin: 10px 0;
    > div {
        display: flex;
        flex-direction: row;
        flex-shrink: 0;
    }
    > div:nth-child(1) {
        padding-right: 20px;
        height: $_height;
    }
    > div:nth-child(2) {
        border-bottom: 1px solid #cccccc;
        flex-grow: 1;
        height: calc($_height / 2);
    }
}
.container {
    display: flex;
    flex-direction: column;
    > *:not(:last-child) {
        margin-bottom: 20px;
    }
}
</style>