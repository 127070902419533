<template lang="html">
<div class="person-feed-record">
    <person-hit
        v-bind:person="resolvedPerson"
        v-bind:context="record.personContext"
        v-bind:binding-reasons="bindingReasons"
    ></person-hit>
</div>
</template>

<script lang="ts">
import {PersonContext, PersonFeedRecord} from '../../core'
import PersonHitComponent from './PersonHit.vue';

export default {
    props: {
        record: {
            type: PersonFeedRecord,
            required: true
        },
        context: {
            type: PersonContext,
            required: true
        }
    },
    computed: {
        resolvedPerson() {
            let item = this.record as PersonFeedRecord;
            let person = item.personContext.persons.get(item.personId);
            return person;
        },
        bindingReasons() {
            let record = this.record as PersonFeedRecord;
            let has = record.personContext.bindingReasons.has(record.personId);
            if (has !== true) {
                return null;
            }
            return record.personContext.bindingReasons.get(record.personId);
        }
    },
    components: {
        personHit: PersonHitComponent
    }
}
</script>

<style lang="scss" scoped>
.person-feed-record {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    background: #ffffff;
    color: #27343b;
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 10px;
    > *:not(:last-child) {
        margin-bottom: 10px;
    }
}
</style>