<template lang="html">
<div>
    <div v-on:click="openFableTextArea" v-if="!additionalFable" class="add-fable">
        <div>Добавить дополнительное описание</div>
    </div>
    <div v-show="additionalFable" class="block">
        <div>
            <div class="block-content">
                <textarea v-bind:disabled="(canEdit !== true) ? true : null" v-model="context.person.fable" v-on:blur="onLeavingFable" ref="fableTextArea" type="text" rows="5" placeholder="Дополнительная информация"></textarea>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import {core} from '../../root'
import {RecordPersonContext} from '../../core'

export default {
    props: {
        context: {
            type: RecordPersonContext,
            required: true
        }
    },
    data() {
        return {
            additionalFable: !!this.context.person.fable
        };
    },
    computed: {
        canEdit() {
            let context = this.context as RecordPersonContext;
            return core.getCanEditPerson(context.person);
        }
    },
    methods: {
        onLeavingFable() {
            if (!this.context.person.fable) {
                this.additionalFable = false;
            }
        },
        openFableTextArea() {
            this.additionalFable = true;
            setTimeout(() => {
                this.$refs.fableTextArea.focus();
            }, 10);
        }
    }
}
</script>

<style lang="scss" scoped>
.root {
    display: flex;
    flex-direction: column;
}
input[type=text] {
    width: 100%;
    border: none;
    border-bottom: 1px solid #cccccc;
    padding: 10px;
    &:focus {
        outline: none;
    }
}
.add-fable {
    border: 1px dashed #cccccc;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-bottom: 10px;
    color: #aaa;
}
.block {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: #ffffff;
    margin-bottom: 10px;
    .block-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        span {
            margin-top: -10px;
            line-height: 20px;
            height: 20px;
            background: #ffffff;
            padding: 0 10px;
            display: flex;
            flex-direction: row;
            border: 1px solid #cccccc;
            margin-left: 10px;
            border-radius: 3px;
            color: #797979;
        }
    }
    .block-content {
        &.flags {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 0 10px;
            > * {
                width: 25%;
            }
        }
    }
}
.checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
     user-select: none;
    > *:not(:last-child) {
        margin-right: 10px;
    }
}
textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    resize: none;
    &:focus {
        outline: none;
    }
}
</style>