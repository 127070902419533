<template lang="html">
    <div class="feed-date-filter">
        <div v-if="mode === 'dateTime'" class="form">
            <div>
                <div>С</div>
                <div>
                    <DatePicker v-model="temp.fromDateTime" :locale="'ru-RU'" :masks="masks" :popover="PopoverOptions">
                        <template v-slot="{ inputValue, inputEvents }">
                            <input
                                :value="inputValue"
                                v-on="inputEvents"
                                placeholder="дд.мм.гггг"
                                :visibility="'focus'"
                                v-bind:disabled="isFormDisabled ? true : null"
                                @focus="selectAllValue"
                            />
                        </template>
                    </DatePicker>
                </div>
            </div>
            <div>
                <div>До</div>
                <div>
                    <DatePicker v-model="temp.tillDateTime" :locale="'ru-RU'" :masks="masks" :popover="PopoverOptions">
                        <template v-slot="{ inputValue, inputEvents }">
                            <input
                                :value="inputValue"
                                v-on="inputEvents"
                                placeholder="дд.мм.гггг"
                                :visibility="'focus'"
                                @focus="selectAllValue"
                            />
                        </template>
                    </DatePicker>
                </div>
            </div>
        </div>
        <div v-if="mode === 'timestamp'" class="form">
            <div>
                <div>С</div>
                <div>
                    <DatePicker v-model="temp.fromTimestamp" :locale="'ru-RU'" :masks="masks" :popover="PopoverOptions">
                        <template v-slot="{ inputValue, inputEvents }">
                            <input
                                :value="inputValue"
                                v-on="inputEvents"
                                placeholder="дд.мм.гггг"
                                :visibility="'focus'"
                                @focus="selectAllValue"
                            />
                        </template>
                    </DatePicker>
                </div>
            </div>
            <div>
                <div>До</div>
                <div>
                    <DatePicker v-model="temp.tillTimestamp" :locale="'ru-RU'" :masks="masks" :popover="PopoverOptions">
                        <template v-slot="{ inputValue, inputEvents }">
                            <input
                                :value="inputValue"
                                v-on="inputEvents"
                                placeholder="дд.мм.гггг"
                                :visibility="'focus'"
                                v-bind:disabled="isFormDisabled ? true : null"
                                @focus="selectAllValue"
                            />
                        </template>
                    </DatePicker>
                </div>
            </div>
        </div>
        <div v-if="!!mode" class="footer">
            <btn v-on:clicked="submit" v-bind:text="'Отфильтровать'"></btn>
        </div>
    </div>
</template>

<script lang="ts">
import ButtonComponent from "../Button.vue";
import { FeedDateFilter } from "../../core";
import { DatePicker, Calendar } from "v-calendar";

export default {
    props: {
        filter: {
            type: FeedDateFilter,
            required: true
        },
        onSubmit: {
            type: Function,
            required: false
        },
        filterType: {
            type: String,
            required: true
        }
    },
    data() {
        let mode = null;
        let temp = new FeedDateFilter();
        let filter = this.filter as FeedDateFilter;
        if (!!filter.fromDateTime || !!filter.tillDateTime) {
            mode = "dateTime";
            temp.fromDateTime = filter.fromDateTime;
            temp.tillDateTime = filter.tillDateTime;
        } else if (!!filter.fromTimestamp || !!filter.tillTimestamp) {
            mode = "timestamp";
            temp.fromTimestamp = filter.fromTimestamp;
            temp.tillTimestamp = filter.tillTimestamp;
        } else {
            mode = null;
        }
        return {
            temp: temp,
            mode: mode || this.filterType,
            masks: {
                input: "DD.MM.YYYY"
            },
            PopoverOptions: {
                visibility: "focus",
                placement: "bottom"
            }
        };
    },
    methods: {
        reset() {
            this.mode = null;
            this.submit();
        },
        submit() {
            let result = this.filter as FeedDateFilter;
            let source = this.temp as FeedDateFilter;
            if (this.mode === "dateTime") {
                result.fromDateTime = source.fromDateTime;
                result.tillDateTime = source.tillDateTime;
                result.fromTimestamp = null;
                result.tillTimestamp = null;
            } else if (this.mode === "timestamp") {
                result.fromTimestamp = source.fromTimestamp;
                result.tillTimestamp = source.tillTimestamp;
                result.fromDateTime = null;
                result.tillDateTime = null;
            } else {
                result.fromDateTime = null;
                result.tillDateTime = null;
                result.fromTimestamp = null;
                result.tillTimestamp = null;
            }
            if (this.onSubmit instanceof Function) {
                this.onSubmit();
            }
        },
        selectAllValue(event) {
            event.target.select();
        }
    },
    components: {
        btn: ButtonComponent,
        Calendar,
        DatePicker
    }
};
</script>

<style lang="scss" scoped>
.feed-date-filter {
    display: flex;
    flex-direction: column;
    > *:not(:last-child) {
        margin-bottom: 10px;
    }
}
.form {
    display: flex;
    flex-direction: row;
    align-self: center;
    padding: 20px 0;
    > * {
        display: flex;
        flex-direction: row;
        align-items: center;
        &:not(:last-child) {
            margin-right: 20px;
        }
        > * {
            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }
}
.option-select {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    > *:not(:last-child) {
        margin-right: 20px;
    }
    > *:nth-child(2) {
        display: flex;
        flex-direction: row;
        border: 1px solid #cccccc;
        border-radius: 3px;
        > div {
            cursor: pointer;
            padding: 5px 15px;
            &[active],
            &:hover {
                background: #ededed;
            }
            &:not(:last-child) {
                border-right: 1px solid #cccccc;
            }
        }
    }
}
.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
</style>
