<template lang="html">
<div class="balance-refill">
    <hint v-bind:text="'Пополнение баланса. Осуществляется платежом с карты. Все поля обязательны для заполнения. Нам понадобится ваш E-Mail и номер телефона, чтобы мы могли отправить на него чек, как того требует ФЗ №54'"></hint>
    <div v-bind:class="{wrong: isWrongPhone}" class="balance-field">
        <div>
            <i class="fas fa-mobile-alt"></i>
        </div>
        <div>
            <input v-bind:disabled="isInLoading ? true : null" v-model="phone" @keypress="isDigit" placeholder="Номер телефона для отправки чека" />
        </div>
    </div>
    <div v-bind:class="{wrong: isWrongEmail}" class="balance-field">
        <div>
            <i class="fas fa-at"></i>
        </div>
        <div>
            <input v-bind:disabled="isInLoading ? true : null" v-model="email" placeholder="Адрес электроной почты для отправки чека" />
        </div>
    </div>
    <div class="balance-field">
        <div>&#8381;</div>
        <div>
            <input v-bind:disabled="isInLoading ? true : null" v-model="amount" @keypress="isDigit" placeholder="Сумма в рублях" />
        </div>
    </div>
    <div v-if="isInFailure === true" class="failure">
        <div class="text">Произошла ошибка</div>
    </div>
    <div v-if="isInFailure === false" class="balance-confirm">
        <btn v-if="isInLoading === false" v-bind:disabled="canPay === false ? true : null" v-on:clicked="pay" v-bind:text="'Перейти к оплате'"></btn>
        <spinner v-if="isInLoading"></spinner>
    </div>
</div>
</template>

<script lang="ts">
import {UserBalanceRefillRequest} from '../core';
import {core} from '../root';
import SpinnerComponent from './Spinner.vue';
import ButtonComponent from './Button.vue';
import HintComponent from './Hint.vue';

export default {
    data() {
        return {
            amount: null,
            isInLoading: false,
            isInFailure: false,
            isWrongEmail: null,
            isWrongPhone: null,
            isWrongData: null,
            phone: null,
            email: null
        }
    },
    computed: {
        canPay() {
            if (this.amount === null) {
                return false;
            }
            if (this.amount.length === 0) {
                return false;
            }
            return true;
        }
    },
    methods: {
        isDigit($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
        },
        async pay() {
            this.isInLoading = true;
            this.isInFailure = false;
            this.isWrongPhone = false;
            this.isWrongEmail = false;
            this.isWrongData = false;
            try {
                const phone = await core.api.parsePhone(this.phone);
                if (!phone) {
                    this.isWrongData = true;
                    this.isWrongPhone = true;
                    return;
                }
                const email = await core.api.parseEmail(this.email);
                if (!email) {
                    this.isWrongData = true;
                    this.isWrongEmail = true;
                    return;
                }
                const request = new UserBalanceRefillRequest();
                request.amount = parseInt(this.amount) * 100;
                request.phone = phone.getString();
                request.email = email.getString();
                const response = await core.api.sendBalanceRefillRequest(request);
                window.location.replace(response.redirectUri);
            } catch {
                this.isInFailure = true;
            } finally {
                this.isInLoading = false;
            }
        }
    },
    components: {
        hint: HintComponent,
        btn: ButtonComponent,
        spinner: SpinnerComponent
    }
}
</script>

<style lang="scss" scoped>
.balance-refill {
    display: flex;
    flex-direction: column;
    > *:not(:last-child) {
        margin-bottom: 20px;
    }
}
.balance-field {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    &:not(.wrong) {
        border: 1px solid #cccccc;
    }
    &.wrong {
        border: 1px solid red
    }
    > div {
        display: flex;
        flex-shrink: 0;
        align-items: center;
    }
    display: flex;
    flex-grow: 1;
    background: #ffffff;
    padding: 5px 20px;
    > div {
        padding-right: 20px;
    }
    > div:nth-child(1) {
        width: 20px;
    }
    > div:nth-child(2) {
        flex-grow: 1;
    }
    input {
        width: 100%;
        font-size: 15px;
        height: 40px;
        border: none;
        border-bottom: 1px solid #ffffff;
        &:focus {
            outline: none;
        }
    }
}
.balance-confirm {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
}
.failure {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
}
</style>