<template lang="html">
    <div class="root">
        <spinner v-if="loading"></spinner>
        <div v-if="loading === false && !!resolvedPerson">
            <div class="save-button-block">
                <div class="record-form-important-flags">
                    <div
                        v-bind:active="personData.person.flags.isRealData === true ? true : null"
                        class="flag-real-data"
                    >
                        <flag
                            v-bind:flags="personData.person.flags"
                            v-bind:name="'isRealData'"
                            v-bind:title="'Подлинные данные'"
                        ></flag>
                    </div>
                    <div
                        v-bind:active="personData.person.flags.isCompromised === true ? true : null"
                        class="flag-real-data"
                    >
                        <flag
                            v-bind:flags="personData.person.flags"
                            v-bind:name="'isCompromised'"
                            v-bind:title="'Скомпрометированные данные'"
                        ></flag>
                    </div>
                </div>
                <button @click="commit" class="save">Сохранить</button>
            </div>
            <change-person-data-form v-bind:context="personData" />
        </div>
    </div>
</template>

<script lang="ts">
import { core } from "../../root";
import RecordFlagComponent from "./RecordFlag.vue";
import SpinnerComponent from "../Spinner.vue";
import PersonBoundComponent from "./PersonBound.vue";
import PersonHitComponent from "./PersonHit.vue";
import PersonMetaComponent from "./PersonMeta.vue";
import PersonMediaComponent from "./PersonMedia.vue";
import PersonWatchComponent from "./PersonWatch.vue";
import { Util } from "../../util";
import {
    LoadPersonPhotoRequest,
    LoadRecordPhotoRequest,
    PersonContext,
    RecordPerson,
    RecordPersonContext,
    RecordPhotoContext,
    RequestContext
} from "../../core";
import ChangePersonDataForm from "./ChangePersonDataForm.vue";

async function loadPersonPhoto(personId: string, id: string): Promise<RecordPhotoContext> {
    let result = new RecordPhotoContext();
    let request = new LoadPersonPhotoRequest(personId, id);
    result.id = id;
    result.content = await core.loadPhotoSafely(request);
    return result;
}
async function loadPersonFile(personId: string, fileId: string): Promise<RecordPhotoContext> {
    const result = new RecordPhotoContext();
    result.id = fileId;
    result.name = await core.api.getFileName(personId, fileId);
    return result;
}
function getHasRole(name: string): boolean {
    return core.auth.user.roles.indexOf(name) !== -1;
}

export default {
    data: function() {
        return {
            person: null,
            record: null,
            loading: false,
            context: null,
            isFaulure: false,
            isNotFound: false,
            isForbidden: false,
            isGeneralError: false,
            personData: new RecordPersonContext()
        };
    },
    props: {
        id: {
            type: String,
            required: true
        },
        searchId: {
            type: String,
            required: false
        },
        section: {
            type: String,
            required: false
        },
        noBound: {
            type: Boolean,
            required: false
        }
    },
    created: function() {
        return this.load(this.id);
    },
    watch: {
        id: function() {
            this.load();
        }
    },
    computed: {
        hasMedia() {
            let person = this.resolvedPerson as RecordPerson;
            if (!person) {
                return false;
            }
            return Util.getHasMedia(person);
        },
        resolvedPerson() {
            let context = this.context as PersonContext;
            if (!context) {
                return null;
            }
            return context.persons.get(this.id);
        },
        saveLink() {
            return "#/person/" + this.id;
        },
        canSetRealDataFlag() {
            const isAdmin = getHasRole("admin");
            if (isAdmin) {
                return true;
            }
            const isEditor = getHasRole("editor");
            if (isEditor) {
                return true;
            }
            const isFlagRealData = getHasRole("flag_real_data");
            if (isFlagRealData) {
                return true;
            }
            return false;
        },
        canEditCurrentPerson(): boolean {
            let context = this.context as RequestContext;
            let current = context.current;
            if (!current) {
                return false;
            }
            return core.getCanEditPerson(current.person);
        }
    },
    methods: {
        async load() {
            let personId = this.id;
            this.loading = true;
            this.isFailure = false;
            this.isNotFound = false;
            this.isForbidden = false;
            this.isGeneralError = false;
            try {
                let context = await core.createPersonContextFromPersonId(personId, this.searchId);
                if (this.id === personId) {
                    this.context = context;
                    this.personData.person = this.resolvedPerson;
                    let loadPhotos = context.persons.get(this.id).photos.map(_ => loadPersonPhoto(personId, _));
                    let loadFiles = context.persons.get(this.id).files.map(_ => loadPersonFile(personId, _));
                    this.personData.photos = await Promise.all(loadPhotos);
                    this.personData.files = await Promise.all(loadFiles);
                }
            } catch (error) {
                if (error.status === 404) {
                    this.isNotFound = true;
                } else if (error.status === 403) {
                    this.isForbidden = true;
                } else {
                    this.isGeneralError = true;
                    console.log(error);
                }
                this.isFailure = true;
            } finally {
                if (this.id === personId) {
                    this.loading = false;
                    this.$emit("load", personId);
                }
            }
        },
        onBindingsLoaded(bindingRootElemenmt) {
            if (this.section !== "bound") {
                return;
            }
            setTimeout(() => {
                window.scrollTo(0, bindingRootElemenmt.offsetTop);
            }, 10);
        },
        async commit() {
            await core.commitPersonData(this.personData).then(() => {
                window.location = this.saveLink;
            });
        }
    },
    components: {
        personBound: PersonBoundComponent,
        personHit: PersonHitComponent,
        personMedia: PersonMediaComponent,
        personWatch: PersonWatchComponent,
        personMeta: PersonMetaComponent,
        spinner: SpinnerComponent,
        changePersonDataForm: ChangePersonDataForm,
        flag: RecordFlagComponent
    }
};
</script>

<style lang="scss" scoped>
.root {
    display: felx;
    flex-direction: column;
    > *:not(:last-child) {
        margin-bottom: 10px;
    }
}
.person {
    display: flex;
    flex-direction: column;
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding: 10px 10px;
    > *:not(:last-child) {
        margin-bottom: 10px;
    }
    .person-separator {
        border-bottom: 1px solid #ededed;
    }
}
.failure {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    > *:not(:last-child) {
        margin-bottom: 50px;
    }
    .fa-ban {
        font-size: 50px;
        color: #ff5151;
    }
    .text {
        font-size: 20px;
    }
    .reason {
        font-size: 15px;
    }
}
.save {
    color: #28d;
    cursor: pointer;
    background: #fff;
    border: 1px solid #28d;
    border-radius: 2px;
    padding: 7px 10px;
    font-size: 12px;

    &:hover {
        color: #fff;
        background: #28d;
    }
}
.save-button-block {
    display: flex;
    justify-content: space-between;
}
.flag-real-data {
    padding: 0 10px;
    border: 1px solid #df6363;
    border-radius: 25px;
    &:not([active]) {
        color: #970000;
    }
    &[active] {
        background: #df6363;
        color: #fff;
    }
}
.record-form-important-flags {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
</style>
