<template lang="html">
<div class="notifications">
    <hint v-bind:text="'Лента уведомлений. Здесь будут отображаться новости от администрации проекта и уведомления по списку мониторинга'"></hint>
    <div v-if="records != null && records.length !== 0" v-infinity-scroll="{scroll: scroll}">
        <div v-for="item in records" class="notification-feed-content">
            <feed-item v-bind:item="item"></feed-item>
        </div>
    </div>
    <div v-if="records != null && records.length > 0" class="notification-feed-footer">
        <button v-if="session !== null" v-bind:disabled="loading ? true : null" v-on:click="scroll">Загрузить ещё</button>
    </div>
    <spinner v-if="loading === true"></spinner>
</div>
</template>

<script lang="ts">
import {core} from '../root';
import {FeedRecord} from '../core';
import HintComponent from './Hint.vue';
import FeedItemComponent from './request/FeedItem.vue';
import InfinityScrollDirective from '../vueDirectives/InfinityScroll';
import Spinner from './Spinner.vue';

export default {
    data() {
        return new class {
            public records: FeedRecord[] = null;
            public loading: boolean = false;
            public session: string = null;
        }();
    },
    async created() {
        await this.load();
    },
    methods: {
        async load() {
            if (this.loading === true) {
                return;
            }
            this.loading = true;
            try {
                let feed = await core.api.getNotificationFeed();
                core.hasAnyNotification = false;
                this.records = feed.records;
                this.session = feed.session;
            } finally {
                this.loading = false;
            }
        },
        async scroll() {
            if (this.loading === true) {
                return;
            }
            if (!this.session) {
                return;
            }
            this.loading = true;
            try {
                let feed = await core.api.scrollNotificationFeed(this.session);
                this.records = this.records.concat(feed.records);
                this.session = feed.session;
            } finally {
                this.loading = false;
            }
        }
    },
    components: {
        feedItem: FeedItemComponent,
        spinner: Spinner,
        hint: HintComponent
    },
    directives: {
        infinityScroll: InfinityScrollDirective
    }
}
</script>

<style lang="scss" scoped>
.notifications {
    display: flex;
    flex-direction: column;
    > *:not(:last-child) {
        margin-bottom: 10px;
    }
}
.notification-feed-content {
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
}
.notification-feed-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    button {
        background: #ffffff;
        border-radius: 3px;
        border: 1px solid #8e8e8e;
        cursor: pointer;
        padding: 10px 20px;
        &:focus {
            outline: none;
        }
        &:hover {
            background: #8e8e8e;
            color: #ffffff;
        }
    }
}
</style>