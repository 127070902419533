<template lang="html">
<div>
    <div class="address-resolve-content">
        <input v-bind:disabled="loading ? true : null" v-on:keyup.enter="search" v-model="address" type="text" placeholder="Введите адрес для поиска. Например, Москва, Улица Воздвиженка, 4" />
        <btn v-on:click="search" v-bind:disabled="loading" v-bind:text="'Указать'"></btn>
    </div>
    <div class="address-resolve-footer">
        <div v-if="notFound" class="not-found">Указанный адрес не найден. Попробуйте изменить формат записи.</div>
    </div>
    <div class="subheader">Также возможно указать координаты вручную</div>
    <div class="address-resolve-content">
        <input v-bind:disabled="loading ? true : null" v-on:keypress="isNumber($event)" v-model="exactLat" type="text" placeholder="Широта" />
        <input v-bind:disabled="loading ? true : null" v-on:keypress="isNumber($event)" v-model="exactLng" type="text" placeholder="Долгота" />
        <btn v-on:click="setCoordinates" v-bind:disabled="loading" v-bind:text="'Указать вручную'"></btn>
    </div>
</div>
</template>

<script lang="ts">
import {core} from '../../root';
import ButtonComponent from '../Button.vue';
 
export default {
    data() : any {
        return {
            address: null,
            loading: false,
            notFound: false,
            exactLat: null,
            exactLng: null
        }
    },
    methods: {
        async search() : Promise<void> {
            if (this.loading) {
                return;
            }
            this.loading = true;
            this.notFound = false;
            try {
                let coordinates = await core.api.resolveAddressIntoCoordinates(this.address);
                if (coordinates !== null) {
                    let resolvedLocation = await core.api.resolveCoordinatesIntoLocation(coordinates.lat, coordinates.lng);
                    if (resolvedLocation) {
                        this.$emit('resolved', resolvedLocation);
                    }
                } else {
                    this.notFound = true;
                }
            } finally {
                this.loading = false;
            }
        },
        async setCoordinates() : Promise<void> {
            const lat = parseFloat(this.exactLat);
            let isNan = isNaN(lat);
            if (isNan) {
                return;
            }
            const lng = parseFloat(this.exactLng);
            isNan = isNaN(lng);
            if (isNan) {
                return;
            }
            let resolvedLocation = await core.api.resolveCoordinatesIntoLocation(lat, lng);
            if (resolvedLocation) {
                this.$emit('resolved', resolvedLocation);
            }
        },
        isNumber($event) {
            if ($event.charCode === 46) {
                return true;
            }
            if ($event.charCode < 48) {
                $event.preventDefault();
            } else if ($event.charCode > 57) {
                $event.preventDefault();
            } else {
                return true;
            }
            return false;
        }
    },
    components: {
        btn: ButtonComponent
    }
}
</script>

<style lang="scss" scoped>
.subheader {
    padding-bottom: 20px;
}
.address-resolve {
    display: flex;
    flex-direction: column;
    > *:not(:last-child) {
        margin-bottom: 10px;
    }
}
.address-resolve-content {
    display: flex;
    flex-direction: row;
    input {
        flex-grow: 1;
        border: 1px solid #cccccc;
        border-radius: 15px;
        background: #fff;
        padding: 0 10px;
        &:focus {
            outline: none;
            background: #fff;
        }
    }
    > *:not(:last-child) {
        margin-right: 10px;
    }
}
.address-resolve-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    font-family: monospace;
    .not-found {
        color: #9f1111;
    }
}
</style>