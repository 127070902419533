<template lang="html">
<button v-bind:important="important ? true : null" v-bind:disabled="(context.isInProcess === true || context.isInSimilarLoading === true) ? true : null" v-on:click="onClick">{{message}}</button>
</template>

<script lang="ts">
import {RequestContext} from '../../core'

export default {
    props: {
        context: {
            type: RequestContext,
            required: true
        },
        onClick: {
            type: Function,
            required: true
        },
        message: {
            type: String,
            required: true
        },
        important: {
            type: Boolean,
            required: false
        }
    }
}
</script>

<style lang="scss" scoped>
button {
    color: #2288dd;
    background: #ffffff;
    border-radius: 2px;
    border: none;
    cursor: pointer;
    padding: 7px 10px;
    font-size: 12px;
    border: 1px solid #2288dd;
    &[important] {
        background: #289add!important;
        color: #ffffff!important;
        border-color: #289add!important;
        &:hover {
            background: lighten($color: #289add, $amount: 10%)!important;
        }
    }
    &:focus {
        outline: none;
    }
    &:hover {
        background:#2288dd;
        color: #ffffff;
    }
    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}
</style>