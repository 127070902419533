<template lang="html">
    <div class="person-meta">
        <div class="headline">
            <a v-bind:href="link" target="_blank" class="link">Анкета</a> от
            <date v-bind:value="person.dateTime" v-bind:no-time="true"></date> (внесена &mdash;
            <date v-bind:value="person.timestamp" v-bind:no-time="true"></date>)
            <span class="person-human-id"
                ><human-id v-if="person.humanId" v-bind:value="person.humanId" v-bind:prefix="'P'"></human-id
            ></span>
        </div>
        <div class="separator"></div>
        <div v-if="canEdit" v-on:click="navigateToEdit" class="button" title="Редактировать">
            <a v-bind:href="editLink" class="link">
                <i class="fa-solid fa-pencil"></i>
            </a>
        </div>
        <div v-on:click="exportPerson" v-if="canExport" class="export" title="Экспортировать">
            <span class="fa-solid fa-download"></span>
        </div>
        <div v-if="member" class="user" title="Автор анкеты">
            <i class="fa-solid fa-user-tie"></i>
            <humanId v-if="member" v-bind:value="member.humanId" v-bind:prefix="'U'"></humanId>
        </div>
    </div>
</template>

<script lang="ts">
import { core } from "../../root";
import { RecordPerson, PersonContext } from "../../core";
import DateComponent from "../Date.vue";
import NumberComponent from "../Number.vue";
import HumanIdComponent from "../common/HumanId.vue";

export default {
    props: {
        person: {
            type: RecordPerson,
            required: true
        },
        context: {
            type: PersonContext,
            required: true
        }
    },
    computed: {
        link() {
            let result = "#/person/" + this.person.personId;
            if (this.context.searchId) {
                result = result + "?search=" + this.context.searchId;
            }
            return result;
        },
        isMine() {
            return this.person.authorId === core.auth.user.id;
        },
        isInLegalMode() {
            return core.isInLegalMode;
        },
        canExport() {
            if (this.isMine === true) {
                return true;
            }
            if (this.isInLegalMode === true) {
                return false;
            }
            return true;
        },
        member() {
            let context = this.context as PersonContext;
            let person = this.person as RecordPerson;
            return context.users.get(person.authorId);
        },
        canEdit() {
            if (this.isAdmin === true) {
                return true;
            }
            let editorIndex = core.auth.user.roles.indexOf("editor");
            if (editorIndex !== -1) {
                return true;
            }
            return false;
        },
        isAdmin() {
            let adminIndex = core.auth.user.roles.indexOf("admin");
            if (adminIndex !== -1) {
                return true;
            }
            return false;
        },
        editLink() {
            return "#/person/" + this.person.personId + "/edit";
        }
    },
    methods: {
        exportPerson: function() {
            core.api.exportPerson(this.person.personId, this.context.searchId);
        },
        navigateToEdit() {
            window.location = this.editLink;
        }
    },

    components: {
        date: DateComponent,
        number: NumberComponent,
        humanId: HumanIdComponent
    }
};
</script>

<style lang="scss" scoped>
.link {
    font-weight: bold;
    text-decoration: none;
}
span {
    font-weight: bold;
}
.person-meta {
    display: flex;
    flex-direction: row;
    > *:not(:last-child):not(.separator) {
        margin-right: 5px;
    }
}
.separator {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}
.person-human-id {
    margin-left: 10px;
}
.headline {
    display: inline;
}
.export {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    cursor: pointer;
    color: #2288dd;
    border-radius: 5px;
    border: 1px solid #4499e1;
    &:hover {
        background: #2288dd;
        color: #fff;
    }
}
.user {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    font-family: monospace;
    padding: 0 10px;
    > *:not(:last-child) {
        margin-right: 5px;
    }
    i {
        font-size: 10px;
        color: #000;
    }
}
.button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    cursor: pointer;
    color: #2288dd;
    border-radius: 5px;
    border: 1px solid #4499e1;
    &:hover, a:hover {
        background: #2288dd;
        color: #fff;
        > a {
            color: #fff;
        }
    }
}
</style>
