<template lang="html">
<div></div>
</template>

<script lang="ts">
import {core} from '../root';

export default {
    mounted() {
        if (core.auth.permission.record.canWrite) {
            window.location.hash = "#/feed";
        } else {
            window.location.hash = "#/face";
        }
    }
}
</script>

<style lang="scss" scoped>

</style>