<template lang="html">
<div class="payement">
    <div v-if="success === true" class="pay-success">
        <div class="far fa-check-circle"></div>
        <div class="text">Платёж успешно проведён</div>
    </div>
    <div v-if="success === false" class="pay-rejected">
        <div class="fas fa-exclamation-circle"></div>
        <div class="text">Платёж отклонён банком</div>
    </div>
</div>
</template>

<script lang="ts">
export default {
    props: {
        success: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.payement {
    display: flex;
    flex-direction: column;
    > * {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;;
        padding: 50px 0;
        > *:not(:last-child) {
            margin-bottom: 50px;
        }
        .fas, .far {
            font-size: 50px;
        }
        .text {
            font-size: 20px;
        }
    }
}
.pay-rejected {
    color: #f96565;
}
.pay-success {
    color: green;
}
</style>