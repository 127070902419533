<template lang="html">
<div class="watches">
    <hint v-bind:text="'Список всех установленных на мониторинг персон'"></hint>
    <div v-if="records != null && records.length !== 0">
        <div v-for="item in records" class="watches-feed-content">
            <feed-item v-bind:item="item"></feed-item>
        </div>
    </div>
    <div v-if="records !== null && records.length === 0" class="feed-empty">
        <div>Пока на мониторинг не установлено ни одной персоны</div>
    </div>
    <spinner v-if="loading === true"></spinner>
</div>
</template>

<script lang="ts">
import {core} from '../root';
import {FeedRecord} from '../core';
import HintComponent from './Hint.vue';
import FeedItemComponent from './request/FeedItem.vue';
import Spinner from './Spinner.vue';

export default {
    data() {
        return new class {
            public records: FeedRecord[] = null;
            public loading: boolean = false;
        }();
    },
    async created() {
        await this.load();
    },
    methods: {
        async load() {
            if (this.loading === true) {
                return;
            }
            this.loading = true;
            try {
                let feed = await core.api.getWatch();
                this.records = feed.records;
            } finally {
                this.loading = false;
            }
        }
    },
    components: {
        feedItem: FeedItemComponent,
        spinner: Spinner,
        hint: HintComponent
    }
}
</script>

<style lang="scss" scoped>
.watches {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    > *:not(:last-child) {
        margin-bottom: 10px;
    }
}
.watches-feed-content {
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
}
.feed-empty {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}
</style>