<template lang="html">
<div>
    <div v-bind:style="style" v-if="ready !== true" class="placeholder">
        <div class="fas fa-spinner fa-pulse"></div>
    </div>
    <div v-if="ready === true" v-on:click="openFullPhoto()" class="photo-container">
        <div v-photo-score="{score: context.photoScores[photoId], func: matchLevelFunction}" v-if="context.photoScores && context.photoScores[photoId]" class="photo-score"></div>
		<img v-bind:src="src" v-bind:width="width" v-bind:height="height" />
    </div>
</div>
</template>

<script lang="ts">
import FullPhotoComponent from './FullPhoto.vue';
import {core, modal} from '../../root';
import {LoadPhotoRequest, PersonContext} from '../../core';

function setPhotoScore(element:HTMLElement, score:number, func:Function = null) {
    const value = (score * 100.0).toFixed();
    const stringValue = value.toString() + '%';
    if (func === null) {
        element.innerHTML = stringValue;
        element.setAttribute('level', '-1');
    } else {
        const level = func(score);
        element.innerHTML = stringValue;
        element.setAttribute('level', level);
    }
}

export default {
    props: {
        context: {
            type: PersonContext,
            required: true
        },
        requests: {
            type: Array<LoadPhotoRequest>,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        size: {
            type: Number,
            required: false
        }
    },
    data() {
        let style;
        if (this.size) {
            style = {};
            style.width = this.size.toString() + 'px';
            style.height = this.size.toString() + 'px';
        } else {
            style = {};
        }
        return {
            ready: false,
            width: null,
            height: null,
            style: style,
            src: null
        }
    },
    created() {
        this.load();
    },
    watch: {
        requests() {
            return this.load();
        },
        index() {
            return this.load();
        },
        size() {
            return this.load();
        }
    },
    computed: {
        matchLevelFunction() {
            return _ => core.computeFaceMatchLevel(_);
        },
        photoId() {
            let requests = this.requests as Array<LoadPhotoRequest>;
            let index = this.index as number;
            return requests[index].photoId;
        }
    },
    methods: {
        openFullPhoto() {
            modal.open(FullPhotoComponent, {
                data: {
                    requests: this.requests,
                    index: this.index,
                    width: 500
                },
                transparent: true,
                noMargin: true
            })
        },
        _loadImage(base64: string) {
            let size = this.size || 100;
            let source = 'data:image/png;base64,'+ base64;
            let image = new Image();
            image.onload = () => {
                if (image.width === image.height) {
                    this.width = size;
                    this.height = size;
                } else if (image.width > image.height) {
                    const height = Math.ceil(image.height / image.width) * size;
                    this.width = size;
                    this.height = height;
                } else {
                    this.height = size;
                    const width = Math.ceil(image.width / image.height) * size;
                    this.width = width;
                }
                this.src = source;
                this.ready = true;
            };
            image.src = source;
        },
        async load() {
            const request = this.requests[this.index];
            const base64 = await core.loadPhotoSafely(request);
            if (base64) {
                this._loadImage(base64);
            }
        }
    },
    directives: {
        photoScore: {
            mounted(element:HTMLElement, binding) {
                setPhotoScore(element, binding.value.score, binding.value.func);
            },
            unmounted(element:HTMLElement, binding) {
                setPhotoScore(element, binding.value.score, binding.value.func);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.placeholder {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    color: #ccc;
    flex-grow: 1;
}
.photo-container {
    position: relative;
    cursor: pointer;
}
.photo-score {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 0 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    border-radius: 3px;
    border-top-right-radius: 0;
    &[level='2'] {
        background: green;
        color: #ffffff;
    }
    &[level='1'] {
        background: orange;
        color: #000000;
    }
    &[level='0'], &[level='-1'] {
        background: #666;
        color: #ffffff;
    }
}
</style>