<template lang="html">
<div class="incident-company">
    <div class="incident-company-picture">
        <div class="incident-company-no-photo">
            <i class="fa-regular fa-building"></i>
        </div>
        <div class="incident-company-human-id">
            <human-id v-if="company.humanId" v-bind:value="company.humanId" v-bind:prefix="'C'" v-bind:href="companyLink">></human-id>
        </div>
    </div>
    <div class="incident-company-content">
        <div class="incident-company-name">
            <a v-bind:href="companyLink" target="_blank">{{name}}</a>
        </div>
        <div class="incident-company-tin">
            <string v-bind:value="company.inn" v-bind:stub="'Номер ИНН не указан'"></string>
        </div>
        <div class="incident-company-info">
            <div v-bind:disabled="incidentCount === null ? true : null">
                <div>
                    <span v-if="incidentCount === null || incidentCount === 0">Инциденты</span>
                    <a v-if="incidentCount !== null && incidentCount !== 0" v-on:click="openRecords($event)" v-bind:href="recordsLink" target="_blank">Инциденты</a>
                </div>
                <div>{{incidentCount === null ? '?' : incidentCount}}</div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import {PersonContext, Company} from '../../core'
import StringComponent from '../String.vue';
import HumanIdComponent from '../common/HumanId.vue';

export default {
    props: {
        company: {
            type: Company,
            required: true
        },
        context: {
            type: PersonContext,
            required: true
        }
    },
    computed: {
        companyLink() {
            let link = '#/company/' + this.company.companyId;
            if (this.context.searchId !== null) {
                link = link + '?company=' + this.context.searchId 
            }
            return link;   
        },
        name() {
            let company = this.company as Company;
            return company.name || '(Название организации не задано)'
        },
        recordsLink() {
            let link = '#/company/' + this.company.companyId + '/record';
            if (this.context.searchId !== null) {
                link = link + '?search=' + this.context.searchId 
            }
            return link;
        },
        incidentCount() {
            let company = this.company as Company;
            let context = this.context as PersonContext;
            let has = context.companyIncidentInfo.has(company.companyId);
            if (has !== true) {
                return null;
            }
            let info = context.companyIncidentInfo.get(company.companyId);
            return info.recordCount;
        },
    },
    methods: {
        openRecords($event:Event) : void {
            $event.preventDefault();
            let company = this.company as Company;
            let context = this.context as PersonContext;
            this.openCompanyRecordsModal(company.companyId, context.searchId);
        }
    },
    components: {
        string: StringComponent,
        humanId: HumanIdComponent
    }
}
</script>

<style lang="scss" scoped>
[stub] {
    color: #cccccc;
}
.incident-company {
    display: block;
    min-height: 75px;
    position: relative;
}
.incident-company-tin {
    height: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    &::before {
        color: #666;
        font-weight: bold;
        content: 'ИНН:';
        margin-right: 10px;
    }
}
.incident-company-picture {
    width: 80px;
    position: absolute;
    left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .incident-company-no-photo {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        color: #818181;
        height: 55px;
    }
    > div {
        border-radius: 3px;
        overflow: hidden;
    }
}
.incident-company-human-id {
    font-weight: bold;
}
.incident-company-content {
    display: block;
    margin-left: 95px;
    padding: 0 10px;
    .incident-company-name {
        display: inline-block;
        font-size: 12px;
        text-transform: uppercase;
        a {
            color: #000;
            text-decoration: none;
            &:hover {
                color: #666;
            }
        }
    }
    .incident-company-info {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 5px 0 0 0;
        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 25px;
            width: 50%;
            &[disabled] {
                opacity: 0.5;
                pointer-events: none;
            }
            > *:nth-child(1) {
                margin-right: 10px;
                width: 70px;
            }
            > *:nth-child(2) {
                width: 30px;
                font-family: monospace;
            }
            a {
                text-decoration: none;
            }
        }
    }
}
</style>