<template lang="html">
<div class="verify-passport-to-face">
    <hint v-bind:text="'Проверка соответствия паспортных данных и биометрии'"></hint>
    <div class="form">
        <div v-file-paste="change" class="upload-dialog">
            <div v-if="source" class="source-photo">
                <photo v-bind:content="source" v-bind:side-size="110"></photo>
            </div>
            <div v-bind:disabled="isInProcess ? true : null" v-file-drop="change" v-file-choose="change" class="dropbox">
                <span>Перетащите сюда файл или кликните для открытия диалога</span>
            </div>
        </div>
        <div class="passport-data">
            <div>
                <input v-on:keyup.enter="search" v-bind:disabled="isInProcess ? true : null" v-model="query.passportSerial" type="text" placeholder="Серия паспорта" />
            </div>
            <div>
                <input v-on:keyup.enter="search" v-bind:disabled="isInProcess ? true : null" v-model="query.passportNumber" type="text" placeholder="Номер паспорта" />
            </div>
        </div>
    </div>
    <div class="submit">
        <btn v-on:clicked="execute" v-bind:text="'Выполнить'" v-bind:disabled="(isInProcess || !canRequest) ? true : null"></btn>
    </div>
    <div>
        <div v-if="isInProcess === false && isInFailure === true" class="failure">
            <div v-if="isGeneralError === true" class="fas fa-exclamation-triangle"></div>
            <div v-if="isGeneralError === true" class="text">При поиске произошла ошибка</div>
            <div v-if="isBillingError === true" class="fas fa-ruble-sign"></div>
            <div v-if="isBillingError === true" class="text">Недостаточно средств на счёте</div>
            <div v-if="isFileSizeError === true" class="fas fa-exclamation-triangle"></div>
            <div v-if="isFileSizeError === true" class="text">Слишком большой размер файла</div>
            <div v-if="errorReason" class="reason">
                <error-reason v-bind:value="errorReason"></error-reason>
            </div>
        </div>
        <div v-if="isInProcess === false && !!result" class="result">
            <div v-if="result.isFound === false" class="match match-false">
                <div class="far fa-times-circle"></div>
                <div>Указанный паспорт не найден</div>
            </div>
            <div v-if="result.isFound === true && result.isMatch === false" class="match match-false">
                <div class="far fa-times-circle"></div>
                <div>Паспорт найден, данные не совпадают</div>
            </div>
            <div v-if="result.isFound === true && result.isMatch === true" class="match match-true">
                <div class="fas fa-check-circle"></div>
                <div>Паспорт найден, данные совпадают</div>
            </div>
        </div>
    </div>
    <spinner v-if="isInProcess === true"></spinner>
</div>
</template>

<script lang="ts">
import {core} from '../../root';

import FileChooseDirective from '../../vueDirectives/FileChoose';
import FilePasteDirective from '../../vueDirectives/FilePaste';
import FileDropDirective from '../../vueDirectives/FileDrop';
import SpinnerComponent from '../Spinner.vue';
import ImageComponent from '../Image.vue';
import {RecordFields} from '../../core';
import ButtonComponent from '../Button.vue';
import HintComponent from '../Hint.vue';

export default {
    data: function () {
        return {
            query: new RecordFields(),
            flagFilter: 'all',
            flagGroups: null,
            isInProcess: false,
            isInFailure: false,
            isBillingError: false,
            isGeneralError: false,
            isFileSizeError: false,
            errorReason: null,
            source: null,
            result: null,
        }
    },
    computed: {
        canRequest() {
            if (this.source === null) {
                return false;
            }
            if (!this.query.passportSerial) {
                return false;
            }
            if (!this.query.passportNumber) {
                return false;
            }
            return true;
        }
    },
    methods: {
        resetError() {
            this.isInFailure = false;
            this.isBillingError = false;
            this.isGeneralError = false;
            this.isFileSizeError = false;
            this.errorReason = null;
        },
        change (file:Blob) {
            if (file instanceof Array) {
                if (file.length === 0) {
                    return;
                }
                file = file[0]
            }
            this.source = file;
        },
        async execute() {
            if (this.isInProcess) {
                return;
            }
            this.isInProcess = true;
            this.resetError();
            try {
                let fileId = await core.api.uploadFile(this.source);
                let result = await core.api.verifyPassportToFace(this.query, fileId);
                this.result = result;
            } catch (error) {
                if (error.status === 500 || error.status == 400) {
                    if (!!error.responseJSON.result && !!error.responseJSON.result.reason) {
                        this.errorReason = error.responseJSON.result.reason;
                    }
                    this.isGeneralError = true;
                } else if (error.status === 402) {
                    this.isBillingError = true;
                } else if (error.status == 413) {
                    this.isFileSizeError = true;
                } else {
                    this.isGeneralError = true;
                }
                this.isInFailure = true;
            } finally {
                this.isInProcess = false;
            }
        }
    },
    directives: {
        fileChoose: FileChooseDirective,
        filePaste: FilePasteDirective,
        fileDrop: FileDropDirective
    },
    components: {
        spinner: SpinnerComponent,
        photo: ImageComponent,
        hint: HintComponent,
        btn: ButtonComponent
    }
}
</script>

<style lang="scss" scoped>
.verify-passport-to-face {
    display: flex;
    flex-direction: column;
    > * {
        margin-top: 20px;
    }
    .dropbox {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        border: 1px dashed #cccccc;
        align-items: center;
        justify-content: center;
        color: #666;
        text-transform: uppercase;
        text-align: center;
        background: #ffffff;
        padding: 0 10px;
        &.drag-hover {
            background: #ededed;
            border: 1px solid #cccccc;
        }
    }
}
[bold] {
    font-weight: bold;
}
.link {
    color: #0070e0;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
        text-decoration: none;
        color: #3eb8ff
    }
}
.result {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.failure {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;;
    padding: 50px 0;
    > *:not(:last-child) {
        margin-bottom: 50px;
    }
    .fas {
        font-size: 50px;
        &.fa-exclamation-triangle {
            color: #f96565;
        }
        &.fa-ruble-sign {
            color: #cecdcd;
        }
    }
    .text {
        font-size: 20px;
    }
}
.form {
    display: flex;
    flex-direction: row;
    height: 120px;
    > *:nth-child(1) {
        flex-grow: 1;
    }
    > *:nth-child(2) {
        width: 300px;
        flex-shrink: 0;
    }
}
.upload-dialog {
    display: flex;
    flex-direction: row;
    > * {
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
    .source-photo {
        width: 120px;
        flex-shrink: 0;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #ededed;
        border: 1px solid #cccccc;
        width: 120px;
        height: 120px;
        border-radius: 3px;
    }
    .dropbox {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        height: 120px;
    }
}
.passport-data {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 20px;
    > div {
        display: flex;
        flex-direction: row;
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
}
.submit {
    display: flex;
    flex-direction: row;
    justify-content:  flex-end;
    align-items: flex-end;
}
input {
    border: none;
    border-bottom: 1px solid #cccccc;
    width: 100%;
    padding: 5px;
    &:focus {
        outline: none;
    }
}
.result {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .match {
        display: flex;
        flex-direction: row;
        padding: 10px;
        > *:not(:last-child) {
            margin-right: 10px;
        }
        text-transform: uppercase;
        font-size: 14px;
        * {
            line-height: 30px;
        }
    }
    .match-true {
        color: green;
    }
    .match-false {
        color: red;
    }
}
</style>