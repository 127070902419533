<template lang="html">
<div class="person-bound">
    <spinner v-if="loading"></spinner>
    <div v-if="loading === false && (bindings.records.length > 0 || hasHidden)" v-bind:inactive="bindingLoading ? true : null" class="bound">
        <div v-if="noHeadline !== true" class="bound-header">
            <div>Похожие персоны</div>
            <div></div>
        </div>
        <div class="bound-filter">
            <div v-show="key in bindingReason" v-on:click="changeBindingFilter(key)" v-bind:active="bindingFilter[key] ? true : null" v-for="value, key in bindingCounters" class="bound-badge">
                <div v-binding-reason="key"></div>
                <div>&mdash;</div>
                <div>{{value}}</div>
            </div>
        </div>
        <div v-if="hasHidden && bindings.records.length > 0" class="has-hidden">
            <div class="fas fa-exclamation-triangle"></div>
            <div class="text">Некоторые совпадения были скрыты, так как у Вас нет к ним доступа</div>
        </div>
        <div v-if="hasHidden && bindings.records.length === 0" class="has-hidden">
            <div class="fas fa-exclamation-triangle"></div>
            <div class="text">Найдены похожие персоны, но у Вас нет доступа ни к одной из них</div>
        </div>
        <div class="bound-records">
            <feed-item v-bind:item="bound" v-for="bound in bindings.records"></feed-item>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import {core, bindingReason} from '../../root';

import FeedItemComponent from './FeedItem.vue';
import SpinnerComponeent from '../Spinner.vue';

export default {
    data: function () {
        return {
            loading: false,
            hasHidden: null,
            bindings: null,
            bindingCounters: null,
            bindingLoading: false,
            bindingFilter: null,
            bindingReason: bindingReason
        }
    },
    props: {
        id: {
            type: String,
            required: true
        },
        searchId: {
            type: String,
            required: false
        },
        noHeadline: {
            type: Boolean,
            required: false
        }
    },
    created: function () {
        return this.load(this.id);
    },
    watch: {
        'id': function () {
            this.load();
        }
    },
    methods: {
        async loadBindings(reasons:Array<string> = null) {
            let personId = this.id;
            this.bindingLoading = true;
            this.hasHidden = null;
            try {
                let bindings = await core.api.getPersonBindings(personId, this.searchId, reasons);
                if (this.id === personId) {
                    this.bindings = bindings;
                }
                if (reasons === null) {
                    let bindingCounters = await core.api.getPersonBindingCounters(personId, this.searchId);
                    if (this.id === personId) {
                        this.bindingCounters = bindingCounters;
                        this.bindingFilter = {};
                        Object.keys(bindingCounters).forEach(_ => this.bindingFilter[_] = true);
                    }
                }
                this.hasHidden = bindings.metaData.hasHidden || false;
            } finally {
                if (this.id === personId) {
                    this.bindingLoading = false;
                }
            }
        },
        async load() {
            let isSuccess = false;
            try {
                this.loading = true;
                await this.loadBindings();
                isSuccess = true;
            } finally {
                this.loading = false;
            }
            if (isSuccess) {
                this.$emit('loaded', this.$el);
            }
        },
        async changeBindingFilter(key) {
            this.bindingFilter[key] = !this.bindingFilter[key];
            const reasons = [];
            Object.keys(this.bindingFilter).forEach(_ => {
                const value = this.bindingFilter[_];
                if (value && _ in bindingReason) {
                    reasons.push(_);
                }
            });
            await this.loadBindings(reasons);
        }
    },
    components: {
        feedItem: FeedItemComponent,
        spinner: SpinnerComponeent
    },
    directives: {
        bindingReason: {
            mounted: function (element:HTMLElement, binding) {
                element.innerHTML = bindingReason[binding.value];
            },
            updated: function (element:HTMLElement, binding) {
                element.innerHTML = bindingReason[binding.value];
            },
            unmounted: function (element:HTMLElement) {
                element.innerHTML = '';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.person-bound {
    display: felx;
    flex-direction: column;
}
.has-hidden {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    color: #bf8080;
    > *:not(:last-child) {
        margin-right: 20px;
    }
    border: 1px solid #ffc6c6;
}
.bound {
    display: flex;
    flex-direction: column;
    > *:not(:last-child) {
        margin-bottom: 10px;
    }
    &[inactive] {
        pointer-events: none;
        opacity: 0.4;
    }
    .bound-header {
        $_height: 40px;
        display: flex;
        flex-direction: row;
        line-height: $_height;
        height: $_height;
        font-size: 14px;
        > div {
            display: flex;
            flex-direction: row;
            flex-shrink: 0;
        }
        > div:nth-child(1) {
            padding-right: 20px;
            height: $_height;
        }
        > div:nth-child(2) {
            border-bottom: 1px solid #cccccc;
            flex-grow: 1;
            height: calc($_height / 2);
        }
    }
    .bound-filter {
        display: flex;
        flex-direction: row;
        > *:not(:last-child) {
            margin-right: 10px;
        }
        .bound-badge {
            display: flex;
            flex-direction: row;
            padding: 3px 15px;
            border-radius: 5px;
            cursor: pointer;
            > *:not(:last-child) {
                margin-right: 5px;
            }
            box-shadow: 0 4px 8px #0000000a, 0 0 4px #0000000f;
            transition: all 0.3s ease 0s;
            border: 1px solid #ccc;
            background: #fff;
            color: #000;
            &:not([active]) {
                opacity: 0.5;
            }
        }
    }
    .bound-records {
        > *:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}
</style>