<template lang="html">
<div class="record-media">
    <div v-if="recordFiles.length > 0" class="record-files">
        <div v-for="recordFile in recordFiles">
            <span>Прикреплённый файл</span>
            <span v-if="isInLegalMode !== true || isMine"><a v-bind:href="getRecordFileLink(recordFile.recordId, recordFile.file.fileId)" target="_blank">{{recordFile.file.name || stubFilename}}</a></span>
            <span v-if="isInLegalMode === true && !isMine">{{recordFile.file.name || stubFilename}}</span>
        </div>
    </div>
    <div v-if="personFiles.length > 0" class="record-files">
        <div v-for="personFile in personFiles">
            <span>Прикреплённый файл</span>
            <span v-if="isInLegalMode !== true || isMine"><a v-bind:href="getPersonFileLink(personFile.personId, personFile.file.fileId)" target="_blank">{{personFile.file.name || stubFilename}}</a></span>
            <span v-if="isInLegalMode === true && !isMine">{{personFile.file.name || stubFilename}}</span>
        </div>
    </div>
    <div v-if="photoRequests.length > 0" class="record-files">
        <div>
            <span>Прикреплённые медиа-объекты:</span>
            <span v-if="showPhoto !== true">
                <span v-on:click="showPhoto = true" class="link">Показать все (<b>{{photoRequests.length}}</b>)</span>
            </span>
            <span v-if="showPhoto === true">
                <span v-on:click="showPhoto = false" class="link">Скрыть</span>
            </span>
        </div>
    </div>
    <div v-if="photoRequests.length > 0 && showPhoto === true" class="record-photos">
        <photo v-bind:size="55" v-bind:context="context" v-bind:requests="photoRequests" v-bind:index="index" v-for="_, index in photoRequests"></photo>
    </div>
</div>
</template>

<script lang="ts">
import {core} from '../../root';
import {Record, PersonContext, FileDescriptor, LoadPhotoRequest, LoadPersonPhotoRequest, LoadRecordPhotoRequest, RecordPerson} from '../../core';
import PhotoComponent from './PhotoPreview.vue';

abstract class FileItem {
    public file:FileDescriptor = null;
}
class PersonFile extends FileItem {
    public personId:string = null;
}
class RecordFile extends FileItem {
    public recordId:string = null;
}
function appendPersonPhotos(context: PersonContext, record: Record, array: Array<LoadPhotoRequest>) : void {
    let hasConnection = context.connections.has(record.recordId);
    if (hasConnection !== true) {
        return;
    }
    let connections = context.connections.get(record.recordId);
    for (let personId of connections) {
        let has = context.persons.has(personId);
        if (has !== true) {
            continue;
        }
        let person = context.persons.get(personId);
        for (let photoId of person.photos) {
            let request = new LoadPersonPhotoRequest(person.personId, photoId, context.searchId);
            array.push(request);
        }
    }
}
function appendRecordPhotos(context: PersonContext, record: Record, array: Array<LoadPhotoRequest>) : void {
    for (let photoId of record.photos) {
        let request = new LoadRecordPhotoRequest(record.recordId, photoId, context.searchId);
        array.push(request);
    }
}
function buildPhotoRequestArray(context: PersonContext, record: Record) : Array<LoadPhotoRequest> {
    let array = new Array<LoadPhotoRequest>();
    appendPersonPhotos(context, record, array);
    appendRecordPhotos(context, record, array);
    return array;
}
export default {
    props: {
        record: {
            type: Record,
            required: true
        },
        context: {
            type: PersonContext,
            required: true
        }
    },
    data() {
        return {
            showPhoto: false,
            stubFilename: '(Файл без имени)'
        };
    },
    computed: {
        isMine() {
            return this.record.authorId === core.auth.user.id;
        },
        isInLegalMode() {
            return core.isInLegalMode;
        },
        recordFiles() {
            let array = new Array<RecordFile>();
            let record = this.record as Record;
            if (record.files === null) {
                return array;
            }
            let context = this.context as PersonContext;
            for (let fileId of record.files) {
                let item = new RecordFile();
                item.recordId = record.recordId;
                item.file = context.files.get(fileId);
                array.push(item);
            }
            return array;
        },
        personFiles() {
            let array = new Array<PersonFile>();
            let record = this.record as Record;
            let context = this.context as PersonContext;
            let hasConnection = context.connections.has(record.recordId);
            if (hasConnection !== true) {
                return array;
            }
            let connections = context.connections.get(record.recordId);
            for (let personId of connections) {
                let has = context.persons.has(personId);
                if (has !== true) {
                    continue;
                }
                let person = context.persons.get(personId);
                for (let fileId of person.files) {
                    let item = new PersonFile();
                    item.personId = personId;
                    item.file = context.files.get(fileId);
                    array.push(item);
                }
            }
            return array;
        },
        photoRequests() {
            let record = this.record as Record;
            let context = this.context as PersonContext;
            return buildPhotoRequestArray(context, record);
        }
    },
    methods: {
        getRecordFileLink(recordId:string, fileId:string) : string {
            let context = this.context as PersonContext;
            return core.api.getRecordFileDownloadLink(recordId, fileId, context.searchId);
        },
        getPersonFileLink(personId:string, fileId:string) : string {
            let context = this.context as PersonContext;
            return core.api.getFileDownloadLink(personId, fileId, context.searchId);
        }
    },
    components: {
        photo: PhotoComponent
    }
}
</script>

<style lang="scss" scoped>
.record-media {
    display: flex;
    flex-direction: column;
    > *:not(:last-child) {
        margin-bottom: 10px;
    }
}
.record-files {
    display: flex;
    flex-direction: column;
    > div > span:first-child {
        color: #aaa;
        &::after {
            content: ':';
        }
    }
    > *:not(:last-child) {
        margin-bottom: 5px;
    }
    > div {
        display: inline-block;
        > *:not(:last-child) {
            margin-right: 10px;
        }
    }
}
.record-photos {
    display:  flex;
    flex-direction: row;
    min-height: 30px;
    flex-wrap: wrap;
    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 55px;
        height: 55px;
        border-radius: 3px;
        overflow: hidden;
        margin: 10px 10px 0 0;
        cursor: pointer;
    }
}
</style>