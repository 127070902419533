<template lang="html">
<div class="incident-person">
    <a v-bind:href="'#/person/' + person.personId" v-if="isOnWatch" class="is-on-watch">Мониторинг</a>
    <div class="incident-person-picture">
        <div v-if="photoRequests.length > 0">
            <photo v-bind:context="context" v-bind:requests="photoRequests" v-bind:index="0" v-bind:size="55"></photo>
        </div>
        <div v-if="photoRequests.length === 0" class="incident-person-no-photo">
            <span class="fa-solid fa-user-secret"></span>
        </div>
        <div class="incident-person-human-id">
            <human-id v-if="person.humanId" v-bind:value="person.humanId" v-bind:prefix="'P'" v-bind:href="'#/person/' + person.personId">></human-id>
        </div>
    </div>
    <div class="incident-person-content">
        <div class="incident-person-name">
            <a v-bind:href="personLink" target="_blank">{{name}}</a>
        </div>
        <div class="incident-person-birthday">
            <div v-if="hasBirthday === false && hasTin === false" stub="1">Дата рождения и ИНН не указаны</div>
            <person-date v-if="hasBirthday" v-bind:day="person.fields.birthdayDay" v-bind:month="person.fields.birthdayMonth"  v-bind:year="person.fields.birthdayYear" v-bind:stub="'Дата рождения не указана'"></person-date>
            <div v-if="hasTin" v-bind:has-birthday="hasBirthday" class="tin">
                <div>ИНН</div>
                <string v-bind:value="person.fields.inn"></string>
            </div>
        </div>
        <div class="incident-person-info">
            <div v-bind:disabled="incidentCount === null ? true : null">
                <div>
                    <span v-if="incidentCount === null || incidentCount === 0">Инциденты</span>
                    <a v-if="incidentCount !== null && incidentCount !== 0" v-on:click="openRecords($event)" v-bind:href="recordsLink" target="_blank">Инциденты</a>
                </div>
                <div>{{incidentCount === null ? '?' : incidentCount}}</div>
            </div>
            <div v-on:click="!!bindingCount && openBound($event)" v-bind:disabled="bindingCount === null ? true : null">
                <div>
                    <span v-if="!bindingCount">Похожие</span>
                    <a v-if="!!bindingCount" v-bind:href="bindingLink" target="_blank">Похожие</a>
                </div>
                <div>{{bindingCount === null ? '?' : bindingCount}}</div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import {RecordPerson, PersonContext, LoadPersonPhotoRequest, LoadPhotoRequest} from '../../core'
import StringComponent from '../String.vue';
import RequestFeedData from './RequestFeedDate.vue';
import HumanIdComponent from '../common/HumanId.vue';
import PhotoComponent from './PhotoPreview.vue';

function resolveBestPhotoId(person: RecordPerson, context: PersonContext) : string|null {
    if (context.photoScores) {
        /**
         * Скоринговые баллы фотографий бывают только в случае, если был произведён поиск по СРЛ
         * В таком случае выбираем фотографю с наибольшим процентом совпадения
         */
        let maxScore = 0.0;
        let maxScorePhotoId:string = null;
        for (let i = 0; i < person.photos.length; i++) {
            let photoId = person.photos[i];
            let score = context.photoScores[photoId];
            if (!score) {
                continue
            }
            if (score <= maxScore) {
                continue;
            }
            maxScore = score;
            maxScorePhotoId =photoId;
        }
        if (maxScorePhotoId) {
            return maxScorePhotoId;
        }
    } else {
        /**
         * Если поиска по СРЛ не было, то показываем сгенерированный аватар при его наличии
         * В противном случае показываем просто первую попавшуюся фотку, на которой есть лицо
         */
        let hasAvatar = context.avatars.has(person.personId);
        if (hasAvatar) {
            return context.avatars.get(person.personId);
        }
        if (person.photos.length === 0) {
            return null;
        }
        for (let i = 0; i < person.photos.length; i++) {
            let mediaId = person.photos[i];
            let hasFaceInfo = context.faceInfo.has(mediaId);
            if (hasFaceInfo !== true) {
                continue;
            }
            let faceInfo = context.faceInfo.get(mediaId);
            if (faceInfo.faceCount > 0) {
                return mediaId;
            }
        }
    }
    /**
     * Если ничего подобрать не удалось, то просто показываем первую попавшуюся фотку
     */
    return person.photos[0];
}

export default {
    props: {
        person: {
            type: RecordPerson,
            required: true
        },
        context: {
            type: PersonContext,
            required: true
        }
    },
    computed: {
        isOnWatch() : boolean {
            let person = this.person as RecordPerson;
            let context = this.context as PersonContext;
            let index = context.watchedPersons.indexOf(person.personId);
            return index !== -1;
        },
        incidentCount() : number|null {
            let person = this.person as RecordPerson;
            let context = this.context as PersonContext;
            let has = context.incidentInfo.has(person.personId);
            if (has !== true) {
                return null;
            }
            let info = context.incidentInfo.get(person.personId);
            return info.recordCount;
        },
        bindingCount() : number|null {
            let context = this.context as PersonContext;
            let person = this.person as RecordPerson;
            let has = context.bindingCount.has(person.personId);
            if (has !== true) {
                return null;
            }
            return context.bindingCount.get(person.personId);
        },
        photoRequests() : Array<LoadPhotoRequest> {
            let person = this.person as RecordPerson;
            let context = this.context as PersonContext;
            let bestPhotoId = resolveBestPhotoId(person, context);
            let requests = new Array<LoadPhotoRequest>();
            if (!!bestPhotoId) {
                let request = new LoadPersonPhotoRequest(person.personId, bestPhotoId, context.searchId);
                requests.push(request);
            }
            for (let photoId of person.photos) {
                if (photoId === bestPhotoId) {
                    continue;
                }
                let request = new LoadPersonPhotoRequest(person.personId, photoId, context.searchId);
                requests.push(request);
            }
            return requests;
        },
        personLink() : string {
            let link = '#/person/' + this.person.personId;
            if (this.context.searchId !== null) {
                link = link + '?search=' + this.context.searchId 
            }
            return link;            
        },
        bindingLink() : string {
            let link = '#/person/' + this.person.personId + '?section=bound';
            if (this.context.searchId !== null) {
                link = link + '&search=' + this.context.searchId 
            }
            return link;
        },
        recordsLink() : string {
            let link = '#/records/' + this.person.personId;
            if (this.context.searchId !== null) {
                link = link + '?search=' + this.context.searchId 
            }
            return link;
        },
        name() : string {
            let person = this.person as RecordPerson;
            let array = [];
            if (person.fields.lastname) {
                array.push(person.fields.lastname);
            }
            if (person.fields.firstname) {
                array.push(person.fields.firstname);
            }
            if (person.fields.patronymic) {
                array.push(person.fields.patronymic);
            }
            let join = array.join(' ');
            return join || '(Имя не задано)'
        },
        hasBirthday() : boolean {
            let person = this.person as RecordPerson;
            if (person.fields.birthday === null) {
                return false;
            }
            return true;
        },
        hasTin() : boolean {
            let person = this.person as RecordPerson;
            return !!person.fields.inn;
        }
    },
    methods: {
        openRecords($event:Event) : void {
            $event.preventDefault();
            this.openIncidentsModal(this.person.personId, this.context.searchId);
        },
        openBound($event:Event) : void {
            $event.preventDefault();
            this.openBoundModal(this.person.personId, this.context.searchId);
        }
    },
    components: {
        string: StringComponent,
        humanId: HumanIdComponent,
        personDate: RequestFeedData,
        photo: PhotoComponent
    }
}
</script>

<style lang="scss" scoped>
.tin {
    display: flex;
    flex-direction: row;
    > *:first-child {
        font-weight: bold;
        &::after {
            content: ':';
            margin-right: 5px;
        }
    }
}
.incident-person {
    display: flex;
    flex-direction: column;
    min-height: 75px;
    position: relative;
    > * {
        display: flex;
        flex-shrink: 0;
    }
    .is-on-watch {
        $_font-size: 10px;
        position: absolute;
        text-decoration: none;
        z-index: 100;
        text-transform: uppercase;
        font-family: Arial, Helvetica, sans-serif;
        font-size: $_font-size;
        line-height: $_font-size;
        border-radius: 3px;
        color: white;
        padding: 5px;
        background: #008000a6;
        top: 0px;
        left: 10px;
        &:hover {
            text-decoration: underline;
        }
    }
}
.incident-person-birthday {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 25px;
    color: #444;
    > *:not(:last-child) {
        margin-right: 10px;
    }
    [stub] {
        color: #cccccc;
    }
}
.incident-person-content {
    display: flex;
    flex-direction: column;
    margin-left: 95px;
    padding: 0 10px;
    flex-grow: 1;
    .incident-person-info {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex-grow: 1;
        align-items: flex-end;
        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 25px;
            width: 50%;
            &[disabled] {
                opacity: 0.5;
                pointer-events: none;
            }
            > *:nth-child(1) {
                margin-right: 10px;
                width: 70px;
            }
            > *:nth-child(2) {
                width: 30px;
                font-family: monospace;
            }
            a {
                text-decoration: none;
            }
        }
    }
    .incident-person-name {
        display: inline-block;
        font-size: 12px;
        text-transform: uppercase;
        a {
            color: #000;
            text-decoration: none;
            &:hover {
                color: #666;
            }
        }
    }
}
.incident-person-picture {
    width: 80px;
    position: absolute;
    left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .incident-person-no-photo {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        color: #818181;
        height: 55px;
    }
    > div {
        border-radius: 3px;
        overflow: hidden;
    }
}
.incident-person-human-id {
    font-weight: bold;
}
.incident-person-stats {
    display: inline-flex;
    border-radius: 3px;
    font-family: monospace;
    > span {
        display: inline-flex;
        > span {
            display: inline-flex;
            padding: 2px 5px;
            &:nth-child(2) {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                min-width: 20px;
            }
        }
        &:not(:last-child) {
            border-right: 1px solid #aaa;
        }
    }
}
</style>