<template lang="html">
<div class="denied">
    <div class="fas fa-ban"></div>
    <div class="text">Данный функционал Вам недоступен</div>
</div>
</template>

<script lang="ts">
export default {}
</script>

<style lang="scss" scoped>
.denied {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;;
    padding: 50px 0;
    > *:not(:last-child) {
        margin-bottom: 50px;
    }
    .fas {
        font-size: 50px;
        color: #f96565;
    }
    .text {
        font-size: 20px;
    }
}
</style>