<template lang="html">
    <div id="root">
        <header>
            <div>
                <div class="navbar">
                    <a
                        v-if="auth.permission.record.canWrite === true"
                        v-bind:active="currentTool === 'feed' ? true : null"
                        href="#/feed"
                        >Записи</a
                    >
                    <a v-bind:active="currentTool === 'face' ? true : null" href="#/face">Сравнение</a>
                    <i class="separator"></i>
                    <div class="navbar-block">
                        <a
                            v-bind:active="currentTool === 'search' && currentSubTool === 'face' ? true : null"
                            href="#/search/face"
                            >Лица</a
                        >
                        <a
                            v-bind:active="currentTool === 'search' && currentSubTool === 'text' ? true : null"
                            href="#/search/text"
                            >Анкеты</a
                        >
                    </div>
                    <div class="navbar-block">
                        <div class="human-id-resolver">
                            <input
                                v-bind:disabled="isInHumanIdResolve ? true : null"
                                v-model="humanId"
                                v-on:keyup.enter="resolve"
                                placeholder="ID инцидента или анкеты"
                                type="text"
                            />
                            <span v-bind:disabled="isInHumanIdResolve ? true : null" v-on:click="resolve">
                                <span v-if="isInHumanIdResolve !== true" class="fa-solid fa-magnifying-glass"></span>
                                <div v-if="isInHumanIdResolve" class="fas fa-sync-alt fa-spin"></div>
                            </span>
                        </div>
                    </div>
                    <i v-if="auth.permission.record.canWrite" class="separator"></i>
                    <a v-if="auth.permission.record.canWrite" href="#/new" class="button">
                        <div class="fas fa-plus"></div>
                        <div>Инцидент</div>
                    </a>
                </div>
                <div class="navbar">
                    <a
                        v-bind:has-new="hasAnyNotification ? true : null"
                        href="#/notification"
                        class="notification-link"
                    >
                        <span>
                            <i v-if="hasAnyNotification !== true" class="far fa-bell"></i>
                            <i v-if="hasAnyNotification === true" class="fas fa-bell"></i>
                        </span>
                    </a>
                    <a href="#/watch">
                        <i class="fas fa-binoculars"></i>
                    </a>
                    <a v-on:click="openMetric($event)" href="#">
                        <i class="fas fa-chart-pie"></i>
                    </a>
                    <a v-if="roleMap['no_billing'] !== true" href="#/balance/refill" class="balance">
                        <tooltip :text="'Баланс: ' + formatedBalance + ' (пополнить)'">
                            {{ balance }} &#8381;
                        </tooltip>
                    </a>
                    <a v-on:click="logout" href="#">Выйти</a>
                </div>
            </div>
        </header>
        <main class="main">
            <root v-if="currentTool === 'root'"></root>
            <div v-if="currentTool === 'feed'" class="main-block">
                <requestFeed
                    v-if="auth.permission.record.canWrite === true"
                    v-bind:default-status="args.status"
                    v-bind:default-flags="args.flags"
                    v-bind:type="args.type"
                    v-bind:on-settings-updated="onFeedSettingsUpdated"
                ></requestFeed>
                <deny v-if="auth.permission.record.canWrite === false"></deny>
            </div>
            <div v-if="currentTool === 'form'">
                <recordForm
                    v-if="auth.permission.record.canWrite === true"
                    v-bind:id="currentSubTool"
                    v-bind:stage="currentSubSubTool"
                ></recordForm>
                <deny v-if="auth.permission.record.canWrite === false"></deny>
            </div>
            <div v-if="currentTool === 'new'">
                <spinner v-if="isInRecordCreate"></spinner>
                <deny v-if="auth.permission.record.canWrite === false"></deny>
            </div>
            <record
                v-if="currentTool === 'record'"
                v-bind:search-id="args.search"
                v-bind:section="args.section"
                v-bind:id="currentSubTool"
            ></record>
            <div v-if="currentTool === 'person'">
                <person
                    v-if="!!currentSubTool && !currentSubSubTool"
                    v-bind:id="currentSubTool"
                    v-bind:search-id="args.search"
                ></person>
                <change-person-data
                    v-if="!!currentSubTool && currentSubSubTool === 'edit'"
                    v-bind:id="currentSubTool"
                    v-bind:search-id="args.search"
                />
            </div>
            <company
                v-if="currentTool === 'company' && !currentSubSubTool"
                v-bind:id="currentSubTool"
                v-bind:search-id="args.search"
            ></company>
            <person-incidents
                v-if="currentTool === 'records'"
                v-bind:id="currentSubTool"
                v-bind:search-id="args.search"
            ></person-incidents>
            <faceCompare v-if="currentTool === 'face'"></faceCompare>
            <notificationFeed v-if="currentTool === 'notification'"></notificationFeed>
            <watch v-if="currentTool === 'watch'"></watch>
            <searchFace v-if="currentTool === 'search' && currentSubTool === 'face'"></searchFace>
            <searchText
                v-if="currentTool === 'search' && currentSubTool === 'text'"
                v-bind:query-string="args.queryString"
                v-bind:query-type="args.queryType"
            ></searchText>
            <balance-refill v-if="currentTool === 'balance' && currentSubTool === 'refill'"></balance-refill>
            <pay v-if="currentTool === 'payement'" v-bind:success="currentSubTool === 'success'"></pay>
            <user-log-metric
                v-if="currentTool === 'metric'"
                v-bind:user-id="userId"
                v-bind:from="new Date(args.from)"
                v-bind:till="new Date(args.till)"
                v-bind:no-actions="true"
            ></user-log-metric>
            <verify-passport-to-face
                v-if="
                    currentTool == 'verify' &&
                        currentSubTool === 'passport' &&
                        roleMap['passport_to_face_verification'] === true
                "
            ></verify-passport-to-face>
            <documentation :role="'user'" v-if="currentTool === 'documentation'" />
            <div v-if="isImpersonated" class="impersonated-message">
                <div>
                    Выполнена имперсонация от имени <span>{{ user.login }}</span>
                </div>
            </div>
        </main>
        <scrollUp></scrollUp>
        <vendor></vendor>
        <logo></logo>
    </div>
</template>

<script lang="ts">
import { core, modal } from "../root";
import RecordComponent from "./request/Record.vue";
import PersonComponent from "./request/Person.vue";
import RecordFormComponent from "./request/RecordForm.vue";
import PersonIncidentsComponent from "./request/PersonIncidents.vue";
import RequestFeedComponent from "./request/RequestFeed.vue";
import FaceCompareComponent from "./util/FaceCompare.vue";
import SearchFaceComponent from "./SearchFace.vue";
import SearchTextComponent from "./SearchText.vue";
import ScrollUpComponent from "./ScrollUp.vue";
import BalanceRefillComponent from "./BalanceRefill.vue";
import UserLogMetricComponent from "./User/UserLogMetric.vue";
import VendorComponent from "./common/Vendor.vue";
import SpinnerComponent from "./Spinner.vue";
import LogoComponent from "./common/InvertedLogo.vue";
import DenyComponent from "./Deny.vue";
import RootComponent from "./Root.vue";
import PayComponent from "./Pay.vue";
import CompanyComponent from "./request/Company.vue";
import VerifyPassportToFaceComponent from "./verification/VerifyPassportToFace.vue";
import NotificationFeedComponent from "./NotificationFeed.vue";
import WatchComponent from "./Watch.vue";
import AlerFeedComponent from "./AlertFeed.vue";
import { Api, FeedSettings } from "../core";
import ChangePersonDataComponent from "./request/ChangePersonData.vue";
import DocumentationComponent from "./admin/AdminDocumentation.vue";
import TooltipComponent from "./Tooltip.vue";

function buildMetricPath(): string {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    let from = new Date(year, month, 1);
    let till = new Date(year, month + 1, 1);
    let fromISOString = from.toISOString();
    let tillISOString = till.toISOString();
    let queryString = "from=" + fromISOString + "&till=" + tillISOString;
    return "#/metric?" + queryString;
}

export default {
    data: function() {
        return {
            core: core,
            humanId: null,
            isInHumanIdResolve: false,
            isInRecordCreate: false
        };
    },
    created() {
        if (core.auth.notification.alert.hasAny !== true) {
            return;
        }
        let closeHandle = modal.open(AlerFeedComponent, {
            dialog: true,
            disableOverlayClickClose: true,
            events: {
                close: () => closeHandle()
            }
        });
    },
    computed: {
        auth: function() {
            return core.auth;
        },
        args: function() {
            return this.routingScope.args;
        },
        roleMap() {
            const map = {};
            core.auth.user.roles.forEach(_ => (map[_] = true));
            return map;
        },
        currentTool: function() {
            return this.routingScope.path[0];
        },
        currentSubTool: function() {
            return this.routingScope.path[1];
        },
        currentSubSubTool: function() {
            return this.routingScope.path[2];
        },
        isImpersonated() {
            return !!core.impersonatedFrom;
        },
        hasAnyNotification() {
            return core.hasAnyNotification;
        },
        formatedBalance() {
            return new Intl.NumberFormat("ru-RU").format(this.balanceRubs);
        },

        balanceRubs() {
            let value = core.auth.balance.balance;
            if (value === 0) {
                return 0;
            }
            return Math.floor(value / 100);
        },
        balance() {
            let rubs = this.balanceRubs;
            let postfix = "";
            if (rubs >= 1000000) {
                rubs = Math.floor(rubs / 1000000);
                postfix = "M";
            } else if (rubs >= 10000) {
                rubs = Math.floor(rubs / 1000);
                postfix = "K";
            }
            let rubsString = rubs.toString();
            return rubsString.replace(/\B(?=(\d{3})+(?!\d))/g, " ") + postfix;
        },
        user() {
            return core.auth.user;
        },
        userId() {
            return this.user.id;
        }
    },
    components: {
        record: RecordComponent,
        person: PersonComponent,
        company: CompanyComponent,
        personIncidents: PersonIncidentsComponent,
        recordForm: RecordFormComponent,
        requestFeed: RequestFeedComponent,
        faceCompare: FaceCompareComponent,
        searchFace: SearchFaceComponent,
        searchText: SearchTextComponent,
        scrollUp: ScrollUpComponent,
        balanceRefill: BalanceRefillComponent,
        userLogMetric: UserLogMetricComponent,
        verifyPassportToFace: VerifyPassportToFaceComponent,
        notificationFeed: NotificationFeedComponent,
        watch: WatchComponent,
        spinner: SpinnerComponent,
        vendor: VendorComponent,
        logo: LogoComponent,
        deny: DenyComponent,
        root: RootComponent,
        pay: PayComponent,
        changePersonData: ChangePersonDataComponent,
        documentation: DocumentationComponent,
        tooltip: TooltipComponent
    },
    watch: {
        async currentTool(value) {
            if (value === "new") {
                try {
                    this.isInRecordCreate = true;
                    const recordId = await core.api.createRecord();
                    window.location.hash = "#/form/" + recordId + "/1";
                } finally {
                    this.isInRecordCreate = false;
                }
            }
        }
    },
    methods: {
        logout: function() {
            return core.api.deleteAuth().then(
                () => {
                    localStorage.removeItem("secret");
                    window.location.href = "/login.html";
                },
                error => {
                    core.raiseError(error, "Ошибка выхода из системы");
                    localStorage.removeItem("secret");
                    window.location.href = "/login.html";
                }
            );
        },
        onFeedSettingsUpdated(settings: FeedSettings) {
            let args = {};
            if (settings.status) {
                args["status"] = settings.status;
            }
            if (settings.type) {
                args["type"] = settings.type;
            }
            if (settings.flags) {
                args["flags"] = settings.flags;
            }
            let queryString = Api.buildQueryString(args);
            window.location.hash = "#/feed?" + queryString;
        },
        openMetric($event: MouseEvent) {
            $event.preventDefault();
            let path = buildMetricPath();
            window.location.hash = path;
        },
        async resolve() {
            if (this.isInHumanIdResolve) {
                return;
            }
            this.isInHumanIdResolve = true;
            let humanId;
            try {
                humanId = this.humanId.replaceAll(/[^\d]/gi, "");
                humanId = parseInt(humanId);
                let isNan = isNaN(humanId);
                if (isNan) {
                    throw new Error();
                }
            } catch {
                this.isBadId = true;
                this.isInHumanIdResolve = false;
                this.humanId = null;
                return;
            }
            try {
                let recordId = await core.api.resolveRecordHumanId(humanId);
                window.location.hash = "#/record/" + recordId;
            } catch (error) {
                if (error.status === 404) {
                    this.isNotFound = true;
                } else {
                    throw error;
                }
            }
            try {
                let personId = await core.api.resolvePersonHumanId(humanId);
                window.location.hash = "#/person/" + personId;
            } catch (error) {
                if (error.status === 404) {
                    this.isNotFound = true;
                } else {
                    throw error;
                }
            }
            this.humanId = null;
            this.isInHumanIdResolve = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.notification-link {
    position: relative;
    &:hover {
        text-decoration: none !important;
        span {
            background: #ffffff;
        }
        &:not([has-new="true"]) {
            color: #0071b5;
        }
    }
    span {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 20px;
        width: 20px;
        border-radius: 3px;
        background: #0071b5;
        border: 1px solid #02629d;
    }
    &[has-new="true"] {
        span {
            border: 1px solid #dc3545;
            background: #dc3545;
            color: #ffffff;
            &:hover {
                background: #e1626e;
            }
        }
    }
}
header {
    display: flex;
    flex-direction: row;
    $_width: 50px;
    $_space: 10px;
    background: #2288dd;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05), 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;
    height: $_width;
    width: 100%;
    justify-content: center;
    align-items: center;
    > div {
        height: $_width;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 800px;
    }
    .human-id-resolver {
        display: flex;
        flex-direction: row;
        align-items: center;
        *[disabled] {
            opacity: 0.5;
            pointer-events: none;
        }
        > span {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            box-sizing: border-box;
            font-size: 12px;
            border: 1px solid #02629d;
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            &:not(:hover) {
                background: #0071b5;
            }
            &:hover {
                background: #ffffff;
                color: #005689;
            }
            height: 30px;
            width: 30px;
            &:not([disabled]) {
                cursor: pointer;
            }
        }
        input {
            border: 1px solid #02629d;
            border-right: none;
            background: #0071b5;
            color: #ffffff;
            padding: 5px 5px;
            border-radius: 3px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            height: 30px;
            width: 170px;
            &:focus {
                outline: none;
                background: #ffffff;
                color: #005689;
                &::placeholder {
                    color: #aaa;
                }
            }
            &::placeholder {
                color: #ffffff;
            }
        }
    }
    .navbar {
        display: flex;
        flex-direction: row;
        height: $_width - $_space * 2;
        margin-top: $_space;
        > *:not(:last-child) {
            margin-right: 15px;
        }
        .navbar-block {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #ffffff;
            > *:not(:last-child) {
                margin-right: 20px;
            }
        }
        a {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            height: $_width - $_space * 2;
            color: #ffffff;
            font-family: Arial;
            font-size: 12px;
            &:focus,
            & {
                text-decoration: none;
            }
            &:hover {
                text-decoration: underline;
            }
            &.button {
                display: flex;
                flex-direction: row;
                padding: 0px 10px;
                height: 28px;
                border-radius: 20px;
                border: 1px solid #ffffff;
                font-size: 13px;
                &:hover {
                    background: #ffffff;
                    color: #37b5ff;
                    text-decoration: none;
                }
                > * {
                    &:not(:last-child) {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    .separator {
        display: flex;
        height: 30px;
        align-self: center;
        border-left: 1px solid #cccccc;
    }
}
main {
    display: flex;
    flex-direction: column;
    width: 800px;
    margin: 0 auto;
    padding: 60px 0;
    flex-grow: 1;
    font: {
        family: Arial;
        size: 12px;
    }
    line-height: 20px;
}
.impersonated-message {
    position: fixed;
    bottom: 0;
    left: 50%;
    width: 800px;
    margin-left: -400px;
    font-family: monospace;
    > div {
        padding: 5px 10px;
        background: #fffdea;
        border: 1px solid #cccccc;
        border-bottom: none;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        span {
            font-weight: bold;
        }
    }
}
#root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}
.main-block {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.denied {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    > *:not(:last-child) {
        margin-bottom: 50px;
    }
    .fas {
        font-size: 50px;
        color: #f96565;
    }
    .text {
        font-size: 20px;
    }
}
</style>
