<template lang="html">
<span>{{result}}</span>
</template>

<script lang="ts">
export default {
    props: ['value'],
    computed: {
        result: function () {
            if (this.value === 'file_too_short') {
                return 'Файл слишком маленького размера';
            }
            if (this.value === 'face_not_found') {
                return 'Лицо не обнуружено';
            }
            if (this.value === 'too_many_faces') {
                return 'Найдено более одного лица';
            }
            if (this.value === 'corrupted_image') {
                return 'Изображение повреждено';
            }
            return 'Непредвиденная ошибка';
        }
    }
}
</script>

<style lang="scss" scoped>

</style>