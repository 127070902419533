<template lang="html">
<span v-bind:stub="isStub ? true : null">{{result}}</span>
</template>

<script lang="ts">
import {legalModeIntValue} from '../../core';

export default {
    props: ['day', 'month', 'year', 'stub', 'prefix'],
    computed: {
        isStub: function () {
            if (this.day == null) {
                return true;
            }
            if (this.month === null) {
                return true;
            }
            if (this.year === null) {
                return true;
            }
            return false;
        },
        result: function () {
            if (this.isStub) {
                if (this.stub === undefined) {
                    return 'Отсутствует';
                }
                return this.stub;
            }
            function pad(str, max) {
                str = str.toString();
                return str.length < max ? pad("0" + str, max) : str;
            }
            const day = this.day === legalModeIntValue ? '**' : pad(this.day, 2);
            const month = this.month === legalModeIntValue ? '**' : pad(this.month, 2);
            const year = this.year === legalModeIntValue ? '**' : pad(this.year, 2);
            let result = day + '.' + month + '.' + year;
            if (this.prefix) {
                result = this.prefix + result;
            }
            return result;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>