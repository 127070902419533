<template lang="html">
<div class="company-hit">
    <incident-company v-bind:company="company" v-bind:context="context"></incident-company>
    <div class="company-info">
        <div v-if="hasFlags">
            <div>Отметки</div>
            <div class="company-flags">
                <span v-if="company.flags.isPartnershipRisk === true" class="negative">Риск сотрудничества</span>
                <span v-if="company.flags.isDefaultRisk === true" class="negative">Риск неисполнения обязательств</span>
                <span v-if="company.flags.isCriminal === true" class="negative">Налоговые риски</span>
                <span v-if="company.flags.isTaxRisk === true" class="negative">Криминал</span>
                <span v-if="company.flags.isFraud === true" class="negative">Мошенничество</span>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import {Company, PersonContext} from '../../core';
import IncidentCompanyComponent from './IncidentCompany.vue';

export default {
    props: {
        company: {
            type: Company,
            required: true
        },
        context: {
            type: PersonContext,
            required: true
        },
        bindingReasons: {
            type: Array,
            required: false
        }
    },
    computed: {
        hasFlags() {
            let company:Company = this.company;
            let keys = Object.keys(company.flags);
            for (let i = 0; i < keys.length; i++) {
                if (company.flags[keys[i]] === true) {
                    return true;
                }
            }
            return false;
        },
    },
    components: {
        incidentCompany: IncidentCompanyComponent
    }
}
</script>

<style lang="scss" scoped>
.company-hit {
    display: flex;
    flex-direction: row;
    > *:nth-child(1) {
        width: 50%;
    }
    > *:nth-child(2) {
        display: flex;
        flex-direction: column;
        width: 50%;
        > * {
            display: flex;
            flex-direction: row;
            &:not(:last-child) {
                margin-bottom: 5px;
            }
            > *:nth-child(1) {
                width: 120px;
                color: #aaa;
                flex-shrink: 0;
                &::after {
                    content: ':';
                }
            }
        }
    }
}
.company-info {
    padding: 0 5px 0 0;
}
.company-flags {
    display: inline-block;
    *[important] {
        font-weight: bold;
    }
    > * {
        border-radius: 3px;
        font-size: 12px;
        line-height: 17px;
        &:not(:last-child) {
            margin-right: 5px;;
            &::after {
                content: ',';
            }
        }
    }
    .negative {
        color: #e91111;
    }
    .neutral {
        color: #26b3ff;
    }
}
</style>