<template lang="html">
<div class="person-media">
    <div v-if="person.fable !== null" class="person-fable">
        <pre>{{person.fable}}</pre>
    </div>
    <div v-if="person.comment !== null" class="person-fable">
        <pre>{{person.comment}}</pre>
    </div>
    <div v-if="person.files.length > 0" class="person-files">
        <div v-for="file in person.files">
            <span>Прикреплённый файл</span>
            <span v-if="isInLegalMode !== true || isMine"><a v-bind:href="getFileLink(file)" target="_blank">{{context.files.get(file).name || stubFilename}}</a></span>
            <span v-if="isInLegalMode === true && !isMine">{{context.files.get(file).name || stubFilename}}</span>
        </div>
    </div>
    <div v-if="photoRequests.length > 0" class="person-photos">
        <photo v-bind:size="100" v-bind:context="context" v-bind:requests="photoRequests" v-bind:index="index" v-for="request, index in photoRequests"></photo>
    </div>
</div>
</template>

<script lang="ts">
import {core} from '../../root';
import {RecordPerson, PersonContext, LoadPhotoRequest, LoadPersonPhotoRequest} from '../../core';
import PhotoComponent from './PhotoPreview.vue';

export default {
    props: {
        person: {
            type: RecordPerson,
            required: true
        },
        context: {
            type: PersonContext,
            required: true
        }
    },
    data() {
        return {
            stubFilename: '(Файл без имени)'
        }
    },
    computed: {
        isMine() {
            return this.person.authorId === core.auth.user.id;
        },
        isInLegalMode() {
            return core.isInLegalMode;
        },
        photoRequests() {
            let array = new Array<LoadPhotoRequest>();
            let person = this.person as RecordPerson;
            let context = this.context as PersonContext;
            for (let photoId of person.photos) {
                let request = new LoadPersonPhotoRequest(person.personId, photoId, context.searchId);
                array.push(request);
            }
            return array;
        }
    },
    methods: {
        getFileLink(fileId:string) : string {
            return core.api.getFileDownloadLink(this.person.personId, fileId, this.context.searchId);
        }
    },
    components: {
        photo: PhotoComponent
    }
}
</script>

<style lang="scss" scoped>
.person-fable {
    display:  flex;
    flex-direction: column;
    pre {
        white-space: pre-wrap;
        word-break: break-word;
        margin: 0;
    }
}
.person-media {
    display: flex;
    flex-direction: column;
    > *:not(:last-child) {
        margin-bottom: 10px;
    }
}
.person-files {
    display: flex;
    flex-direction: column;
    span:first-child {
        color: #aaa;
        &::after {
            content: ':';
        }
    }
    > *:not(:last-child) {
        margin-bottom: 5px;
    }
    > div {
        display: inline-block;
        > *:not(:last-child) {
            margin-right: 10px;
        }
    }
}
.person-photos {
    display:  flex;
    flex-direction: row;
    min-height: 30px;
    flex-wrap: wrap;
    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 105px;
        height: 105px;
        img {
            border-radius: 3px;
            cursor: pointer;
        }
    }
}
</style>