<template lang="html">
<div class="record">
    <spinner v-if="loading"></spinner>
    <div v-if="loading === false && !!record" class="container">
        <feed-item v-bind:item="record"></feed-item>
    </div>
    <div v-if="loading === false && isFailure === true" class="failure">
        <div class="fas fa-exclamation-triangle"></div>
        <div v-if="isGeneralError === true" class="text">Произошла ошибка</div>
        <div v-if="isNotFound === true" class="text">Запись не найдена</div>
        <div v-if="isForbidden === true" class="text">Доступ запрещён</div>
    </div>
</div>
</template>

<script lang="ts">
import {core} from '../../root';

import {RequestFeedRecord} from '../../core';

import FeedItemComponent from './FeedItem.vue';
import SpinnerComponeent from '../Spinner.vue';

export default {
    data: function () {
        return {
            record: null,
            loading: false,
            isFaulure: false,
            isNotFound: false,
            isForbidden: false,
            isGeneralError: false
        }
    },
    props: {
        id: {
            type: String,
            required: true
        },
        searchId: {
            type: String,
            required: false
        }
    },
    created: function () {
        return this.load(this.id);
    },
    watch: {
        'id': function () {
            this.load();
        }
    },
    methods: {
        async load() {
            const recordId = this.id;
            this.loading = true;
            this.isFailure = false;
            this.isNotFound = false;
            this.isForbidden = false;
            this.isGeneralError = false;
            try {
                let record = new RequestFeedRecord();
                record.recordId = recordId;
                record.personContext = await core.createPersonContextFromRecordId(recordId, this.searchId);
                if (this.id === recordId) {
                    this.record = record;
                }
            } catch (error) {
                if (error.status === 404) {
                    this.isNotFound = true;
                } else if (error.status === 403) {
                    this.isForbidden = true;
                } else {
                    this.isGeneralError = true;
                    console.log(error)
                }
                this.isFailure = true;
            } finally {
                if (this.id === recordId) {
                    this.loading = false;
                    this.$emit('load', recordId);
                }
            }
        }
    },
    components: {
        feedItem: FeedItemComponent,
        spinner: SpinnerComponeent
    }
}
</script>

<style lang="scss" scoped>
.record {
    display: felx;
    flex-direction: column;
}
.bound {
    display: flex;
    flex-direction: column;
    &[inactive] {
        pointer-events: none;
        opacity: 0.4;
    }
    .bound-header {
        $_height: 40px;
        display: flex;
        flex-direction: row;
        line-height: $_height;
        margin-top: $_height;
        height: $_height;
        font-size: 14px;
        > div {
            display: flex;
            flex-direction: row;
            flex-shrink: 0;
        }
        > div:nth-child(1) {
            padding-right: 20px;
            height: $_height;
        }
        > div:nth-child(2) {
            border-bottom: 1px solid #cccccc;
            flex-grow: 1;
            height: calc($_height / 2);
        }
    }
    .bound-filter {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        > *:not(:last-child) {
            margin-right: 10px;
        }
        .bound-badge {
            display: flex;
            flex-direction: row;
            padding: 3px 15px;
            border-radius: 3px;
            cursor: pointer;
            > *:not(:last-child) {
                margin-right: 5px;
            }
            &:not([active]) {
                color: #4dbb4d;
                background: #ffffff;
                border: 1px solid #4dbb4d;
            }
            &[active] {
                color: #ffffff;
                background: #4dbb4d;
                border: 1px solid #4dbb4d;
            }
        }
    }
    .bound-records {
        > *:not(:last-child) {
            margin-bottom: 30px;
        }
    }
}
.failure {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;;
    padding: 50px 0;
    > *:not(:last-child) {
        margin-bottom: 50px;
    }
    .fas {
        font-size: 50px;
        &.fa-exclamation-triangle {
            color: #f96565;
        }
    }
    .text {
        font-size: 20px;
    }
    .reason {
        font-size: 15px;
    }
}
</style>