<template lang="html">
<div>
    <record-feed-record v-if="item.type === 'record' && isHidden !== true" v-bind:record="item" v-bind:context="item.personContext"></record-feed-record>
    <person-feed-record v-if="item.type === 'person' && isHidden !== true" v-bind:record="item" v-bind:context="item.personContext"></person-feed-record>
    <company-feed-record v-if="item.type === 'company' && isHidden !== true" v-bind:record="item" v-bind:context="item.personContext"></company-feed-record>
    <notification v-if="item.type === 'notification' && isHidden !== true" v-bind:record="item" v-bind:context="item.personContext"></notification>
    <div v-if="isHidden === true" class="access-denied">
        <div>У Вас нет необходимых прав доступа для просмотра этого сегмента данных</div>
    </div>
</div>
</template>

<script lang="ts">
import RequestFeedRecordComponent from './RequestFeedRecord.vue';
import PersonFeedRecordComponent from './PersonFeedRecord.vue';
import CompanyFeedRecordComponent from './CompanyFeedRecord.vue';
import FeedNotificationRecordComponent from './FeedNotification.vue';
import {CompanyFeedRecord, FeedNotification, FeedRecord, PersonFeedRecord, RequestFeedRecord} from '../../core';

export default {
    props: {
        item: {
            type: [
                PersonFeedRecord,
                RequestFeedRecord,
                CompanyFeedRecord,
                FeedNotification
            ],
            required: true
        }
    },
    computed: {
        isHidden() {
            let item = this.item as FeedRecord;
            if (item.type !== "person") {
                return false;
            }
            let personItem = item as PersonFeedRecord;
            let hiddenIndex = item.personContext.hiddenPersons.indexOf(personItem.personId);
            if (hiddenIndex === -1) {
                return false;
            }
            return true;
        }
    },
    components: {
        recordFeedRecord: RequestFeedRecordComponent,
        personFeedRecord: PersonFeedRecordComponent,
        companyFeedRecord: CompanyFeedRecordComponent,
        notification: FeedNotificationRecordComponent
    }
}
</script>

<style lang="scss" scoped>
.access-denied {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ccc;
    text-transform: uppercase;
    text-align: center;
    padding: 20px;
    border: 1px solid #ccc
}
</style>