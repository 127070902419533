<template lang="html">
<div class="root">
    <spinner v-if="loading"></spinner>
    <div v-if="loading === false && isFailure === true" class="failure">
        <div class="fa-solid fa-ban"></div>
        <div v-if="isGeneralError === true" class="text">Произошла ошибка</div>
        <div v-if="isNotFound === true" class="text">Запись не найдена</div>
        <div v-if="isForbidden === true" class="text">Доступ запрещён</div>
    </div>
    <div v-if="loading === false && !!resolvedPerson">
        <div class="person">
            <person-meta v-bind:person="resolvedPerson" v-bind:context="context"></person-meta>
            <person-hit v-bind:person="resolvedPerson" v-bind:context="context"></person-hit>
            <div class="person-separator"></div>
            <person-watch v-bind:person="resolvedPerson" v-bind:context="context"></person-watch>
            <div v-if="hasMedia" class="person-separator"></div>
            <person-media v-if="hasMedia" v-bind:person="resolvedPerson" v-bind:context="context"></person-media>
        </div>
        <person-bound v-if="noBound !== true" v-bind:id="id" v-bind:search-id="searchId" v-on:loaded="onBindingsLoaded"></person-bound>
    </div>
</div>
</template>

<script lang="ts">
import {core} from '../../root';

import SpinnerComponeent from '../Spinner.vue';
import PersonBoundComponent from './PersonBound.vue';
import PersonHitComponent from './PersonHit.vue';
import PersonMetaComponent from './PersonMeta.vue';
import PersonMediaComponent from './PersonMedia.vue';
import PersonWatchComponent from './PersonWatch.vue';
import { Util } from '../../util';
import { PersonContext, RecordPerson } from '../../core';

export default {
    data: function () {
        return {
            person: null,
            record: null,
            loading: false,
            context: null,
            isFaulure: false,
            isNotFound: false,
            isForbidden: false,
            isGeneralError: false
        }
    },
    props: {
        id: {
            type: String,
            required: true
        },
        searchId: {
            type: String,
            required: false
        },
        section: {
            type: String,
            required: false
        },
        noBound: {
            type: Boolean,
            required: false
        }
    },
    created: function () {
        return this.load(this.id);
    },
    watch: {
        'id': function () {
            this.load();
        }
    },
    computed: {
        hasMedia() {
            let person = this.resolvedPerson as RecordPerson;
            if (!person) {
                return false;
            }
            return Util.getHasMedia(person);
        },
        resolvedPerson() {
            let context = this.context as PersonContext;
            if (!context) {
                return null;
            }
            return context.persons.get(this.id);
        }
    },
    methods: {
        async load() {
            let personId = this.id;
            this.loading = true;
            this.isFailure = false;
            this.isNotFound = false;
            this.isForbidden = false;
            this.isGeneralError = false;
            try {
                let context = await core.createPersonContextFromPersonId(personId, this.searchId);
                if (this.id === personId) {
                    this.context = context;
                }
            } catch (error) {
                if (error.status === 404) {
                    this.isNotFound = true;
                } else if (error.status === 403) {
                    this.isForbidden = true;
                } else {
                    this.isGeneralError = true;
                    console.log(error)
                }
                this.isFailure = true;
            } finally {
                if (this.id === personId) {
                    this.loading = false;
                    this.$emit('load', personId);
                }
            }
        },
        onBindingsLoaded(bindingRootElemenmt) {
            if (this.section !== 'bound') {
                return;
            }
            setTimeout(() => {
                window.scrollTo(0, bindingRootElemenmt.offsetTop)
            }, 10);
        }
    },
    components: {
        personBound: PersonBoundComponent,
        personHit: PersonHitComponent,
        personMedia: PersonMediaComponent,
        personWatch: PersonWatchComponent,
        personMeta: PersonMetaComponent,
        spinner: SpinnerComponeent
    }
}
</script>

<style lang="scss" scoped>
.root {
    display: felx;
    flex-direction: column;
    > *:not(:last-child) {
        margin-bottom: 10px;
    }
}
.person {
    display: flex;
    flex-direction: column;
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding: 10px 10px;
    > *:not(:last-child) {
        margin-bottom: 10px;
    }
    .person-separator {
        border-bottom: 1px solid #ededed;
    }
}
.failure {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;;
    padding: 50px 0;
    > *:not(:last-child) {
        margin-bottom: 50px;
    }
    .fa-ban {
        font-size: 50px;
        color: #ff5151;
    }
    .text {
        font-size: 20px;
    }
    .reason {
        font-size: 15px;
    }
}
</style>