<template lang="html">
<div>
    <div class="block">
        <div>
            <div class="block-content flags">
                <flag v-bind:flags="context.record.flags" v-bind:name="'isFraud'" v-bind:title="'Мошенник'"></flag>
                <flag v-bind:flags="context.record.flags" v-bind:name="'isBankFraud'" v-bind:title="'Банковский мошенник'"></flag>
                <flag v-bind:flags="context.record.flags" v-bind:name="'isMicrofinanceFraud'" v-bind:title="'Мошенник МФО'"></flag>
                <flag v-bind:flags="context.record.flags" v-bind:name="'isInsuranceFraud'" v-bind:title="'Страховое мошенничество'"></flag>
                <flag v-bind:flags="context.record.flags" v-bind:name="'isMoneyLaundering'" v-bind:title="'Обналичивание'"></flag>
                <flag v-bind:flags="context.record.flags" v-bind:name="'isCargoThief'" v-bind:title="'Кража грузов'"></flag>
                <flag v-bind:flags="context.record.flags" v-bind:name="'isShopThief'" v-bind:title="'Магазинный вор'"></flag>
                <flag v-bind:flags="context.record.flags" v-bind:name="'isEmployRisk'" v-bind:title="'Риск при найме'"></flag>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import {RequestContext} from '../../core';
import RecordFlagComponent from './RecordFlag.vue';

export default {
    props: {
        context: {
            type: RequestContext,
            required: true
        }
    },
    components: {
        flag: RecordFlagComponent
    },
    methods: {
        onLeavingFable() {
            if (!this.context.person.fable) {
                this.additionalFable = false;
            }
        },
        openFableTextArea() {
            this.additionalFable = true;
            setTimeout(() => {
                this.$refs.fableTextArea.focus();
            }, 10);
        }
    }
}
</script>

<style lang="scss" scoped>
.block {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: #ffffff;
    .block-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        span {
            margin-top: -10px;
            line-height: 20px;
            height: 20px;
            background: #ffffff;
            padding: 0 10px;
            display: flex;
            flex-direction: row;
            border: 1px solid #cccccc;
            margin-left: 10px;
            border-radius: 3px;
            color: #797979;
        }
    }
    .block-content {
        &.flags {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 0 10px;
            > * {
                width: 25%;
            }
        }
    }
}
.checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
     user-select: none;
    > *:not(:last-child) {
        margin-right: 10px;
    }
}

</style>