import $ from "jquery";
import {Util} from '../util';

export default {
    mounted(el:HTMLElement, binding:any) {
        let id = Util.generateRandomId();
        el.setAttribute('paste-file-id', id);
        $(document).on('paste.pasteFile__' + id, (event:any) => {
            event = event.originalEvent as ClipboardEvent;
            if (!event.clipboardData) {
                return;
            }
            let files = new Array<File>();
            let items = event.clipboardData.items as Array<DataTransferItem>;
            for (let item of items) {
                if (item.kind !== 'file') {
                    continue;
                }
                let blob = item.getAsFile();
                if (blob === null) {
                    continue;
                }
                files.push(blob);
            }
            binding.value(files);
        });
    },
    unmounted(el:HTMLElement) {
        let id = el.getAttribute('paste-file-id');
        $(document).off('paste.pasteFile__' + id);
    }
};