<template lang="html">
    <div class="person-hit">
        <div class="person-header">
            <div v-if="person.timestamp" class="date">
                <i class="fa-regular fa-clock"></i>
                <div>Дата внесения &mdash;</div>
                <div>
                    <date v-bind:value="person.timestamp"></date>
                </div>
            </div>
            <div v-if="person.dateTime" class="date">
                <i class="fa-regular fa-clock"></i>
                <div>Дата события &mdash;</div>
                <div>
                    <date v-bind:value="person.dateTime" v-bind:noTime="true"></date>
                </div>
            </div>
            <div class="person-header-separator"></div>
            <div v-if="member" class="user" title="Автор инцидента">
                <i class="fa-solid fa-user-tie"></i>
                <humanId v-if="member" v-bind:value="member.humanId" v-bind:prefix="'U'"></humanId>
            </div>
        </div>
        <div class="person-content">
            <incident-person v-bind:person="person" v-bind:context="context"></incident-person>
            <div class="person-info">
                <div v-if="hasPassport && !isInLegalMode">
                    <div>Паспорт</div>
                    <div class="passport">
                        <span>
                            <string v-bind:value="person.fields.passportSerial" v-bind:stub="'Отсутствует'"></string
                            >&nbsp;<string
                                v-bind:value="person.fields.passportNumber"
                                v-bind:stub="'Отсутствует'"
                            ></string>
                        </span>
                        <span>
                            <person-date
                                v-bind:day="person.fields.passportIssueDay"
                                v-bind:month="person.fields.passportIssueMonth"
                                v-bind:year="person.fields.passportIssueYear"
                                v-bind:prefix="'Выдан: '"
                                v-bind:stub="null"
                            ></person-date>
                        </span>
                    </div>
                </div>
                <div v-if="hasContacs">
                    <div>Контакты</div>
                    <div class="person-contacts">
                        <phone v-bind:value="value" v-for="value in person.contacts.phones"></phone>
                        <email v-bind:value="value" v-for="value in person.contacts.emails"></email>
                    </div>
                </div>
                <div v-if="hasFlags">
                    <div>Отметки</div>
                    <div class="person-flags">
                        <span v-if="person.flags.isFraud === true" class="negative">Мошенник</span>
                        <span v-if="person.flags.isBankFraud === true" class="negative">Банковский мошенник</span>
                        <span v-if="person.flags.isMicrofinanceFraud === true" class="negative">Мошенник МФО</span>
                        <span v-if="person.flags.isInsuranceFraud === true" class="negative"
                            >Страховое мошенничество</span
                        >
                        <span v-if="person.flags.isMoneyLaundering === true" class="negative">Обналичивание</span>
                        <span v-if="person.flags.isCargoThief === true" class="negative">Кража грузов</span>
                        <span v-if="person.flags.isShopThief === true" class="negative">Магазинный вор</span>
                        <span v-if="person.flags.isEmployRisk === true" class="negative">Риск при найме</span>
                        <span v-if="person.flags.isRealData === true" class="negative" important="1"
                            >Подлинные данные</span
                        >
                        <span v-if="person.flags.isCompromised === true" class="negative" important="1"
                            >Скомпрометированные данные</span
                        >
                    </div>
                </div>
                <div v-if="!!person.comment">
                    <div>Комментарий</div>
                    <div>
                        <string v-bind:value="person.comment" v-bind:max-length="20"></string>
                    </div>
                </div>
                <div v-if="incidentRange">
                    <div>Инциденты</div>
                    <div>
                        <span>Первый &mdash;</span>
                        <date v-bind:value="incidentRange.min" v-bind:no-time="true"></date>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { core, bindingReason } from "../../root";
import StringComponent from "../String.vue";
import DateComponent from "../Date.vue";
import RequestFeedData from "./RequestFeedDate.vue";
import { RecordPerson, PersonContext } from "../../core";
import IncidentPersonComponent from "./IncidentPerson.vue";
import PhoneComponent from "../common/Phone.vue";
import EmailComponent from "../common/Email.vue";
import HumanIdComponent from "../common/HumanId.vue";

export default {
    props: {
        person: {
            type: RecordPerson,
            required: true
        },
        context: {
            type: PersonContext,
            required: true
        },
        bindingReasons: {
            type: Array,
            required: false
        }
    },
    computed: {
        hasPassport() {
            let person: RecordPerson = this.person;
            if (!!person.fields.passportSerial) {
                return true;
            }
            if (!!person.fields.passportNumber) {
                return true;
            }
            return false;
        },
        hasContacs() {
            let person: RecordPerson = this.person;
            if (!person.contacts) {
                return false;
            }
            if (!!person.contacts.phones && person.contacts.phones.length > 0) {
                return true;
            }
            if (!!person.contacts.emails && person.contacts.emails.length > 0) {
                return true;
            }
            return false;
        },
        hasFlags() {
            let person: RecordPerson = this.person;
            let keys = Object.keys(person.flags);
            for (let i = 0; i < keys.length; i++) {
                if (person.flags[keys[i]] === true) {
                    return true;
                }
            }
            return false;
        },
        hasBindingReasons() {
            if (this.bindingReasons instanceof Array === false) {
                return false;
            }
            let bindingReasons = this.bindingReasons as Array<string>;
            if (bindingReasons.length === 0) {
                return false;
            }
            return true;
        },
        isInLegalMode() {
            return core.isInLegalMode;
        },
        incidentRange() {
            let context = this.context as PersonContext;
            let person = this.person as RecordPerson;
            let has = context.incidentRanges.has(person.personId);
            if (has !== true) {
                return null;
            }
            return context.incidentRanges.get(person.personId);
        },
        member() {
            let context = this.context as PersonContext;
            let person = this.person as RecordPerson;
            return context.users.get(person.authorId);
        }
    },
    components: {
        date: DateComponent,
        string: StringComponent,
        personDate: RequestFeedData,
        incidentPerson: IncidentPersonComponent,
        humanId: HumanIdComponent,
        phone: PhoneComponent,
        email: EmailComponent
    },
    directives: {
        bindingReason: {
            mounted(element: HTMLElement, binding) {
                let reasonDescription = bindingReason[binding.value];
                element.innerHTML = reasonDescription;
                element.setAttribute("title", "Причина внесения в похожие — " + reasonDescription);
            },
            updated(element: HTMLElement, binding) {
                let reasonDescription = bindingReason[binding.value];
                element.innerHTML = reasonDescription;
                element.setAttribute("title", "Причина внесения в похожие — " + reasonDescription);
            },
            unmounted(element: HTMLElement) {
                element.innerHTML = "";
                element.removeAttribute("title");
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.person-hit {
    display: flex;
    flex-direction: column;
    > *:not(:last-child) {
        margin-bottom: 10px;
    }
}
.person-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    line-height: 20px;
    font-weight: bold;
    font-size: 10px;
    > *:not(:last-child):not(.person-header-separator) {
        margin-right: 10px;
    }
    .person-header-separator {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
    }
    > *:not(.person-header-separator) {
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        padding: 0 10px;
        display: flex;
        flex-shrink: 0;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        > *:not(:last-child) {
            margin-right: 5px;
        }
    }
}
.person-content {
    display: flex;
    flex-direction: row;
    > *:nth-child(1) {
        width: 50%;
    }
    > *:nth-child(2) {
        display: flex;
        flex-direction: column;
        width: 50%;
        > * {
            display: flex;
            flex-direction: row;
            > *:nth-child(1) {
                width: 100px;
                color: #aaa;
                flex-shrink: 0;
            }
        }
    }
}
.passport {
    > span {
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
}
.person-flags {
    display: inline-block;
    *[important] {
        font-weight: bold;
    }
    > * {
        border-radius: 3px;
        font-size: 12px;
        &:not(:last-child) {
            margin-right: 5px;
            &::after {
                content: ",";
            }
        }
    }
    .negative {
        color: #e91111;
    }
    .neutral {
        color: #26b3ff;
    }
}
.person-info {
    padding: 0 5px 0 0;
    > div {
        line-height: 20px;
    }
}
.person-contacts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > *:not(:last-child) {
        margin-right: 5px;
        &::after {
            content: ",";
        }
    }
}
</style>
