<template lang="html">
<div class="company-feed-record">
    <incident-company v-if="resolvedCompany !== null" v-bind:company="resolvedCompany" v-bind:context="context"></incident-company>
</div>
</template>

<script lang="ts">
import {Company, CompanyFeedRecord, PersonContext} from '../../core';
import IncidentCompanyComponent from './IncidentCompany.vue';

export default {
    props: {
        record: {
            type: CompanyFeedRecord,
            required: true
        },
        context: {
            type: PersonContext,
            required: true
        }
    },
    computed: {
        resolvedCompany() : Company {
            let context = this.context as PersonContext;
            let record = this.record as CompanyFeedRecord;
            let has = context.companies.has(record.companyId);
            if (has) {
                return context.companies.get(record.companyId);
            }
            return null;
        }
    },
    components: {
        incidentCompany: IncidentCompanyComponent
    }
}
</script>

<style lang="scss" scoped>

</style>