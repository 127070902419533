<template lang="html">
<div class="person-hit-record">
    <div class="person-hit-header">Персона, установленная на мониторинг</div>
    <person-hit v-bind:person="originalPerson" v-bind:context="context"></person-hit>
    <div v-if="matchedPersons.length === 1" class="person-hit-header">Совпавшая персона</div>
    <div v-if="matchedPersons.length > 1" class="person-hit-header">Совпавшие персоны</div>
    <person-hit v-bind:person="person" v-bind:context="context" v-for="person in matchedPersons"></person-hit>
</div>
</template>

<script lang="ts">
import {PersonContext, RecordPerson} from '../../core'
import PersonHitComponent from './PersonHit.vue';

export default {
    props: {
        hitId: {
            type: String,
            required: true
        },
        context: {
            type: PersonContext,
            required: true
        }
    },
    computed: {
        originalPerson() {
            let context = this.context as PersonContext;
            let hit = context.watchHits.get(this.hitId);
            let person = context.persons.get(hit.personId);
            return person;
        },
        matchedPersons() {
            let context = this.context as PersonContext;
            let hit = context.watchHits.get(this.hitId);
            let result = new Array<RecordPerson>();
            hit.hits.forEach(_ => {
                if (_.objectType !== 'person') {
                    return;
                }
                let person = context.persons.get(_.objectId);
                result.push(person);
            });
            return result;
        }
    },
    components: {
        personHit: PersonHitComponent
    }
}
</script>

<style lang="scss" scoped>
.person-hit-record {
    display: flex;
    flex-direction: column;
    padding: 10px;
    > *:not(:last-child) {
        margin-bottom: 20px;
    }
}
.person-hit-header {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ebebeb;
    text-transform: uppercase;
}
</style>