<template lang="html">
    <div class="record-form">
        <div v-if="context.isInProcess === false && context.isInFailure === false">
            <div v-if="context.stage === 1" class="record-form-stage">
                <div class="record-form-header">
                    <div class="record-date-time">
                        <div>Дата и время инцидента</div>
                        <div class="record-date-time-input">
                            <DatePicker
                                v-model="context.record.dateTime"
                                :locale="'ru-RU'"
                                :masks="masks"
                                :popover="PopoverOptions"
                            >
                                <template v-slot="{ inputValue, inputEvents }">
                                    <input
                                        style="border: 0"
                                        :value="inputValue"
                                        v-on="inputEvents"
                                        placeholder="дд.мм.гггг"
                                        :visibility="'focus'"
                                        v-bind:disabled="isFormDisabled ? true : null"
                                        @focus="selectAllValue"
                                    />
                                </template>
                            </DatePicker>
                        </div>
                    </div>
                    <div class="record-form-buttons">
                        <div v-on:click="remove" class="link delete-link">
                            Удалить запись
                        </div>
                        <submit-button
                            v-bind:on-click="commit"
                            v-bind:message="'Сохранить'"
                            v-bind:context="context"
                        ></submit-button>
                    </div>
                </div>
                <div class="step-header">Основная информация об инциденте</div>
                <div class="record-comment">
                    <textarea
                        v-model="context.record.comment"
                        type="text"
                        rows="3"
                        placeholder="Фабула инцидента"
                    ></textarea>
                </div>
                <div class="record-flags">
                    <record-form-flags v-bind:context="context"></record-form-flags>
                </div>
                <div class="step-header">Сумма ущерба</div>
                <div class="record-loss">
                    <input
                        v-model="context.record.loss"
                        type="number"
                        min="0"
                        placeholder="Сумма ущерба в результате инцидента"
                    />
                </div>
                <div class="step-header">Место происшествия</div>
                <div class="record-location">
                    <div v-if="resolvedLocationString">{{ resolvedLocationString }}</div>
                    <div v-if="!resolvedLocationString" stub="1">
                        Местоположение не выбрано
                    </div>
                    <div v-on:click="changeLocation" class="link">
                        Изменить местоположение
                    </div>
                </div>
                <!--  -->
                <div class="step-header">Участники инцидента</div>
                <div class="record-form-navbar">
                    <div
                        v-on:click="switchCurrent(person)"
                        v-bind:active="person === context.current ? true : null"
                        v-for="(person, index) in context.persons"
                    >
                        Участник&nbsp;<b>{{ index + 1 }}</b>
                    </div>
                    <div v-on:click="addPerson">Добавить участника</div>
                    <div v-on:click="addCompromised">
                        Добавить скомпрометированные данные
                    </div>
                </div>
                <div v-if="context.current === null" class="record-form-empty">
                    <div>Ни одного участника не добавлено</div>
                </div>
                <div v-if="context.current && !context.current.isHidden" class="record-form-person">
                    <div class="record-form-person-header">
                        <div class="record-form-person-id">
                            <span
                                >Анкета <b>{{ context.persons.indexOf(context.current) + 1 }}</b> из
                                <b>{{ context.persons.length }}</b></span
                            >
                        </div>
                        <div class="record-form-important-flags">
                            <div
                                v-bind:active="context.current.person.flags.isRealData === true ? true : null"
                                class="flag-real-data"
                            >
                                <flag
                                    v-bind:disabled="
                                        canSetRealDataFlag === false || canEditCurrentPerson !== true ? true : null
                                    "
                                    v-bind:flags="context.current.person.flags"
                                    v-bind:name="'isRealData'"
                                    v-bind:title="'Подлинные данные'"
                                ></flag>
                            </div>
                            <div
                                v-bind:active="context.current.person.flags.isCompromised === true ? true : null"
                                class="flag-real-data"
                            >
                                <flag
                                    v-bind:disabled="canEditCurrentPerson !== true ? true : null"
                                    v-bind:flags="context.current.person.flags"
                                    v-bind:name="'isCompromised'"
                                    v-bind:title="'Скомпрометированные данные'"
                                ></flag>
                            </div>
                        </div>
                        <div class="separator"></div>
                        <div>
                            <span v-on:click="dropPerson(context.current)" class="link">Удалить анкету</span>
                        </div>
                    </div>
                    <change-person-data-form :context="context.current" />
                </div>
                <div v-if="context.current && context.current.isHidden" class="record-form-hidden">
                    <div>
                        У Вас нет необходимых прав доступа для просмотра этого сегмента данных
                    </div>
                </div>
                <!--  -->
                <div class="step-header">Организации, связанные с инцидентом</div>
                <spinner v-if="isCompanyLoading === true"></spinner>
                <div v-if="isCompanyLoading === false" class="record-form-navbar">
                    <div
                        v-on:click="switchCurrentCompany(company)"
                        v-bind:active="company === selectedCompany ? true : null"
                        v-for="(company, index) in companiesArray"
                    >
                        Организация&nbsp;<b>{{ index + 1 }}</b>
                    </div>
                    <div v-on:click="addCompany">Добавить организацию</div>
                </div>
                <div v-if="companiesArray.length <= 0 && isCompanyLoading === false" class="record-form-empty">
                    <div>Ни одной организации не добавлено</div>
                </div>
                <div v-if="companiesArray.length > 0 && isCompanyLoading === false" class="record-form-person">
                    <div class="record-form-person-header">
                        <div class="record-form-person-id">
                            <span
                                >Анкета <b>{{ companiesArray.indexOf(selectedCompany) + 1 }}</b> из
                                <b>{{ companiesArray.length }}</b></span
                            >
                        </div>
                        <div class="separator"></div>
                        <div>
                            <span v-on:click="dropCompany(selectedCompany)" class="link">Удалить анкету</span>
                        </div>
                    </div>
                    <div class="record-form-important-flags">
                        <div class="company-field">
                            <div>ИНН</div>
                            <input type="text" placeholder="ИНН Организации" v-model="selectedCompany.inn" />
                        </div>
                        <div class="block-content flags">
                            <flag
                                v-bind:disabled="
                                    canSetRealDataFlag === false || canEditCurrentPerson !== true ? true : null
                                "
                                v-bind:flags="selectedCompany.flags"
                                v-bind:name="'isPartnershipRisk'"
                                v-bind:title="'Риск сотрудничества'"
                            ></flag>
                            <flag
                                v-bind:disabled="canEditCurrentPerson !== true ? true : null"
                                v-bind:flags="selectedCompany.flags"
                                v-bind:name="'isDefaultRisk'"
                                v-bind:title="'Риск неисполнения обязательств'"
                            ></flag>
                            <flag
                                v-bind:disabled="canEditCurrentPerson !== true ? true : null"
                                v-bind:flags="selectedCompany.flags"
                                v-bind:name="'isTaxRisk'"
                                v-bind:title="'Налоговые риски'"
                            ></flag>
                            <flag
                                v-bind:disabled="canEditCurrentPerson !== true ? true : null"
                                v-bind:flags="selectedCompany.flags"
                                v-bind:name="'isCriminal'"
                                v-bind:title="'Криминал'"
                            ></flag>
                            <flag
                                v-bind:disabled="canEditCurrentPerson !== true ? true : null"
                                v-bind:flags="selectedCompany.flags"
                                v-bind:name="'isFraud'"
                                v-bind:title="'Мошенничество'"
                            ></flag>
                        </div>
                    </div>
                </div>
                <!--  -->
                <div class="step-header">
                    Дополнительные фото и файлы, относящиеся к инциденту
                </div>
                <div class="record-form-media">
                    <record-form-media v-bind:context="context"></record-form-media>
                </div>
            </div>
            <div v-if="context.stage === 2" class="record-form-stage">
                <recordConfirm v-bind:context="context"></recordConfirm>
            </div>
            <div v-if="context.stage === 3" class="record-form-stage">
                <recordWaiting v-bind:context="context"></recordWaiting>
            </div>
        </div>
        <div v-if="context.isInFailure === true" class="record-form-error">
            <div class="fas fa-exclamation-triangle"></div>
            <div class="text">Произошла ошибка</div>
            <div v-if="context.failureCode === 'photo_too_big'" class="text">
                Фотография слишком большая
            </div>
        </div>
        <spinner v-if="context.isInProcess === true && context.isInFailure === false"></spinner>
    </div>
    <toast-notification ref="toast"></toast-notification>
</template>

<script lang="ts">
import { core, modal } from "../../root";
import RecordFormMediaComponent from "./RecordFormMedia.vue";
import RecordFormFlagsComponent from "./RecordFormFlags.vue";
import RecordFlagComponent from "./RecordFlag.vue";
import RecordConfirm from "./RecordConfirm.vue";
import RecordWaiting from "./RecordWaiting.vue";
import DateComponent from "../Date.vue";
import Spinner from "../Spinner.vue";
import RecordFormSubmitButtonComponent from "./RequestFormButton.vue";
import ResolveOrCreatePersonComponent from "./ResolveOrCreatePerson.vue";
import AddressResolveComponent from "../common/AddressResolve.vue";
import ChangePersonDataForm from "./ChangePersonDataForm.vue";
import ToastNotificationComponent from "../ToastNotification.vue";
import { DatePicker, Calendar } from "v-calendar";

import {
    RequestContext,
    RecordPhotoContext,
    RecordPersonContext,
    LoadPersonPhotoRequest,
    LoadRecordPhotoRequest,
    ResolvedCoordinates,
    Company
} from "../../core";

async function loadRecordPhoto(recordId: string, id: string) {
    let result = new RecordPhotoContext();
    let request = new LoadRecordPhotoRequest(recordId, id);
    result.id = id;
    result.content = await core.loadPhotoSafely(request);
    return result;
}
async function loadPersonPhoto(personId: string, id: string) {
    let result = new RecordPhotoContext();
    let request = new LoadPersonPhotoRequest(personId, id);
    result.id = id;
    result.content = await core.loadPhotoSafely(request);
    return result;
}
async function loadRecordFile(recordId: string, fileId: string): Promise<RecordPhotoContext> {
    const result = new RecordPhotoContext();
    result.id = fileId;
    result.name = await core.api.getRecordFileName(recordId, fileId);
    return result;
}
async function loadPersonFile(personId: string, fileId: string): Promise<RecordPhotoContext> {
    const result = new RecordPhotoContext();
    result.id = fileId;
    result.name = await core.api.getFileName(personId, fileId);
    return result;
}
function getCanEdit(context: RequestContext): boolean {
    if (context.record.status === 1) {
        return true;
    }
    const adminIndex = core.auth.user.roles.indexOf("admin");
    if (adminIndex !== -1) {
        return true;
    }
    const editorIndex = core.auth.user.roles.indexOf("editor");
    if (editorIndex !== -1) {
        return true;
    }
    let localEditorIndex = core.auth.user.roles.indexOf("editor_local");
    if (localEditorIndex !== -1 && core.auth.user.id === context.record.authorId) {
        return true;
    }
    return false;
}
async function loadPerson(context: RequestContext, personId: string): Promise<RecordPersonContext> {
    let currentDateTime = await core.api.getCurrentDateTime();
    let personContext = new RecordPersonContext();
    try {
        let person = await core.api.getPerson(personId);
        personContext.person = person;
        person.dateTime = person.dateTime || currentDateTime;
        let loadPhotos = person.photos.map(_ => loadPersonPhoto(personId, _));
        let loadFiles = person.files.map(_ => loadPersonFile(personId, _));
        personContext.photos = await Promise.all(loadPhotos);
        personContext.files = await Promise.all(loadFiles);
    } catch (error) {
        if (error.status === 403) {
            personContext.isHidden = true;
        } else {
            throw error;
        }
    }
    context.persons.push(personContext);
    return personContext;
}
async function loadCompany(companyId: string): Promise<Company> {
    return await core.api.getCompany(companyId);
}
function getHasRole(name: string): boolean {
    return core.auth.user.roles.indexOf(name) !== -1;
}
export default {
    data: function() {
        return {
            context: new RequestContext(),
            companies: null,
            companiesArray: new Array(),
            selectedCompany: null,
            droppedCompanies: null,
            isCompanyLoading: false,
            isFormDisabled: false,
            masks: {
                input: "DD.MM.YYYY"
            },
            PopoverOptions: {
                visibility: "focus",
                placement: "bottom"
            }
        };
    },
    props: {
        id: {
            type: String,
            required: true
        },
        stage: {
            type: String,
            required: false
        }
    },
    created: async function() {
        this.context.isInProcess = true;
        if (this.stage) {
            this.context.stage = parseInt(this.stage);
        }
        await this.load();
    },
    computed: {
        canEdit() {
            return getCanEdit(this.context);
        },
        canSetRealDataFlag() {
            const isAdmin = getHasRole("admin");
            if (isAdmin) {
                return true;
            }
            const isEditor = getHasRole("editor");
            if (isEditor) {
                return true;
            }
            const isFlagRealData = getHasRole("flag_real_data");
            if (isFlagRealData) {
                return true;
            }
            return false;
        },
        canEditCurrentPerson(): boolean {
            let context = this.context as RequestContext;
            let current = context.current;
            if (!current) {
                return false;
            }
            return core.getCanEditPerson(current.person);
        },
        resolvedLocationString(): string {
            let context = this.context as RequestContext;
            if (context.resolvedLocation === null) {
                return null;
            }
            return context.resolvedLocation.location.address;
        }
    },
    watch: {
        "context.stage": function(value) {
            if (this.context.record) {
                window.location.hash = "#/form/" + this.context.record.recordId + "/" + value;
            }
        },
        stage: function(value) {
            if (this.canEdit === false) {
                window.location.hash = "#/form/" + this.context.record.recordId + "/6";
            } else {
                this.context.stage = parseInt(value);
            }
        },
        id: function() {
            this.load();
        }
    },
    components: {
        recordFormFlags: RecordFormFlagsComponent,
        recordFormMedia: RecordFormMediaComponent,
        recordConfirm: RecordConfirm,
        recordWaiting: RecordWaiting,
        flag: RecordFlagComponent,
        dateTime: DateComponent,
        spinner: Spinner,
        submitButton: RecordFormSubmitButtonComponent,
        changePersonDataForm: ChangePersonDataForm,
        toastNotification: ToastNotificationComponent,
        Calendar,
        DatePicker
    },
    methods: {
        async remove() {
            this.context.isInProcess = true;
            await core.api.removeRecord(this.context.record.recordId);
            window.location.href = "#";
        },
        changeStage: function(stage: number) {
            if (this.canEdit === false) {
                return;
            }
            if (this.context.stage !== stage) {
                this.context.stage = stage;
            }
        },
        switchCurrent(person: RecordPersonContext) {
            let context = this.context as RequestContext;
            context.current = person;
        },
        switchCurrentCompany(company: Company) {
            this.selectedCompany = company;
        },
        changeLocation() {
            let context = this.context as RequestContext;
            let handle = modal.open(AddressResolveComponent, {
                dialog: true,
                events: {
                    resolved: async (resolved: ResolvedCoordinates) => {
                        context.resolvedLocation = resolved;
                        context.record.location.lat = resolved.location.lat;
                        context.record.location.lng = resolved.location.lng;
                        context.record.location.geoHash = resolved.geoHash;
                        handle();
                    }
                }
            });
        },
        addPerson() {
            let context = this.context as RequestContext;
            let handle = modal.open(ResolveOrCreatePersonComponent, {
                data: {
                    recordId: context.record.recordId
                },
                dialog: true,
                events: {
                    resolved: async (personId: string) => {
                        context.isInProcess = true;
                        try {
                            await core.api.bindPerson(context.record.recordId, personId);
                            let personContext = await loadPerson(context, personId);
                            this.switchCurrent(personContext);
                        } finally {
                            context.isInProcess = false;
                        }
                        handle();
                    }
                }
            });
        },
        async addCompany() {
            this.isCompanyLoading = true;
            try {
                const company = await core.createCompanyFromIncident(this.context.record.recordId);
                const companyContext = company;
                this.companiesArray.push(companyContext);
                this.selectedCompany = companyContext;
            } catch (error) {
                this.$refs.toast.addToast("Ошибка при создании организации", "error");
                console.error(error);
            } finally {
                this.isCompanyLoading = false;
            }
        },
        async addCompromised() {
            let context = this.context as RequestContext;
            if (context.isInProcess) {
                return;
            }
            context.isInProcess = true;
            try {
                let personId = await core.api.createPerson(context.record.recordId);
                await core.api.bindPerson(context.record.recordId, personId);
                let personContext = await loadPerson(context, personId);
                personContext.person.flags.isCompromised = true;
                await core.api.setPersonFlags(personId, personContext.person.flags);
                this.switchCurrent(personContext);
            } finally {
                context.isInProcess = false;
            }
        },
        async load() {
            let context = this.context as RequestContext;
            let id = this.id;
            try {
                context.record = await core.api.getRecord(id);
                let loadPhotos = context.record.photos.map(_ => loadRecordPhoto(id, _));
                let loadFiles = context.record.files.map(_ => loadRecordFile(id, _));
                if (context.record.location.lat !== null && context.record.location.lng !== null) {
                    let lat = context.record.location.lat;
                    let lng = context.record.location.lng;
                    context.resolvedLocation = await core.api.resolveCoordinatesIntoLocation(lat, lng);
                }
                context.photos = await Promise.all(loadPhotos);
                context.files = await Promise.all(loadFiles);
                context.persons = [];
                let canEdit = getCanEdit(context);
                if (canEdit === false) {
                    context.stage = 3;
                }
                for (let personId of context.record.persons) {
                    await loadPerson(context, personId);
                }
                if (context.persons.length === 0) {
                    let personId = await core.api.createPerson(context.record.recordId);
                    let personContext = await loadPerson(context, personId);
                    this.switchCurrent(personContext);
                } else {
                    context.current = context.persons[0];
                }
                if (!context.record.dateTime) {
                    context.record.dateTime = new Date(context.record.timestamp);
                }

                for (let companyId of context.record.companies) {
                    const company = await loadCompany(companyId);
                    this.companiesArray.push(company);
                }
                this.selectedCompany = this.companiesArray[0];
                context.isInProcess = false;
            } catch {
                context.isInFailure = true;
            }
        },
        dropPerson(person: RecordPersonContext) {
            let context = this.context as RequestContext;
            let index = context.persons.indexOf(person);
            if (index === -1) {
                throw new Error("Failed to find person to remove");
            }
            context.persons.splice(index, 1);
            if (context.persons.length === 0) {
                context.current = null;
            } else if (index === 0) {
                context.current = context.persons[0];
            } else {
                context.current = context.persons[index - 1];
            }
            if (!person.person.personId) {
                return;
            }
            context.dropped.push(person);
        },
        async dropCompany(company: Company) {
            if (this.droppedCompanies === null) {
                this.droppedCompanies = new Array();
            }
            this.droppedCompanies.push(company.companyId);
            let context = this.companiesArray;
            let index = context.indexOf(company);
            if (index === -1) {
                throw new Error("Failed to find company to remove");
            }
            context.splice(index, 1);
            if (context.length === 0) {
                context = null;
            } else if (index === 0) {
                context = context[0];
            } else {
                context = context[index - 1];
            }
            if (!company.companyId) {
                return;
            }
            this.selectedCompany = context;
        },
        async commit() {
            let context = this.context as RequestContext;
            if (context.isInProcess) {
                return;
            }
            context.isInProcess = true;
            let recordId = context.record.recordId;
            core.commitRecordData(context, recordId);
            if (this.droppedCompanies !== null) {
                for (let index = 0; index < this.droppedCompanies.length; index++) {
                    const droppedCompany: string = this.droppedCompanies[index];
                    await core.api.removeCompanyFromIncident(recordId, droppedCompany);
                }
            }
            if (this.companiesArray.length !== 0) {
                for (let index = 0; index < this.companiesArray.length; index++) {
                    const company: Company = this.companiesArray[index];
                    await core.setCompanyData(company);
                }
            }
        },
        selectAllValue(event: { target: { select: () => void } }) {
            event.target.select();
        }
    }
};
</script>

<style lang="scss" scoped>
.delete-link {
    color: #f32525;
}
.record-form-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    > *:not(:last-child) {
        margin-right: 20px;
    }
}
.record-loss {
    input {
        padding: 10px;
        width: 100%;
        border: 1px solid #cccccc;
        &:focus {
            outline: none;
        }
    }
}
.record-location {
    display: inline-block;
    [stub] {
        color: #cccccc;
    }
    > *:not(:last-child) {
        margin-right: 5px;
    }
    > * {
        display: inline-block;
    }
    > * {
        &::after {
            content: ".";
        }
    }
}
.record-date-time {
    display: flex;
    flex-direction: row;
    > * {
        display: flex;
        flex-shrink: 0;
        align-items: center;
    }
    > *:nth-child(1) {
        width: 150px;
        font-weight: bold;
    }
    > *:nth-child(2) {
        flex-grow: 1;
    }
    .record-date-time-input {
        border-bottom: 1px solid #000000;
    }
}
.step-header {
    text-transform: uppercase;
    padding: 10px 0 0px 0;
    font-weight: bold;
}
.record-form-hidden {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ccc;
    text-transform: uppercase;
    text-align: center;
    padding: 20px;
    border: 1px solid #ccc;
}
.record-comment {
    display: flex;
    flex-direction: column;
    textarea {
        resize: none;
        width: 100%;
        padding: 10px;
        border: 1px solid #cccccc;
        &:focus {
            outline: none;
        }
    }
}
.record-form-empty {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding: 20px 0;
}
.record-form-navbar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 10px;
    > * {
        display: flex;
        flex-direction: row;
        border: 1px solid #2288dd;
        padding: 3px 10px;
        border-radius: 3px;
        cursor: pointer;
        &:not(:last-child) {
            margin-right: 10px;
        }
        &:not([active]):not(:hover) {
            color: #2288dd;
            background: transparent;
        }
        &[active],
        &:hover {
            background: #2288dd;
            color: #ffffff;
        }
    }
}
.record-form {
    > *:not(:last-child) {
        margin-bottom: 10px;
    }
}
.record-form-person-header {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ededed;
    padding-bottom: 10px;
    > * {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-shrink: 0;
        &:not(:last-child):not(.separator) {
            margin-right: 20px;
        }
    }
    .flag-real-data {
        padding: 0 10px;
        border: 1px solid #df6363;
        border-radius: 25px;
        &:not([active]) {
            color: #970000;
        }
        &[active] {
            background: #df6363;
            color: #fff;
        }
    }
    .separator {
        flex-grow: 1;
    }
    .record-form-important-flags {
        > *:not(:last-child) {
            margin-right: 10px;
        }
    }
}
.record-form-stage {
    display: flex;
    flex-direction: column;
    > *:not(:last-child) {
        margin-bottom: 10px;
    }
}
.record-form-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    > *:not(:last-child) {
        margin-bottom: 50px;
    }
    .fas {
        font-size: 50px;
        color: #f96565;
    }
    .text {
        font-size: 20px;
    }
}
.record-form-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    > * {
        display: flex;
        flex-direction: row;
    }
}
.record-form-person {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #e3e3e3;
}
.record-form-submit {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
}

.company-field {
    width: 50%;
    display: flex;

    & div:nth-child(1) {
        width: 35%;
        display: flex;
        align-items: center;
        margin: 10px;
    }

    & input:nth-child(2) {
        width: 65%;
        border: none;
        border-bottom: 1px solid #ccc;
        padding: 5px;
        &:focus {
            outline: none;
        }
    }
}
.block-content {
    &.flags {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 10px;
        & div:not(:first-child) {
            margin-left: 20px;
        }
    }
}
</style>
