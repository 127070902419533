<template lang="html">
<div class="photo-full">
    <div class="header">
        <div>Фото {{scope.requests.indexOf(scope.current) + 1}} из {{scope.requests.length}}</div>
    </div>
    <div v-if="scope.base64 !== null" class="area">
        <div v-bind:disabled="hasPrev === false ? true : null" v-on:click="openPrev()" class="prev">
            <i class="fas fa-chevron-left"></i>
        </div>
        <div v-bind-image="{data: scope.base64, width: width}" class="image"></div>
        <div v-bind:disabled="hasNext === false ? true : null" v-on:click="openNext()" class="next">
            <i class="fas fa-chevron-right"></i>
        </div>
    </div>
    <div v-if="loading" class="spinner">
        <spinner v-bind:white="true"></spinner>
    </div>
</div>
</template>

<script lang="ts">
import {core} from '../../root'
import $ from "jquery";

import SpinnerComponent from '../Spinner.vue';
import {LoadPhotoRequest} from '../../core';

class Scope {
    public cache:any = {};
    public requests: Array<LoadPhotoRequest>;
    public current: LoadPhotoRequest;
    public base64: string|null = null;
    constructor(requests: Array<LoadPhotoRequest>, index: number) {
        this.requests = requests;
        this.current = requests[index];
    }
}
function insertPhoto(element, base64:string, width:number) {
    const e = $(element);
    e.empty();
    if (!base64) {
        return;
    }
    const image = new Image();
    const source = 'data:image/png;base64,'+ base64;
    image.onload = () => {
        image.setAttribute('width', width + 'px');
        e.append(image);
    };
    image.setAttribute('src', source);
};
async function ensureCached(scope: Scope, onLoadStart: Function) {
    if (scope.current.photoId in scope.cache) {
        return;
    }
    onLoadStart();
    scope.cache[scope.current.photoId] = await core.loadPhotoSafely(scope.current);
}

export default {
    props: {
        requests: {
            type: Array<LoadPhotoRequest>,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        width: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            scope: new Scope(this.requests, this.index)
        };
    },
    async created () {
        await this.loadPhoto();
        $(window).on('keyup.photoFull', this.keyboardHandler);
    },
    beforeDestroy() {
        $(window).off('keyup.photoFull')
    },
    computed: {
        hasNext() {
            let scope:Scope = this.scope;
            const photoId = scope.current;
            let index = scope.requests.indexOf(photoId);
            if (index < scope.requests.length - 1) {
                return true;
            }
            return false;
        },
        hasPrev() {
            let scope:Scope = this.scope;
            const photoId = scope.current;
            let index = scope.requests.indexOf(photoId);
            if (index > 0) {
                return true;
            }
            return false;
        }
    },
    methods: {
        keyboardHandler(event:KeyboardEvent) {
            if (event.key === "ArrowLeft") {
                this.openPrev();
            } else if (event.key === "ArrowRight") {
                this.openNext();
            }
        },
        async openNext() {
            if (this.hasNext === false) {
                return;
            }
            let scope:Scope = this.scope;
            let index = scope.requests.indexOf(scope.current);
            scope.current = scope.requests[index + 1];
            await this.loadPhoto();
        },
        async openPrev() {
            if (this.hasPrev === false) {
                return;
            }
            let scope:Scope = this.scope;
            let index = scope.requests.indexOf(scope.current);
            scope.current = scope.requests[index - 1];
            await this.loadPhoto();
        },
        async loadPhoto() {
            let scope:Scope = this.scope;
            let current = scope.current;
            if (!!scope.current.base64) {
                scope.base64 = scope.current.base64;
                return;
            }
            scope.base64 = null;
            await ensureCached(scope, () => {
                this.loading = true;
            });
            if (scope.current === current) {
                scope.base64 = scope.cache[current.photoId];
                this.loading = false;
            }
        }
    },
    components: {
        spinner: SpinnerComponent
    },
    directives: {
        bindImage: {
            mounted(el, binding) {
                insertPhoto(el, binding.value.data, binding.value.width - 20)
            },
            updated(el, binding) {
                insertPhoto(el, binding.value.data, binding.value.width - 20)
            },
            unmounted(el)  {
                insertPhoto(el, null, null);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
$_background-color: #000000;

.spinner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}
.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ffffff;
    display: flex;
    flex-direction: row;
    padding: 10px 0;
}
.photo-full {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    flex-grow: 1;
    color: #ffffff;
    background: rgba($_background-color, 0.5);
}
.area {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    .image {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }
    .prev, .next {
        width: 150px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        font-size: 30px;
        &[disabled] {
            color: #666;
            cursor: default;
        }
        &:not([disabled]) {
            cursor: pointer;
        }
        &:not([disabled]):hover {
            background: rgba($_background-color, 0.2);
        }
    }
}
</style>